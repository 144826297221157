import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, DistributorCategoryStatus, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { InitialData } from '../common/InitialValue';
import toast from 'react-hot-toast';

const BrokerageCategoryAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.BrokerageCategory>(InitialData.BrokerageCategory);
    const formikRef = useRef<FormikProps<APIData.BrokerageCategory>>(null);

    const yupSchema = Yup.object().shape({
        bond_brokerage_category_name: Yup.string().required('Category Name is required'),
    });
    useEffect(() => {
        if (location.state.mode == 'edit') {
            setFormikInitialData(location.state);
        }
    }, [location])
    const submitData = (data: any, setSubmitting: any) => {
        console.log(data);
        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putBrokerageCategory(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_LIST, { state: res.id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postBrokerageCategory(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_LIST, { state: res.id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a>  Masters </a></li>
                                                <li className="breadcrumb-item active ">Brokerage Category</li>
                                                <li className="breadcrumb-item active ">Add Brokerage Category</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Add Brokerage Category </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="distributor_category_statusChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="distributor_category_statusChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_brokerage_category_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.bond_brokerage_category_status == DistributorCategoryStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_brokerage_category_name" className='bond-label'>Brokerage Category</label>
                                                                                    <input id="bond_brokerage_category_name" name="bond_brokerage_category_name" type="text" className='bond-input'
                                                                                        value={formikProps.values.bond_brokerage_category_name}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_brokerage_category_name: e.target.value }))}
                                                                                    />
                                                                                    {formikProps.errors.bond_brokerage_category_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_brokerage_category_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)}>Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default BrokerageCategoryAdd;

