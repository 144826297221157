import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { BondType, DealerActiveBondStatus, Depository, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import { getDealerStatus, getDisplayData, getDownloadFile, getFormattedLocalDate, getFormattedLocalDateTime, getOrderStatus, getOrderTypeFilter, numberWithCommas } from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BondEnquiryDetailPopup from "../modals/BondEnquiryDetailPopup";
import { PublishOutlined } from "@mui/icons-material";
import { cp } from "fs";

var startSerialNo = 0;
const TrustMonyUsers: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState("all");

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const [active_search_text, setActiveSearchText] = useState("");
  const [all_bond_search_text, setAllBondSearchText] = useState("");
  const { setShowPreloader, activeDealerTab, setActiveDealerTab } = useAppStateAPI();
  const searchref = React.createRef();
  const [userId, setuserId] = useState(0);
  const [all_datas, setAllDatas] = useState({
    id: 0,
    ISIN_number: "",
    publish_unpublish: 0,
    listingID: 0,
  });
  const [customerEnqurieList, setcustomerEnqurieList] = useState<APIData.CustomerEnquire[]>([]);
  const [customerEnqurieData, setcustomerEnqurieData] = useState<APIData.CustomerEnquire>(InitialData.CustomerEnquire);
  const [activeBondList, setActiveBondList] = useState<APIData.ActiveBondList[]>([]);
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmPage, setconfirmPage] = useState("");
  const [allBondList, setAllBondList] = useState<APIData.AllBondList[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [activeBondBg, setactiveBondBg] = useState(false);
  const [activeBondListingBondID, setActiveBondListingBondID] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  let columns: any = [
    {
      name: 'Sr.No',
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },

    {
      name: "Cust. ID",
      selector: (row: any) => row.customer_id == null || row.customer_id == "" ? 'N/A' : row.customer_id,
      sortable: true
    },
    {
      name: "Request date",
      selector: (row: any) => row.customer_bond_enquire_created_at,
      sortable: true,
      format: (row: any) => moment(row.customer_bond_enquire_created_at).format('DD-MM-YYYY,  hh:mm A'),
    },

    {
      name: "Customer Name",
      selector: "customer_name",
      sortable: true
    },
    {
      name: "Mobile Number",
      selector: (row: any) => row.customer_mobile_number !== null && row.customer_mobile_number !== "" ? row.customer_mobile_number : 'N/A',
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row: any) => getDisplayData(row.customer_email_id),
      sortable: true,
    },
    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row.bond_name),
      sortable: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.bond_isin_number),
      sortable: true,
    },
    {
      name: "Price (₹)",
      selector: (row: any) => numberWithCommas(row.customer_bond_enquire_price),
      sortable: true,
    },
    {
      name: "Yield (%)",
      selector: (row: any) => getDisplayData(row.customer_bond_enquire_yield),
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row: any) => getDisplayData(row.customer_bond_enquire_quantity),
      sortable: true,
    },
    {
      name: "Settlement Date",
      selector: (row: any) => getDisplayData(row.settlement_date_name),
      sortable: true,
    },
    {
      name: "Order Type",
      selector: (row: any) => getDisplayData(row.buy_sell_name),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: any) => row.customer_bond_enquire_status,
      cell: (row: any) => [
        <div className="city-action-rable-row ">
          {row.customer_bond_enquire_status === 1 ? (
            <div>Added</div>
          ) : (
            <div
              onClick={() => {
                handleClickOpen();
                setcustomerEnqurieData(row);
              }}
            >
              <img src="../../assets/images/add.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} /><br />
              <span style={{ cursor: "pointer" }}>Add</span>
            </div>
          )}
        </div>
      ]
    },
  ];
  let columns1: any = [
    {
      name: 'Sr.No',
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },

    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row.bond_name),
      sortable: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.bond_isin_number),
      sortable: true,
    },
    {
      name: "Price (₹)",
      selector: (row: any) => numberWithCommas(row.bond_listing_price),
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row: any) => getFormattedLocalDate(row.bond_listing_start_date),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row: any) => getFormattedLocalDate(row.bond_listing_end_date),
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row: any) => getDisplayData(row.bond_listing_quantity),
      sortable: true,
    },
    {
      name: "Yield (%)",
      selector: (row: any) => getDisplayData(row.bond_listing_yield),
      sortable: true,
    },
    {
      name: "Order Type",
      selector: (row: any) => getDisplayData(row.buy_sell_name),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: any) => row.bond_listing_publish_unpublish,
      cell: (row: any) => [
        <div className="city-action-rable-row d-flex align-item-center">
          <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.BOND_PRICE_DETAIL_VIEW, { state: { bondISINNumber: row.bond_isin_number, publishMode: row.bond_listing_publish_unpublish, bondID: row.bond_id, ListingID: row.bond_listing_id } })}></i>
          {row.bond_listing_publish_unpublish === 0 || row.bond_listing_publish_unpublish === null ? (
            <div
              onClick={() => {
                handlePublishConfirmationOpen(DealerActiveBondStatus.Publish, row.bond_listing_id, row.bond_listing_bond_id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src="../../assets/images/publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
              <span>Publish </span>
            </div>
          ) : (
            <div
              onClick={() => {
                handlePublishConfirmationOpen(DealerActiveBondStatus.UnPublish, row.bond_listing_id, row.bond_listing_bond_id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src="../../assets/images/un_publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
              <span style={{ cursor: "pointer" }}>Un Publish</span>
            </div>
          )}
        </div>
      ]
    },
  ];
  let columns2: any = [
    {
      name: 'Sr.No',
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },

    {
      name: "Bond Name",
      selector: (row: any) => getDisplayData(row.bond_name),
      sortable: true,
    },
    {
      name: "ISIN Name",
      selector: (row: any) => getDisplayData(row.bond_issuer_name),
      sortable: true,
    },
    {
      name: "Instrument Type",
      selector: (row: any) => numberWithCommas(row.bond_nature_of_instrument),
      sortable: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.bond_isin_number),
      sortable: true,
    },

    {
      name: "Face Value",
      selector: (row: any) => numberWithCommas(row.bond_face_value),
      sortable: true,
    },
    {
      name: "Maturity Date",
      selector: (row: any) => getDisplayData(row.bond_maturity_date),
      sortable: true,
    },
    {
      name: "Call Date",
      selector: (row: any) => getDisplayData(row.call_date),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: any) => row.bond_listing_status,
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          {row.bond_listing_status != null || row.bond_listing_status == 0 ? (
            <div>Added</div>
          ) : (
            <div
              onClick={() => {
                handleConfirmationOpen(row.bond_id, row.bond_isin_number);
              }}
            >
              <img src="../../assets/images/add.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
              <span style={{ cursor: "pointer" }}>Add</span>
            </div>
          )}
        </td>
      ]
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  const tabReload = (value: any) => {
    if (value == "active_bonds") {
      setactiveBondBg(true);
      setTabIndex(1);
      setTimeout(() => {
        setactiveBondBg(false);
      }, 5000);
    } else if (value == "all_bonds") {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  }
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
   }
  // useEffect(() => {
  //   loadCustomerEnquiryList();
  // }, [pageNumber, search_text, toData, fromDate, preData, open]);

  useEffect(() => {
    if (search_text != '') {
      loadCustomerEnquiryList();
    }
  }, [pageNumber, search_text, toData, fromDate]);

  useEffect(() => {
    if (active_search_text != '') {
      loadActiveBondList();
    }
  }, [pageNumber, toData, fromDate, active_search_text]);

  useEffect(() => {
    if (all_bond_search_text != '') {
      loadBondList();
    }
  }, [pageNumber, toData, fromDate, all_bond_search_text]);

  useEffect(() => {
    loadCustomerEnquiryList();
    loadActiveBondList();
    loadBondList();
  }, []);

  const loadCustomerEnquiryList = () => {
    setShowPreloader(true);
    ServerAPI.getCustomerEnquiryList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        try {
          setcustomerEnqurieList(res.data);
          setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const loadActiveBondList = () => {
    setShowPreloader(true);
    ServerAPI.getActiveBondList(pageNumber, active_search_text, toData, fromDate)
      .then((res) => {
        try {
          setActiveBondList(res.data);
          setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const loadBondList = () => {
    setShowPreloader(true);
    ServerAPI.getAllBondList(pageNumber, all_bond_search_text, toData, fromDate)
      .then((res) => {
        try {
          setAllBondList(res.data);
          setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  /*customer enquiry model*/
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e: any) => {
    setOpen(e);
  };

  /*active bond confirm popup*/
  const handleConfirmationOpen = (bondID: number, isinNumber: string) => {
    setOpenModal(true);
    setConfirmContent("Are you sure you want to add the bond to the active list?");
    setconfirmPage("update-bond-active-mode");
    setAllDatas({ id: bondID, ISIN_number: isinNumber, publish_unpublish: 0, listingID: 0 });
  };
  const handleConfirmationClosed = (e: any) => {
    setOpenModal(e);
  };

  /*pulish or un publish confirm popup*/
  const handlePublishConfirmationOpen = (mode: number, listing_id: number, bondListingBondId: number) => {
    setOpenModal(true);
    setConfirmContent(`Are you sure you want to ${mode == DealerActiveBondStatus.Publish ? "publish" : "Un Publish"} the Bond?`);
    setconfirmPage("update-bond-publish-mode");
    setAllDatas({ id: 0, ISIN_number: "", publish_unpublish: mode, listingID: listing_id });
    setActiveBondListingBondID(bondListingBondId);
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Users</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trust Mony Users</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>Dealers</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList className="Product_detail_tablist" style={{ marginBottom: "0rem" }}>
                  <div className=" d-flex justify-content-between align-items-end">
                    <div>
                      <Tab className="product_detail_tab">Customer Enquiries</Tab>
                      <Tab className="product_detail_tab">Active Bonds</Tab>
                      <Tab className="product_detail_tab">All Bonds</Tab>
                    </div>
                    {/* <div>
                      <form className="app-search card-title p-2 m-0 w-100">
                        <div className="position-relative btn-suspend">
                          <a className="text-white" onClick={() => navigate(PageLinks.USERS, { state: true })}>
                            <i className="fa fa-user-circle mx-2" aria-hidden="true"></i>
                            Customer Enquiries
                          </a>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Bond Name, ISIN Name, Price..."/>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-4 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setFromDate(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>

                                <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered"> */}
                              {/* <thead className="city-table">
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Cust. ID</th>
                                    <th>Request date</th>
                                    <th>Customer Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                    <th>Bond Name</th>
                                    <th>ISIN Number</th>
                                    <th>Price (₹)</th>
                                    <th>Yield (%)</th>
                                    <th>Qty</th>
                                    <th>Settlement Date</th>
                                    <th>Order Type</th>
                                    <th colSpan={3} data-priority="3" className="">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody> */}
                              {/* {customerEnqurieList != undefined && customerEnqurieList.length > 0 ? (
                                  <>
                                    {customerEnqurieList.map((res: APIData.CustomerEnquire, i: number) => (
                                      <tbody key={i}>
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>{getDisplayData(res.customer_id)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.customer_bond_enquire_created_at)}</td> */}
                              {/* <td>{res.customer_bond_enquire_created_at != null && moment(res.customer_bond_enquire_created_at).format("DD-MM-YYYY,  hh:mm A")}</td>
                                          <td>{getDisplayData(res.customer_name)}</td>
                                          <td>{getDisplayData(res.customer_mobile_number)}</td>
                                          <td>{getDisplayData(res.customer_email_id)}</td>
                                          <td>{getDisplayData(res.bond_name)}</td>
                                          <td>{getDisplayData(res.bond_isin_number)}</td>
                                          <td>₹ {numberWithCommas(res.customer_bond_enquire_price)}</td>
                                          <td>{getDisplayData(res.customer_bond_enquire_yield)}</td>
                                          <td>{getDisplayData(res.customer_bond_enquire_quantity)}</td>
                                          <td>{getDisplayData(res.settlement_date_name)}</td>
                                          <td>{getDisplayData(res.buy_sell_name)}</td>
                                          <td className="city-action-rable-row ">
                                            {res.customer_bond_enquire_status === 1 ? (
                                              <div>Added</div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  handleClickOpen();
                                                  setcustomerEnqurieData(res);
                                                }}
                                              >
                                                <img src="../../assets/images/add.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }}>Add</span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </>
                                ) : (
                                  <tbody>
                                    <tr className="py-4">
                                      <td className="text-center py-5" colSpan={11}>
                                        No Data Found.
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table> */}
                              <DataTable
                                data={customerEnqurieList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {customerEnqurieList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setActiveSearchText(e.target.value)} placeholder="Bond Name, ISIN Name, Price..."/>
                                  </div>
                                </form>
                              </div>
                            </div>

                            {/* <div className="col-xl-4 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker selected={fromDate} onChange={(date: Date) => setFromDate(date)} placeholderText="DD/MM/YYYY" className="date-picker-style" customInput={<CustomInput />} />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker selected={toData} onChange={(date: Date) => setToData(date)} placeholderText="DD/MM/YYYY" className="date-picker-style" customInput={<CustomInput />} />
                                  </div>
                                </form>
                              </div>
                            </div> */}
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                                <thead className="city-table">
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Bond Name</th>
                                    <th>ISIN Number</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Price (₹)</th>
                                    <th>Qty</th>
                                    <th>Yeild (%)</th>
                                    <th>Order Type</th>
                                    <th colSpan={3} data-priority="3" className="">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {activeBondList != undefined && activeBondList.length > 0 ? (
                                  <>
                                    {activeBondList.map((res: APIData.ActiveBondList, i: number) => (
                                      <tbody key={i}>
                                        <tr style={{ backgroundColor: `${res.bond_listing_bond_id === activeBondListingBondID && activeBondBg == true ? "#FFE1BE" : ""}` }}>
                                          <td> {i + 1} </td>
                                          <td>{getDisplayData(res.bond_name)}</td>
                                          <td>{getDisplayData(res.bond_isin_number)}</td>
                                          <td>{getFormattedLocalDate(res.bond_listing_start_date)}</td>
                                          <td>{getFormattedLocalDate(res.bond_listing_end_date)}</td>
                                          <td>₹ {numberWithCommas(res.bond_listing_price)}</td>
                                          <td>{getDisplayData(res.bond_listing_quantity)}</td>
                                          <td>{getDisplayData(res.bond_listing_yield)}</td>
                                          <td>{getDisplayData(res.buy_sell_name)}</td>
                                          <td className="city-action-rable-row d-flex align-item-center">
                                            <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.BOND_PRICE_DETAIL_VIEW,{state:{bondISINNumber:res.bond_isin_number,publishMode:res.bond_listing_publish_unpublish,bondID:res.bond_id,ListingID:res.bond_listing_id}})}></i>
                                            {res.bond_listing_publish_unpublish === 0 || res.bond_listing_publish_unpublish === null ? (
                                              <div
                                                onClick={() => {
                                                  handlePublishConfirmationOpen(DealerActiveBondStatus.Publish, res.bond_listing_id,res.bond_listing_bond_id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img src="../../assets/images/publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span>Publish </span>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  handlePublishConfirmationOpen(DealerActiveBondStatus.UnPublish, res.bond_listing_id,res.bond_listing_bond_id);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img src="../../assets/images/un_publish.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }}>Un Publish</span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </>
                                ) : (
                                  <tbody>
                                    <tr className="py-4">
                                      <td className="text-center py-5" colSpan={11}>
                                        No Data Found.
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table> */}
                              <DataTable
                                data={activeBondList}
                                columns={columns1}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {activeBondList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-8">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setAllBondSearchText(e.target.value)} placeholder="Bond Name, ISIN Name, Price..."/>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                                <thead className="city-table">
                                  <tr>
                                    <th>Sr.No</th>
                                    <th>Bond Name</th>
                                    <th>ISIN Name</th>
                                    <th>Instrument Type</th>
                                    <th>ISIN Number</th>
                                    <th>Face Value</th>
                                    <th>Maturity Date</th>
                                    <th>Call Date</th>
                                    <th colSpan={3} data-priority="3" className="">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {allBondList != undefined && allBondList.length > 0 ? (
                                  <>
                                    {allBondList.map((res: APIData.AllBondList, i: number) => (
                                      <tbody key={i}>
                                        <tr>
                                          <td> {i + 1} </td>
                                          <td>{getDisplayData(res.bond_name)}</td>
                                          <td>{getDisplayData(res.bond_issuer_name)}</td>
                                          <td>{getDisplayData(res.bond_nature_of_instrument)}</td>
                                          <td>{getDisplayData(res.bond_isin_number)}</td>
                                          <td>₹ {numberWithCommas(res.bond_face_value)}</td>
                                          <td>{getDisplayData(res.bond_maturity_date)}</td>
                                          <td>{getDisplayData(res.call_date)}</td>
                                          <td className="city-action-rable-row ">
                                            {res.bond_listing_status != null || res.bond_listing_status == 0 ? (
                                              <div>Added</div>
                                            ) : (
                                              <div
                                                onClick={() => {
                                                  handleConfirmationOpen(res.bond_id, res.bond_isin_number);
                                                }}
                                              >
                                                <img src="../../assets/images/add.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} />
                                                <span style={{ cursor: "pointer" }}>Add</span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                                  </>
                                ) : (
                                  <tbody>
                                    <tr className="py-4">
                                      <td className="text-center py-5" colSpan={11}>
                                        No Data Found.
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table> */}
                              <DataTable
                                data={allBondList}
                                columns={columns2}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                              
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {allBondList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <BondEnquiryDetailPopup customerData={customerEnqurieData} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} listReload={loadCustomerEnquiryList} />
      <ConfirmationModal
        contents={confirmContent}
        details_datas={all_datas}
        currentPage={confirmPage}
        openModal={open_modal}
        handleClose={(e: any) => handleConfirmationClosed(e)}
        activeBondListingBondID={activeBondListingBondID}
        user_id={userId}
        listReload={(e: any) => {
          console.log(e)
          loadActiveBondList();
          loadBondList();
          tabReload(e);
        }}
      />
    </div>
  );
};

export default TrustMonyUsers;