import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { DealerActiveBondStatus, PageLinks } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";
import SuccessFailureModel from "./SuccessFailureModel";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  contents?: any;
  currentPage?: any;
  details_datas?: any;
  user_id?: any;
  listReload?: any;
  activeBondListingBondID?: any;
  setIssave?: any;
  reloadlist?: any;
}

const ConfirmationModal: React.FC<modalDatas> = ({ handleOpen, openModal, handleClose, contents, currentPage,setIssave, details_datas, user_id, listReload ,reloadlist, activeBondListingBondID}) => {
  console.log("🚀 ~ file: Confirmation-modal.tsx:23 ~ reloadlist:", reloadlist)
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { setShowPreloader, setActiveDealerTab } = useAppStateAPI();
  const [successStatus, setSuccessStatus] = useState<boolean>(false);

  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openModal]);
  

  const handleCloses = () => {
    handleClose(false);
    setOpen(false);
  };

  const handleSave = () => {
    if (currentPage === "update-status") {
      setShowPreloader(true);
      ServerAPI.UserUpdateStatus(details_datas, user_id)
        .then((res) => {
          toast.success(res.message);
          handleClose(false);
          setOpen(false);
          listReload('success');
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "suspended-modal") {
      setShowPreloader(true);
      ServerAPI.AddUpdateStatus(details_datas, user_id)
        .then((res: any) => {
          toast.success("Successfully");
          handleClose(false);
          setOpen(false);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "suspend-user-list") {
      setShowPreloader(true);
      ServerAPI.ActiveSuspendedUser(details_datas)
        .then((res: any) => {
          handleClose(false);
          setOpen(false);
          navigate(PageLinks.SUSPENDED_USER_LIST, { state: true });
          toast.success("Successfully");
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "update-bond-active-mode") {
      // handleClose(false);
      // listReload('active_bonds');
      // setActiveDealerTab('active_bonds');
      setShowPreloader(true);
      ServerAPI.ActiveBond(details_datas.id, 1)
        .then((res: any) => {
          setActiveDealerTab("active_bonds");
          handleClose(false);
          listReload("active_bonds");
          toast.success(res.message);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "update-bond-new-price") {
      console.log(details_datas);
      setShowPreloader(true);
      ServerAPI.ActiveBondNewPriceUpdate(
        details_datas.bondID, 
        details_datas.price, 
        details_datas.yield, 
        details_datas.startDate, 
        details_datas.endDate, 
        details_datas.qty, 
        details_datas.buySell, 
        details_datas.publish_unpublish, 
        details_datas.listingID)
        .then((res) => {
          toast.success(res.message);
          reloadlist("");
          setIssave(true)
          handleCloses();
          listReload('update-new-price');
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }

    if (currentPage === "update-bond-publish-mode") {
      setShowPreloader(true);
      ServerAPI.PublishUnpublishBond(details_datas.publish_unpublish, details_datas.listingID)
        .then((res: any) => {
          handleCloses();
          listReload("active_bonds");
          toast.success(res.message);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  return (
    <div>
      <Dialog open={open} className="dialog-box" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className="model-title">
          <div className="modal-box-title">
            <p className="m-0 fs-5">Confirmation</p>
            <p
              className="m-0"
              onClick={() => {
                handleClose();
              }}
            >
              <i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i>
            </p>
          </div>
        </DialogTitle>
        <hr />

        <DialogContent>
          <DialogContentText className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <img src="./assets/images/exclaimation.png" alt="exclaimation" className="exclaimation-style" />
              <p className="m-0 ml-3 text-black-medium fs-6">{contents}</p>
            </div>
            {currentPage === "update-bond-publish-mode" ? <p style={{ margin: "6px", paddingLeft: "38px", fontSize: "12px" }}>{`${details_datas.publish_unpublish == DealerActiveBondStatus.Publish ? "Publishing" : "Un Publishing"} will list the Bond on the website`}</p> : ""}
            <br />
            <div className="d-flex justify-content-end">
              <a className="cancel-btn_1 mx-3 " onClick={() => handleCloses()}>
                No
              </a>
              <a className="save-btn_1" onClick={() => handleSave()}>
                Yes
              </a>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <SuccessFailureModel contents={details_datas?.ISIN_number != undefined ? details_datas.ISIN_number : ""} openModal={successStatus} handleClose={() => setSuccessStatus(false)} />
    </div>
  );
};

export default ConfirmationModal;
