import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { DealerStatus, Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InitialData } from '../common/InitialValue';
import { getDealerStatus, getDisplayData, getFormattedLocalDateTime, getGender } from '../common/Utilities';
import toast from 'react-hot-toast';

const DealerDetailView: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [dealerDetail, setDealerDetail] = useState<APIData.DealerList>(InitialData.Dealer);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<any>();
    const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);

    const [addComments, setAddComments] = useState<any | undefined>(EditorState.createEmpty());
    const [addComments_text, setAddComments_text] = useState("");
    const formikRef = useRef<FormikProps<APIData.BondAdd>>(null);

    const onEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddComments(editorState);
        setAddComments_text(htmlResult);
    };

    useEffect(() => {
        if (location.state) {
            loadDealerDetail(location.state);
        }
    }, [location.state])

    // useEffect(() => {
    //     suspended_list();
    // }, [pageNumber, search_text, toData, fromDate, preData, open])


    const loadDealerDetail = (id: number) => {
        setShowPreloader(true);
        ServerAPI.getDealerDetail(id).then((res) => {
            setDealerDetail(res);
            setTotalCount(res.total_count);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const setDealerStatus = (status: number, dealerID: number) => {
        setShowPreloader(true);
        ServerAPI.setDealerStatus(status, dealerID).then((res) => {
            toast.success(res.message);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const loadDealerLoginDetails = (dealer_id: number, dealer_user_name: string, dealer_password: string, dealer_unique_code: string) => {
        setShowPreloader(true);
        ServerAPI.putDealerLoginDetail(dealer_id, dealer_user_name, dealer_password, dealer_unique_code).then((res) => {
            console.log(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item" onClick={() => navigate(PageLinks.DEALER_LIST)}><a> Dealer List </a></li>
                                                <li className="breadcrumb-item active "> Detail View </li>
                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header col-xl-12 w-100">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="my-3 header"> Dealer Details </h5>
                                                <div className="d-flex justify-content-end align-items-center">
                                                    <div className="form-check form-switch mx-2">
                                                        <label className="form-check-label" htmlFor="dealerStatusChecked">Disable/Enable</label>
                                                        <input className="form-check-input " type="checkbox" role="switch" id="dealerStatusChecked"
                                                            onChange={(e) => {
                                                                e.target.checked == true ? dealerDetail.dealer_status = 1 : dealerDetail.dealer_status = 0;
                                                                setDealerStatus(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, dealerDetail.dealer_id);
                                                                setDealerDetail(({ ...dealerDetail }));
                                                            }}
                                                            checked={dealerDetail.dealer_status == DealerStatus.Active ? true : false}
                                                        />
                                                    </div>
                                                    <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.DEALER_ADD, { state: { id: dealerDetail.dealer_id, mode: 'edit' } })} ><img src="../../assets/images/edit.svg" style={{ marginBottom: "4px", width: "16px" }} /> Edit</a>
                                                </div>
                                            </div>

                                            <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">User Type</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_unique_code)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Gender</p>
                                                            <h6>{getGender(dealerDetail.dealer_gender)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Name of User</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Employee Code</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_employee_code)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Current Status</p>
                                                            <h6>{getDealerStatus(dealerDetail.dealer_status)}</h6>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Manage By</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_managed_by)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Branch Name</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_branch_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Date of Joining</p>
                                                            <h6>{getFormattedLocalDateTime(dealerDetail.dealer_doj)}</h6>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Date of Birth</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_dob)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Phone No</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_phone)}</h6>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Mobile No</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_mobile)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Email ID</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_email)}</h6>
                                                        </div>

                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Address Line 1</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_address_line1)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Address Line 2</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_address_line2)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">State</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_state_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">City</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_city_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                                            <p className="detail-title">Remarks</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_remarks)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />

                                            <div className="card">
                                                <div className="card-header">
                                                    <h5 className="m-0 header">Login Details</h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Unique Code</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_unique_code)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">User Name</p>
                                                            <h6>{getDisplayData(dealerDetail.dealer_user_name)}</h6>
                                                        </div>
                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                            <p className="detail-title">Password</p>
                                                            <div className='row'>
                                                                <div className={`col-lg-${dealerDetail.dealer_login_status == 0 ? '8' : '12'}`}>
                                                                    <h6>{getDisplayData(dealerDetail.dealer_password)}</h6>
                                                                </div>
                                                                {
                                                                    dealerDetail.dealer_login_status == 0 ?
                                                                        <div className='col-lg-4'>
                                                                            <button className='resend-btn' onClick={() => loadDealerLoginDetails(dealerDetail.dealer_id, dealerDetail.dealer_user_name, dealerDetail.dealer_password, dealerDetail.dealer_unique_code)}>ReSend</button>
                                                                        </div> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default DealerDetailView;

