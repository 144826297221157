import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InitialData } from '../common/InitialValue';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import toast from 'react-hot-toast';

const BrokerAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [brokerID, setbrokerID] = useState(0);
    const [formikInitialData, setFormikInitialData] = useState<APIData.BrokerList>(InitialData.BrokerList);
    const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);

    const [addComments, setAddComments] = useState<any | undefined>(EditorState.createEmpty());
    const [addComments_text, setAddComments_text] = useState("");
    const formikRef = useRef<FormikProps<APIData.BrokerList>>(null);

    const onEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddComments(editorState);
        setAddComments_text(htmlResult);
    };

    function DateIcon() {
        return (
            <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
                <path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" />
            </SvgIcon>
        );
    }

    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    useEffect(() => {
        suspended_list();
    }, [pageNumber, search_text, toData, fromDate, preData, open])

    useEffect(() => {
        if (location.state.mode && location.state.mode == "edit") {
            loadIntitalBOND(location.state.id);
        }
        if (location.state.mode == "add") {
            setFormikInitialData((e) => ({
                ...e,
                broker_advisory: InitialData.BrokerAdvisory,
            }));
            console.log(formikInitialData);
        }
    }, []);

    const loadIntitalBOND = (broker_id: number) => {
        if (broker_id) {
            setShowPreloader(true);
            ServerAPI.BrokerListID(broker_id)
                .then((res) => {
                    console.log('12', res);
                    setbrokerID(res.data.broker_id);
                    setFormikInitialData((e) => ({
                        ...res.data,
                        broker_advisory: res.data.broker_advisory,
                    }));
                })
                .finally(() => {
                    setShowPreloader(false);
                });
        }
    };

    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const updateNDCList = () => {
        let updatedList = NDCList;
        setNDCList(prev => ({ ...prev, header: [...prev, { name: 'Serial 2' }] }))
    }

    useEffect(() => {
        console.log(NDCList);
    }, [NDCList])


    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    const yupSchema = Yup.object().shape({
        firstName: Yup.string().required('Product Name is required'),
        lastName: Yup.string().required('Product Name is required'),
        email: Yup.string().required('Product Name is required'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putBroker(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BROKER_LIST, { state: res.broker_id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postBroker(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.BROKER_LIST, { state: res.broker_id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Broker List </a></li>
                                                <li className="breadcrumb-item active "> Add Broker </li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                //  validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    values.broker_id = location.state.mode == "edit" ? brokerID : 0;
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Add Broker </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Broker Name</label>
                                                                                    <input id="broker_name" name="broker_name" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_name: e.target.value }))}
                                                                                        value={formikProps.values.broker_name} />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>NSE Broker Code</label>
                                                                                    <input id="broker_nse_code" name="broker_nse_code" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_nse_code: e.target.value }))}
                                                                                        value={formikProps.values.broker_nse_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>BES Broker Code</label>
                                                                                    <input id="broker_bse_code" name="broker_bse_code" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_bse_code: e.target.value }))}
                                                                                        value={formikProps.values.broker_bse_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>MCX Broker Code</label>
                                                                                    <input id="broker_mcx_code" name="broker_mcx_code" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_mcx_code: e.target.value }))}
                                                                                        value={formikProps.values.broker_mcx_code} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Address Line 1</label>
                                                                                    <input id="broker_address_line_1" name="broker_address_line_1" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_address_line_1: e.target.value }))}
                                                                                        value={formikProps.values.broker_address_line_1} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Address Line 2</label>
                                                                                    <input id="broker_address_line_2" name="broker_address_line_2" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_address_line_2: e.target.value }))}
                                                                                        value={formikProps.values.broker_address_line_2} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>City</label>
                                                                                    <input id="broker_address_city" name="broker_address_city" type="text" className='bond-input-search'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_address_city: e.target.value }))}
                                                                                        value={formikProps.values.broker_address_city} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Exchange Type</label>
                                                                                    <select id="broker_exchange" name="broker_exchange"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_exchange: e.target.value }))}
                                                                                        value={formikProps.values.broker_exchange}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="">Select</option>
                                                                                        <option value="Food">Food</option>
                                                                                        <option value="Entertainment">Entertainment</option>
                                                                                        <option value="Academic">Academic</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>GST No</label>
                                                                                    <input id="broker_gst_no" name="broker_gst_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_gst_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_gst_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>GST City</label>
                                                                                    <input id="broker_gst_city" name="broker_gst_city" type="text" className='bond-input-search'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_gst_city: e.target.value }))}
                                                                                        value={formikProps.values.broker_gst_city} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Pincode</label>
                                                                                    <input id="broker_address_pincode" name="broker_address_pincode" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_address_pincode: e.target.value }))}
                                                                                        value={formikProps.values.broker_address_pincode} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Phone No</label>
                                                                                    <input id="broker_phone_no" name="broker_phone_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_phone_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_phone_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Mobile No</label>
                                                                                    <input id="broker_mobile_no" name="broker_mobile_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_mobile_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_mobile_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Fax No</label>
                                                                                    <input id="broker_fax_no" name="broker_fax_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_fax_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_fax_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>E-Mail ID</label>
                                                                                    <input id="broker_email" name="broker_email" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_email: e.target.value }))}
                                                                                        value={formikProps.values.broker_email} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>PAN No</label>
                                                                                    <input id="broker_pan_no" name="broker_pan_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_pan_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_pan_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>ST Reg No</label>
                                                                                    <input id="broker_st_reg_no" name="broker_st_reg_no" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_st_reg_no: e.target.value }))}
                                                                                        value={formikProps.values.broker_st_reg_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>ST Regn Category</label>
                                                                                    <input id="broker_st_reg_category" name="broker_st_reg_category" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_st_reg_category: e.target.value }))}
                                                                                        value={formikProps.values.broker_st_reg_category} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>ST Service Category</label>
                                                                                    <input id="broker_st_service_category" name="broker_st_service_category" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, broker_st_service_category: e.target.value }))}
                                                                                        value={formikProps.values.broker_st_service_category} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Advisory Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {/* {formikProps.values?.broker_advisory.map((rating, index) => {
                                                                            return (
                                                                                <> */}
                                                                        {formikProps?.values?.broker_advisory !== undefined && 
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_name" className='bond-label'>Advisory Name</label>
                                                                                    <input id="advisory_name" name="advisory_name" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_name = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_name} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_pan_no" className='bond-label'>Advisory PAN No</label>
                                                                                    <input id="advisory_pan_no" name="advisory_pan_no" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_pan_no = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                    // value={formikProps.values?.broker_advisory.advisory_pan_no}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_address_line_1" className='bond-label'>Address Line 1</label>
                                                                                    <input id="advisory_address_line_1" name="advisory_address_line_1" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_address_line_1 = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_address_line_1} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_address_line_2" className='bond-label'>Address Line 2</label>
                                                                                    <input id="advisory_address_line_2" name="advisory_address_line_2" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_address_line_2 = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_address_line_2} />
                                                                                </div>
                                                                            </Grid>
                                                                            {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Contact Person</label>
                                                                                    <input id="Bond Name" name="lastName" type="text" className='bond-input' 
                                                                                    onChange={e => setFormikInitialData(({ ...formikInitialData, advisory_address_line_2: e.target.value }))} 
                                                                                     value={formikProps.values.advisory_address_line_2} />
                                                                                </div>
                                                                            </Grid> */}
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_address_city" className='bond-label'>City</label>
                                                                                    <input id="advisory_address_city" name="advisory_address_city" type="text" className='bond-input-search'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_address_city = e.target.value;
                                                                                            console.log('52', formikProps.values.broker_advisory.advisory_address_city)
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_address_city} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_address_pincode" className='bond-label'>Pincode</label>
                                                                                    <input id="advisory_address_pincode" name="advisory_address_pincode" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_address_pincode = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_address_pincode} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_address_state" className='bond-label'>Advisory State</label>
                                                                                    <input id="advisory_address_state" name="advisory_address_state" type="text" className='bond-input-search'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_address_state = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_address_state} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_gst_no" className='bond-label'>Advisory GST</label>
                                                                                    <input id="advisory_gst_no" name="advisory_gst_no" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_gst_no = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_gst_no} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="advisory_remarks" className='bond-label'>Remark (if any)</label>
                                                                                    <input id="advisory_remarks" name="advisory_remarks" type="text" className='bond-input'
                                                                                        onChange={(e) => {
                                                                                            formikProps.values.broker_advisory.advisory_remarks = e.target.value;
                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                        }}
                                                                                        value={formikProps.values?.broker_advisory.advisory_remarks} />
                                                                                </div>
                                                                            </Grid>


                                                                        </Grid>}

                                                                        {/* </> */}
                                                                        {/* );
                                                                                                      
                                                                        })} */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />

        </div>
    );
}

export default BrokerAdd;

