import { string } from "yup"

export namespace FormDataTypes {
    export interface SelectOption {
        value: string,
        label: string
    }
}

export namespace FormDefaultConstants {
    export const GenderOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Male'
        },
        {
            value: '2',
            label: 'Female'
        },
        {
            value: '3',
            label: 'Others'
        }
    ]
    export const dealerStatusOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Active'
        },
        {
            value: '2',
            label: 'Disable'
        }
    ]
    export const dealerManagedbyOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'admin'
        },
        {
            value: '2',
            label: 'sub'
        }
    ]
    export const instrumentTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Taxable'
        },
        {
            value: '2',
            label: 'Tax Free'
        }
    ]
    export const securityTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Private Sector Bond (Non Convertible Debentures)'
        },
        {
            value: '2',
            label: 'PSU Bond/ Government Security'
        },
        {
            value: '3',
            label: 'Fixed Deposit'
        },
        {
            value: '4',
            label: '54 EC Capital Gains Bonds'
        },
        {
            value: '5',
            label: 'RBI Sovereign Gold BondFloating Rate Savings Bonds'
        },
        {
            value: '6',
            label: 'Corporate Fixed Deposit'
        },
    ]
    export const issuerTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Yet to Open'
        },
        {
            value: '2',
            label: 'Open'
        },
        {
            value: '3',
            label: 'Closed'
        }
    ]
    export const effortTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Effort Basis 1'
        },
        {
            value: '2',
            label: 'Effort Basis 2'
        },
        {
            value: '3',
            label: 'Effort Basis 3'
        }
    ]
    export const registatorTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Each Issue will have a separate Registrar'
        },
    ]
    export const ourStatusTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'open'
        },
        {
            value: '2',
            label: 'close'
        },
    ]
    export const listingTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'BSE'
        },
        {
            value: '2',
            label: 'NSE'
        },
        {
            value: '3',
            label: 'Both BSE & NSE'
        },
        {
            value: '4',
            label: 'UNLISTED'
        },
    ]
    export const depositoryTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'National Securities Depository Limited (NSDL)'
        },
        {
            value: '2',
            label: 'Central Depository Services (India) Limited (CDSL)'
        },
        {
            value: '3',
            label: 'Both'
        },
    ]
    export const frequnecyOfInterestTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Annual'
        },
        {
            value: '2',
            label: 'Semi Annual'
        },
        {
            value: '3',
            label: 'Quarterly'
        },
        {
            value: '4',
            label: 'Monthly'
        },
    ]
    export const greenShoeTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Yes'
        },
        {
            value: '2',
            label: 'No'
        },
    ]
    export const GSTTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: '5'
        },
        {
            value: '2',
            label: '10'
        },
        {
            value: '3',
            label: '18'
        },
    ]
    export const issuerSTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Private Sector Corporate'
        },
        {
            value: '2',
            label: 'Public Sector Undertaking'
        },
        {
            value: '3',
            label: 'Private  Bank'
        },
        {
            value: '4',
            label: 'Public Sector Bank'
        },
        {
            value: '5',
            label: 'State Government'
        },
        {
            value: '6',
            label: 'Central Government'
        },
    ]
    export const typeOfIssuerTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Private Sector Corporate'
        },
        {
            value: '2',
            label: 'Public Sector Undertaking'
        },
        {
            value: '3',
            label: 'Private  Bank'
        },
        {
            value: '4',
            label: 'Public Sector Bank'
        },
        {
            value: '5',
            label: 'State Government'
        },
        {
            value: '6',
            label: 'Central Government'
        },
    ]
    export const couponTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Interest Rate to be paid (cannot be part of Drop Down Values)'
        },
    ]
    export const frequnceyInterestOfPaymentTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Monthly'
        },
        {
            value: '2',
            label: 'Cumulative'
        },
        {
            value: '3',
            label: 'Yearly'
        },
    ]
    export const GuaranteeTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Yes'
        },
        {
            value: '2',
            label: 'No'
        },
    ]
    export const InterestTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Interest 1'
        },
        {
            value: '2',
            label: 'Interest 2'
        },
        {
            value: '3',
            label: 'Interest 3'
        },
    ]
    export const InterestCalculateByTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Ratting'
        },
        {
            value: '2',
            label: 'Amount'
        },
        {
            value: '3',
            label: 'Date'
        },
    ]
    export const ExitOptionAvailableTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Exit Option Available 1'
        },
        {
            value: '2',
            label: 'Exit Option Available 2'
        },
        {
            value: '3',
            label: 'Exit Option Available 3'
        },
    ]
    export const bondCalcIntOnHolidaysTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'yes'
        },
        {
            value: '2',
            label: 'No'
        },
    ]
    export const Gender: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Male'
        },
        {
            value: '2',
            label: 'Female'
        },
        {
            value: '3',
            label: 'Other'
        },
    ]
    export const bond_exchangeTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'BSE'
        },
        {
            value: '2',
            label: 'NSE'
        },
        {
            value: '3',
            label: 'Both BSE & NSE'
        },
    ]
    export const InterestFrequencyTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Monthly'
        },
        {
            value: '2',
            label: 'Quarterly'
        },
        {
            value: '3',
            label: 'Half Yearly'
        },
        {
            value: '4',
            label: 'Yearly'
        },
    ]
    export const purchaseLimitTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: '1 Year'
        },
        {
            value: '2',
            label: '5 Year'
        },
        {
            value: '3',
            label: '10 Year'
        }
    ]
    export const putAndCallTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Put'
        },
        {
            value: '2',
            label: 'Call'
        },
        {
            value: '3',
            label: 'Both'
        }
    ]
    export const modeOFissuerTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Public Placement'
        },
        {
            value: '2',
            label: 'Private'
        },
    ]
}

export namespace APIData {

    export interface Login {
        mobile_number: string,
    }
    export interface Otp {
        otp: string
    }

    export interface userDetail {
        bank_details: any,
        exist_demat_account_details: any,
        new_demat_account_details: any,
        personal_details: personalDetail
    }

    export interface personalDetail {
        address_proof_id: number,
        aadhar_name: string,
        aadhar_number: string,
        address_city: string,
        address_line_1: string,
        address_line_2: string,
        address_line_3: string,
        address_state: string,
        address_state_code: string,
        address_zip: string,
        annual_income: number,
        bank_name: string,
        check_box_share_data_with_company: number,
        check_box_share_data_with_govt: number,
        city_sequence_no: number,
        country_code: string,
        customer_id: string,
        dob: string,
        education_degree: string,
        ekyc_application_status: null,
        email_id: string,
        family_account: string,
        father_or_husband_name: null,
        fname: string,
        gender: number | String,
        geogriphical_code: string,
        address_type: any,
        // id: string,
        ifsc_code: string,
        in_person_verification: number,
        is_aadhar_verified: string | number,
        is_email_verified: number,
        is_mobile_verified: number,
        is_pan_verified: number,
        is_politically_exposed: number,
        is_nominee_added: number,
        lifestyle: string,
        lname: string,
        married_status: number,
        mental_disability: string,
        mname: string,
        mobile_number: string,
        mothers_maiden_name: string,
        occupation: string | number,
        pan_name: string,
        pan_number: string,
        personal_details_created_at: string,
        personal_details_updated_at: string,
        profile_image: string,
        proof_back_image: string,
        proof_front_image: string,
        proof_type: string,
        signature: string,
        signature_file_flag: string,
        // suffix: null,
        title: string,
        trading_experience: number,
        user_hashkey: string,
        user_id: string,
        users_created_at: string,
        users_created_by: string,
        users_updated_at: string,
        users_updated_by: string,
        verification_video: string,
        customer_type: string,
        customer_category: string,
        customer_sub_category: string,
        would_you_like_to_activate: number,
        customer_group: string,
        account_type_1: string,
        in_person_video_verification_status: number,
        gst: string,
        kra_status: number,
        firstname: string,
        middlename: string,
        lastname: string,
        married_status_name: string,
    }

    // export interface NomineeDetails {
    //     country_code: string,
    //     ekyc_application_status: string,
    //     email_id: string,
    //     father_or_husband_name: string,
    //     fname: string,
    //     mname: string,
    //     lname: string,
    //     mobile_number: string,
    //     nominee_details_address_city: string,
    //     nominee_details_address_line_1: string,
    //     nominee_details_address_type: string,
    //     nominee_details_address_line_2: string,
    //     nominee_details_address_line_3: string,
    //     nominee_details_address_state: string,
    //     nominee_details_address_state_code: string,
    //     nominee_details_address_zip: string,
    //     nominee_details_allocation: number,
    //     nominee_details_city_sequence_no: string,
    //     nominee_details_created_at: string,
    //     nominee_details_current_address_city: string,
    //     nominee_details_current_address_line_1: string,
    //     nominee_details_current_address_line_2: string,
    //     nominee_details_current_address_line_3: string,
    //     nominee_details_current_address_state: string,
    //     nominee_details_current_address_state_codecurrent: string,
    //     nominee_details_current_address_zip: string,
    //     nominee_details_current_city_sequence_no: string,
    //     nominee_details_dob: string,
    //     nominee_details_fname: string,
    //     nominee_details_identification: number,
    //     nominee_details_identification_number: string,
    //     nominee_details_lname: string,
    //     nominee_details_mname: string,
    //     nominee_details_mobile_number: string,
    //     nominee_details_relationship: string,
    //     nominee_details_title: string,
    //     nominee_details_updated_at: string,
    //     suffix: string,
    //     title: string,
    //     // user_id: 30

    // }
    export interface guardianRecords {
        name_title: string;
        name_title_created_at: string;
        name_title_id: number;
        name_title_status: number;
        name_title_updated_at: string;
        nominee_guardian_created_at: string;
        nominee_guardian_fullname: string;
        nominee_guardian_id: string;
        nominee_guardian_identification_number: string;
        nominee_guardian_mobile_number: string;
        nominee_guardian_updated_at: string;
        nominee_identification: string;
        nominee_identification_created_at: string;
        nominee_identification_id: number;
        nominee_identification_status: number;
        nominee_identification_updated_at: string;
        relationship_with_applicant: string;
        relationship_with_applicant_created_at: string;
        relationship_with_applicant_id: number;
        relationship_with_applicant_status: number;
        relationship_with_applicant_updated_at: string;
      }
    export interface NomineeDetails {
        name_title_id: number;
        nominee_address_as_user: number;
        nominee_address_city: string;
        nominee_address_line_1: string;
        nominee_address_line_2: string;
        nominee_address_pincode: string;
        nominee_address_state: string;
        nominee_created_at: string;
        nominee_dob: string;
        nominee_fullname: string;
        nominee_guardian_id: number;
        nominee_guardian_records: guardianRecords[];
        nominee_id: number;
        nominee_identification_id: number;
        nominee_identification_number: string;
        nominee_is_minor: number;
        nominee_mobile_number: string;
        nominee_percentage: string;
        nominee_updated_at: string;
        relationship_with_applicant_id: number;
        name_title: string;
        relationship_with_applicant: string;
      }

    export interface FactaDetails {

        dp_resident_india: number,
        dp_resident_usa: number,
        check_box_terms_selected: number,
        check_box_account_statement_electronic: number,
        demat_signature_image: string,
        born_place: string,
        primary_source: string,
        demat_account_details_updated_at: any

    }

    export interface BankDetailsDatas {
        account_name: string,
        account_number: string,
        account_type_1: number | string,
        account_type_2: number | string,
        bank_details_created_at: string,
        bank_details_id: number,
        bank_details_updated_at: string,
        bank_name: string,
        cancel_cheque_image: string,
        ifsc_code: string,
        is_bank_account_verified: number,
        is_primary: number,
        status: number,
        branch_name: string,
        branch_address: string,
        account_status: any,
    }

    export interface IpvDatas {
        in_person_verification_remarks: string;
        in_person_verification_status: string;
        in_person_video: string;
        aadhar_dob: string,
        aadhar_name: string,
        address_match: number,
        date_of_birth_match: number,
        digilocker_image: string,
        name_match: number,
        proof_back_image: string,
        proof_front_image: string,
        proof_type: string,
        random_code: string,
        user_dob: string,
        user_name: string
    }

    export interface Digilocker_address_data {
        aadhar_number: string,
        care_of: string,
        country: string,
        date_of_birth: string,
        digilocker_details_created_at: string,
        digilocker_details_updated_at: string,
        district: string,
        gender: any,
        house_no: string,
        landmark: string,
        location: string,
        name: string,
        pincode: string,
        state: string,
        street: string,
        village_town_city: string,
        address_line_1: string,
        address_line_2: string,
        address_line_3: string,
        api_type: string,
        address_type: string

    }
    export interface AddressDatas_cvl_kra {
        cvl_kra_pan_name: string,
        cvl_kra_dob: string,
        cvl_kra_address_line_1: string,
        cvl_kra_address_line_2: string,
        cvl_kra_address_line_3: string,
        cvl_kra_business_address_line_1: string,
        cvl_kra_business_address_line_2: string,
        cvl_kra_business_address_line_3: string,
        cvl_kra_business_city: string,
        cvl_kra_business_country_code: number,
        cvl_kra_business_pincode: string,
        cvl_kra_business_state: string,
        cvl_kra_city: string,
        cvl_kra_pincode: string,
        cvl_kra_state: string,
        cvl_kra_father_name: string,
        cvl_kra_aadhaar_seeding_status: any,
        cvl_kra_ipv_seeding_status: any,
        cvl_kra_gst_available: any,
        cvl_kra_gst_number: any
        cvl_kra_api_type: any,
        aadhaar_api_type: any,
        cvl_kra_updated_at: string
    }

    export interface ExistingDematAccountDetails {
        beneficiary_id: string,
        depository: string,
        dp_id: string,
        dp_name: string,
        status: number,
        exist_demat_account_details_created_at: string,
        exist_demat_account_details_updated_at: string,
        id: number,
        client_id: number,
        exist_demat_account_details_status_key: number,
        demat_account_status: number,
        firstname: string,
        lastname: string,
    }

    export interface NewDematAccountDetails {
        application_number: string,
        born_place: string,
        check_box_account_statement_electronic: number,
        check_box_terms_selected: number,
        country_code: string,
        demat_account_details_created_at: string,
        demat_account_details_updated_at: string,
        demat_signature_image: string,
        dp_resident_india: number | string,
        dp_resident_usa: number | string,
        ekyc_application_status: number,
        id: number,
        user_id: number,
        status: string,
        depository: string,
        primary_source: string,
        demat_account_details_status: number,
        demat_account_status: number,
        fname: string,
        lname: string,
        dp_account_number: string
    }
    export interface NewDematAccount {

    }

    export interface UserAddress {
        address_city: string,
        address_line_1: string,
        address_line_2: string,
        address_line_3: string,
        address_state: string,
        address_state_code: string,
        address_zip: string
    }

    export interface AccountStatusDetails {
        account_status_created_at: string,
        account_status_id: number,
        account_status_reason: string,
        account_status_remarks: string,
        account_status_updated_at: string,
        suspended_end_date: string,
        suspended_start_date: string,
        user_status_key: number,
    }

    export interface AlluserLogs {
        user_customer_id: string,
        ip_address: string,
        module: string,
        operation_request: any,
        operation_status: string,
        user_id: number,
        user_logs_created_at: string,
        user_mobile_number: string,
        user_name: string,
        platform: string,
        operation_type: any,
        user_last_login: string
    }

    export interface BondList {
        bond_about_this_ipo: string,
        bond_allotment_date: string,
        bond_app_no_series: string,
        bond_arranger_name: string,
        bond_asba_app_no_series_1: string,
        bond_asba_app_no_series_2: string,
        bond_bonds_name: string,
        bond_brokerage_details_status: string,
        bond_brokerage_structure: string,
        bond_calc_int_on_holidays: string,
        bond_category_hni: string,
        bond_category_institutional: string,
        bond_category_instructions: string,
        bond_category_non_institutional: string,
        bond_category_retail: string,
        bond_closer_date: string,
        bond_closing_date: string,
        bond_coupon_amount: string,
        bond_coupon_date: string,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_created_at: string,
        bond_deposit: string,
        bond_disclaimer: string,
        bond_dmat_book_closer_date: string,
        bond_e_form_incentive: string,
        bond_early_bid_incentive: string,
        bond_effort_basis: string,
        bond_exchange: string,
        bond_face_value: string,
        bond_final_issue_amount: string,
        bond_green_shoe: number,
        bond_green_shoe_size: string,
        bond_gst: string,
        bond_guaranted_by: string,
        bond_id: number,
        bond_int_on_app_money: string,
        bond_int_on_maturity: string,
        bond_int_on_refund_money: string,
        bond_interest_days: string,
        bond_interest_frequency: string,
        bond_interest_type: string,
        bond_ip_date: string,
        bond_ipo_sell_windows_days: string,
        bond_isin_number: string,
        bond_issue_date: string,
        bond_issue_price: string,
        bond_issue_size: string,
        bond_issue_status: string,
        bond_issuer_name: string,
        bond_key_highlights: string,
        bond_listing: string,
        bond_listing_circle: string,
        bond_logo: string,
        bond_maturity_amount: string,
        bond_maturity_date: string,
        bond_minimum_application: string,
        bond_nature_of_instrument: string,
        bond_opening_date: string,
        bond_other_incentive: string,
        bond_our_status: string,
        bond_price_per_bond: string,
        bond_procurement_amount: string,
        bond_product_note: string,
        bond_rbi_loan_code: string,
        bond_registrar_name: string,
        bond_script_id: number,
        bond_security_code: string,
        bond_security_type: string,
        bond_series_instructions: string,
        bond_st: string,
        bond_sub_category_code: string,
        bond_term_condition_link: string,
        bond_trustee_name: string,
        bond_type: number,
        bond_updated_at: string,
        bond_upi_app_no_series_1: string,
        bond_upi_app_no_series_2: string,
        bond_name: string,
    }

    export interface BondAdd {
        bond_id: number,
        bond_isin_number: any,
        bond_issuer_name: any,
        // bond_asba_details: string,
        bond_name: any,
        bond_nature_of_instrument: number,
        bond_security_type: number,
        bond_st: number,
        bond_security_code: any,
        bond_guaranted_by: any,
        bond_face_value: any,
        bond_issue_price: any,
        bond_price_per_bond: any,
        bond_issue_date: any,
        bond_ip_date: any,
        bond_interest_frequency: any,
        bond_interest_type: number,
        bond_coupon_on: any,
        bond_coupon_rate: any,
        bond_interest_days: any,
        bond_closer_date: any,
        bond_ncd_status: number,
        bond_dmat_book_closer_date: any,
        bond_rbi_loan_code: any,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: any,
        bond_listing: number,
        bond_minimum_application: any,
        bond_issue_size: any,
        bond_logo: any,
        bonds_yeild: any,
        bond_govt_guranatee: number,
        // bond_type: number,
        bond_maturity_date: any,
        bond_maturity_amount: any,
        bond_coupon_date: any,
        bond_coupon_amount: any,
        bond_key_highlights: any,
        bond_disclaimer: any,
        bond_term_condition_link: any,
        bond_category_institutional: any,
        bond_category_non_institutional: any,
        bond_category_hni: any,
        bond_category_retail: any,
        bond_about_this_ipo: any,
        // bond_ncd_status: number,
        // bond_brokerage_details_status: number,
        // bond_issue_status: number,
        // bond_effort_basis: number,
        // bond_registrar_name: number,
        // bond_trustee_name: number,
        // bond_our_status: number,
        // bond_int_on_app_money: any,
        // bond_int_on_refund_money: any,
        // bond_early_bid_incentive: any,
        // bond_other_incentive: any,
        // bond_e_form_incentive: any,
        // bond_opening_date: string,
        // bond_closing_date: string,
        // bond_allotment_date: string,
        // bond_deposit: string,
        // bond_green_shoe: number,
        // bond_green_shoe_size: string,
        // bond_final_issue_amount: string,
        // bond_procurement_amount: any,
        // bond_gst: number,
        // bond_script_id: string,
        // bond_arranger_name: any,
        // bond_upi_app_no_series_1: string,
        // bond_upi_app_no_series_2: string,
        // bond_asba_app_no_series_1: string,
        // bond_asba_app_no_series_2: string,
        // bond_ipo_sell_windows_days: number,
        // bond_category_instructions: string,
        // bond_sub_category_code: string,
        // bond_app_no_series: string,
        // bond_series_instructions: string,
        // bond_product_note: string,
        // bond_listing_circle: string,
        // bond_brokerage_structure: string,

        // bond_details: BondDetails,
        bond_call_details: BondCallDetails[],
    bond_put_details: BondPutDetails[],
    bond_cashflow_details: BondCashflowDetails[],
    bond_rating_details: BondRatingDetails[],
    bond_issuer_details: BondIssuerDetails[],
    bond_brand_tags: BondBrandTags[],
    bond_eligible_investors: BondEligibleInvestors[],
    bond_ncd_available: BondNcdAvailable[],
    bond_ncd_series: BondNcdSeries[],
        bond_status: number
    }

    export interface IPOAdd {
        bond_id: number,
        bond_isin_number: any,
        bond_issuer_name: any,
        bond_asba_details: any,
        bond_name: any,
        bond_nature_of_instrument: number,
        bond_security_type: number,
        bond_st: number,
        bond_security_code: any,
        bond_guaranted_by: any,
        bond_face_value: any,
        bond_issue_price: any,
        bond_price_per_bond: any,
        bond_issue_date: any,
        bond_form_start_number: number,
        bond_form_end_number: number,
        bond_status: number,
        bond_ip_date: any,
        bond_interest_frequency: any,
        bond_interest_type: number,
        bond_coupon_on: any,
        bond_coupon_rate: any,
        bond_interest_days: any,
        bond_closer_date: any,
        bond_dmat_book_closer_date: any,
        bond_rbi_loan_code: any,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: any,
        bond_listing: number,
        bond_minimum_application: any,
        bond_issue_size: any,
        bond_logo: any,
        bond_type: number,
        bond_maturity_date: any,
        bond_maturity_amount: any,
        bond_coupon_date: any,
        bond_coupon_amount: any,
        bond_key_highlights: any,
        bond_disclaimer: any,
        bond_term_condition_link: any,
        bond_category_institutional: any,
        bond_category_non_institutional: any,
        bond_category_hni: any,
        bond_category_retail: any,
        bond_about_this_ipo: any,
        bond_ncd_status: number,
        bond_brokerage_details_status: number,
        bond_issue_status: number,
        bond_effort_basis: number,
        bond_registrar_name: number,
        bond_trustee_name: number,
        bond_our_status: number,
        bond_int_on_app_money: any,
        bond_int_on_refund_money: any,
        bond_early_bid_incentive: any,
        bond_other_incentive: any,
        bond_e_form_incentive: any,
        bond_opening_date: any,
        bond_closing_date: any,
        bond_allotment_date: any,
        bond_deposit: any,
        bond_green_shoe: number,
        bond_green_shoe_size: any,
        bond_final_issue_amount: any,
        bond_procurement_amount: any,
        bond_gst: number,
        bond_script_id: any,
        bond_arranger_name: any,
        bond_upi_app_no_series_1: any,
        bond_upi_app_no_series_2: any,
        bond_asba_app_no_series_1: any,
        bond_asba_app_no_series_2: any,
        bond_ipo_sell_windows_days: number,
        bond_category_instructions: any,
        bond_sub_category_code: any,
        bond_app_no_series: any,
        bond_series_instructions: any,
        bond_product_note: any,
        bond_listing_circle: any,
        bond_brokerage_structure: any,
        bonds_yeild: any,

        bond_call_details: BondCallDetails[],
        bond_put_details: BondPutDetails[],
        bond_rating_details: BondRatingDetails[],
        bond_issuer_details: BondIssuerDetails[],
        bond_brand_tags: BondBrandTags[],
        bond_eligible_investors: BondEligibleInvestors[],
        bond_ncd_available: BondNcdAvailable[],
        bond_ncd_series: BondNcdSeries[],
        bond_brokerage_details: BondBrokerageDetails[]
    }

    export interface IPODetailView {
        bond_id: number,
        bond_isin_number: string,
        bond_issuer_name: string,
        bond_asba_details: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_security_type: number,
        bond_st: number,
        bond_security_code: string,
        bond_guaranted_by: string,
        bond_face_value: string,
        bond_issue_price: any,
        bond_price_per_bond: any,
        bond_issue_date: string,
        bond_ip_date: string,
        bond_interest_frequency: string,
        bond_interest_type: number,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_interest_days: number,
        bond_closer_date: string,
        bond_dmat_book_closer_date: string,
        bond_rbi_loan_code: string,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: string,
        bond_listing: number,
        bond_minimum_application: string,
        bond_issue_size: string,
        bond_logo: string,
        bond_type: number,
        bond_maturity_date: string,
        bond_maturity_amount: string,
        bond_coupon_date: string,
        bond_coupon_amount: string,
        bond_key_highlights: string,
        bond_disclaimer: string,
        bond_term_condition_link: string,
        bond_category_institutional: string,
        bond_category_non_institutional: string,
        bond_category_hni: string,
        bond_category_retail: string,
        bond_about_this_ipo: string,
        bond_ncd_status: number,
        bond_brokerage_details_status: number,
        bond_issue_status: number,
        bond_effort_basis: number,
        bond_registrar_name: number,
        bond_trustee_name: number,
        bond_our_status: number,
        bond_int_on_app_money: string,
        bond_int_on_refund_money: string,
        bond_early_bid_incentive: string,
        bond_other_incentive: string,
        bond_e_form_incentive: string,
        bond_opening_date: string,
        bond_closing_date: string,
        bond_allotment_date: string,
        bond_deposit: string,
        bond_green_shoe: number,
        bond_green_shoe_size: string,
        bond_final_issue_amount: string,
        bond_procurement_amount: string,
        bond_gst: number,
        bond_script_id: string,
        bond_arranger_name: string,
        bond_upi_app_no_series_1: string,
        bond_upi_app_no_series_2: string,
        bond_asba_app_no_series_1: string,
        bond_asba_app_no_series_2: string,
        bond_ipo_sell_windows_days: number,
        bond_category_instructions: string,
        bond_sub_category_code: string,
        bond_app_no_series: string,
        bond_series_instructions: string,
        bond_product_note: string,
        bond_listing_circle: string,
        bond_brokerage_structure: string,
        bonds_yeild: string,
        bond_depository: number

        bond_call_details: BondCallDetails[],
        bond_put_details: BondPutDetails[],
        bond_rating_details: BondRatingDetails[],
        bond_issuer_details: BondIssuerDetails[],
        bond_brand_tags: BondBrandTags[],
        bond_eligible_investors: BondEligibleInvestors[],
        bond_ncd_available: BondNcdAvailable[],
        bond_ncd_series: BondNcdSeries[],
        bond_brokerage_details: BondBrokerageDetails[]
    }

    export interface BondBrandTags {
        brand_tag_id: number,
        brand_tag_name: string,
        bond_brand_tag_id: any,
        brand_tag_status?: number,
        brand_tag_checked?: boolean,
        bond_brand_tag_available: any
    }

    export interface BondEligibleInvestors {
        eligible_investor_id: any,
        eligible_investor_name: string,
        bond_eligible_investor_id: any,
        eligible_investors_available: any,
        eligible_investor_status?: number
    }

    export interface BondNcdAvailable {
        bond_ncd_id: number,
        bond_ncd_minimum_application: number,
        bond_ncd_frequency_of_interest_payment: any,
        bond_ncd_in_multiple_terms_of_thereafter: number,
        bond_ncd_issue_price_of_ncd: any,
        bond_ncd_tenor: number,
        bond_ncd_coupon: any,
        bond_ncd_effective_yield: any,
        bond_ncd_mode_of_interest_payment: number,
        bond_ncd_redemption_amount: any,
        bond_ncd_put_and_option: number
    }

    export interface BondNcdSeries {
        bond_ncd_series_id: number,
        bond_ncd_series_number: number,
        bond_ncd_minimum_application: number,
        bond_ncd_frequency_of_interest_payment: any,
        bond_ncd_in_multiple_terms_of_thereafter: number,
        bond_ncd_issue_price_of_ncd: any,
        bond_ncd_tenor: number,
        bond_ncd_coupon: any,
        bond_ncd_effective_yield: any,
        bond_ncd_mode_of_interest_payment: any,
        bond_ncd_redemption_amount: any,
        bond_ncd_put_and_option: number
    }

    export interface BondBrokerageDetails {
        bond_brokerage_detail_id: number,
        bond_breakage_series_number: number,
        bond_brokerage_category_id: number,
        bond_brokerage_value: string,
        bond_id: number
    }

    export interface BondDetails {
        bond_isin_number: string,
        bond_issuer_name: string,
        bond_name: string,
        bond_nature_of_instrument: string,
        bond_security_type: string,
        bond_st: number,
        bond_security_code: number,
        bond_guaranted_by: string,
        bond_face_value: number,
        bond_issue_price: number,
        bond_price_per_bond: number,
        bond_issue_date: string,
        bond_ip_date: string,
        bond_interest_frequency: string,
        bond_govt_guranatee: number,
        bond_interest_type: string,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_interest_days: number,
        bond_closer_date: string,
        bond_opening_date: string,
        bond_dmat_book_closer_date: string,
        bond_rbi_loan_code: string,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: string,
        bond_listing: number,
        bond_minimum_application: string,
        bond_issue_size: string,
        bond_logo: string,
        bond_maturity_date: string,
        bond_maturity_amount: string,
        bond_coupon_date: string,
        bond_coupon_amount: string,
        bond_key_highlights: string,
        bond_disclaimer: string,
        bond_term_condition_link: string,
        bond_category_institutional: string,
        bond_category_non_institutional: string,
        bond_category_hni: number,
        bond_category_retail: number,
        bond_about_this_ipo: string,
        bond_security_type_code: number,
        bonds_yeild: string,
        bond_call_details: BondCallDetails[],
        bond_put_details: BondPutDetails[],
        bond_cashflow_details: BondCashflowDetails[],
        bond_rating_details: BondRatingDetails[],
        bond_issuer_details: BondIssuerDetails[],
        bond_brand_tags: BondBrandTags[],
        bond_eligible_investors: BondEligibleInvestors[],
        bond_ncd_available: BondNcdAvailable[],
        bond_ncd_series: BondNcdSeries[],
        bond_brokerage_details: BondBrokerageDetails[]
    }

    export interface BondCallDetails {
        bond_call_detail_id: number,
        bond_call_detail_datetime: any,
        bond_call_detail_amount: any
    }

    export interface BondPutDetails {
        bond_put_detail_id: number,
        bond_put_detail_datetime: any,
        bond_put_detail_amount: any
    }

    export interface BondRatingDetails {
        bond_rating_agency: any,
        bond_rating_certificate: any,
        bond_rating: number
    }

    export interface BondIssuerDetails {
        bond_issuer_id: number,
        bond_issuer_type: number,
        bond_issuer_total_annual_revenue: any,
        bond_issuer_year_of_inception: any,
        bond_issuer_industry: any,
        bond_issuer_head_office: any,
        bond_type_of_issuer: number,
        bond_issuer_finance_details: any,
        bond_issuer_documents: any,
        bond_issuer_profile_link: any,
        bond_issuer_about: any,
        bond_issuer_series: any,
        bond_issuer_tenor: number,
        bond_issuer_coupon_type: number,
        bond_issuer_frequency_of_interest: number,
        bond_issuer_first_ip_date: any,
        bond_issuer_ip_dates: any,
        bond_issuer_effective_yield: any,
        bond_id: number,
        // bond_issuer_isin_number: string
    }

    export interface BondNcdsDetails {
        bond_ncd_series_id: number,
        bond_specfic_terms_for_each_ncd: any,
        bond_frequency_of_interest_payment: any,
        bond_tenor: any,
        bond_coupon_per_annum: number,
        bond_redemption_amount_on_maturity: number,
        bond_effective_yield_per_annum: number
    }

    export interface BondCashflowDetails {
        bond_cashflow_date: any,
        bond_cashflow_amount: number,
        bond_cashflow_days: string,
        bond_cashflow_type: number
    }

    export interface DealerList {
        dealer_address_line1: string,
        dealer_address_line2: string,
        dealer_address_line3: string,
        dealer_branch_name: string,
        dealer_created_at: string,
        dealer_dob: any,
        dealer_doj: any,
        dealer_email: string,
        dealer_employee_code: string,
        dealer_gender: number,
        dealer_id: number,
        dealer_login_hash: string,
        dealer_login_otp: string,
        dealer_login_otp_time: string,
        dealer_login_status: number,
        dealer_managed_by: string,
        dealer_mobile: string,
        dealer_name: string,
        dealer_password: string,
        dealer_phone: string,
        dealer_remarks: string,
        dealer_status: number,
        dealer_unique_code: string,
        dealer_updated_at: string,
        dealer_user_name: string,
        dealer_state_name: string,
        dealer_city_name: string,
        dealer_state_id: number,
        dealer_city_id: number
    }
    export interface DealerAdd {
        dealer_address_line1: string,
        dealer_address_line2: string,
        dealer_address_line3: string,
        dealer_branch_name: string,
        dealer_dob: any,
        dealer_doj: any,
        dealer_email: string,
        dealer_employee_code: string,
        dealer_gender: number,
        dealer_id: number,
        dealer_managed_by: string,
        dealer_mobile: string,
        dealer_name: string,
        dealer_password: string,
        dealer_phone: string,
        dealer_remarks: string,
        dealer_unique_code: string,
        dealer_user_name: string,
        dealer_status: number,
        dealer_state_id: number,
        dealer_city_id: number
    }

    export interface SGBList {
        bond_id: number,
        bond_isin_number: string,
        bond_issuer_name: string,
        bond_asba_details: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_security_type: number,
        bond_st: number,
        bond_security_code: string,
        bond_guaranted_by: string,
        bond_face_value: any,
        bond_issue_price: any,
        bond_price_per_bond: any,
        bond_issue_date: string,
        bond_ip_date: string,
        bond_interest_frequency: string,
        bond_interest_type: number,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_interest_days: number,
        bond_closer_date: string,
        bond_dmat_book_closer_date: string,
        bond_rbi_loan_code: string,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: string,
        bond_listing: number,
        bond_minimum_application: string,
        bond_issue_size: string,
        bond_logo: string,
        bond_type: number,
        bond_maturity_date: string,
        bond_maturity_amount: any,
        bond_coupon_date: string,
        bond_coupon_amount: any,
        bond_key_highlights: string,
        bond_disclaimer: string,
        bond_term_condition_link: string,
        bond_category_institutional: any,
        bond_category_non_institutional: any,
        bond_category_hni: any,
        bond_category_retail: any,
        bond_about_this_ipo: string,
        bond_ncd_status: number,
        bond_brokerage_details_status: number,
        bond_issue_status: number,
        bond_effort_basis: number,
        bond_registrar_name: number,
        bond_trustee_name: number,
        bond_our_status: number,
        bond_int_on_app_money: any,
        bond_int_on_refund_money: any,
        bond_early_bid_incentive: any,
        bond_other_incentive: any,
        bond_e_form_incentive: any,
        bond_opening_date: string,
        bond_closing_date: string,
        bond_allotment_date: string,
        bond_deposit: string,
        bond_green_shoe: number,
        bond_green_shoe_size: string,
        bond_final_issue_amount: string,
        bond_procurement_amount: any,
        bond_gst: number,
        bond_script_id: string,
        bond_arranger_name: any,
        bond_upi_app_no_series_1: string,
        bond_upi_app_no_series_2: string,
        bond_asba_app_no_series_1: string,
        bond_asba_app_no_series_2: string,
        bond_ipo_sell_windows_days: number,
        bond_category_instructions: string,
        bond_sub_category_code: string,
        bond_app_no_series: string,
        bond_series_instructions: string,
        bond_product_note: string,
        bond_listing_circle: string,
        bond_brokerage_structure: string,
    }

    export interface SGBAdd {
        bond_id: number,
        bond_issuer_name: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_security_type: number,
        bond_st: number,
        bond_security_code: string,
        bond_security_type_code: string,
        bond_govt_guranatee: number,
        bond_isin_number: string,
        bond_face_value: number,
        bond_issue_price: number,
        bonds_price_per_gram: number,
        bond_purchase_limit: number,
        bond_purchase_limit_metric: number,
        bond_coupon_rate: any,
        bonds_yeild: number,
        bond_interest_type: string,
        bond_interest_frequency: string,
        bond_allotment_date: any,
        bond_opening_date: any,
        bond_closing_date: any,
        bonds_next_interest_payment_date: any,
        bond_maturity_date: any,
        bond_dmat_book_closer_date: any,
        exit_option_available: number,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: string,
        bond_listing: number,
        bonds_banner_rcb_notice_link: any,
        bond_logo: string,
        bond_about_this_ipo: string,
        bond_category_institutional: number,
        bond_category_non_institutional: number,
        bond_category_hni: number,
        bond_category_retail: number,
        bond_coupon_date: any,
        bond_coupon_amount: number,
        bonds_banner_content_title: string,
        bonds_banner_content_sub_title: string,
        bonds_banner_content_image: string,
        bond_issue_document: string,
        bond_disclaimer: string,
        bond_term_condition_link: string,
        bond_benefits: bond_benefits[],
        bond_highlights: bond_highlights[],
        bond_upcoming_issues_status: number,
        bond_upcoming_issues: bond_upcoming_issues[],
        bond_cashflow_details: BondCashflowDetails[],
        bond_brand_tags: BondBrandTags[],
        bond_eligible_investors: BondEligibleInvestors[],
        bond_status: number
    }
    export interface bond_upcoming_issues {
        bond_upcoming_issues_name: string,
        bond_upcoming_issues_subscription_start_date: any,
        bond_upcoming_issues_subscription_end_date: any,
        bond_upcoming_issues_allotment_date: any
    }
    export interface bond_highlights {
        bond_highlights_title: string
    }
    export interface bond_benefits {
        bond_benefits_title: string,
        bond_benefits_icon: string,
        bond_benefits_description: string
    }
    export interface IPOFormAllocation {
        bond_form_allocation_id: number,
        bond_form_allocation_status: number,
        bond_form_allocation_form_start: number,
        bond_form_allocation_form_end: number,
        bond_form_allocation_form_total: number,

        bond_form_allocation_online_single_page: number,
        bond_form_allocation_online_single_start: number,
        bond_form_allocation_online_single_end: number,
        bond_form_allocation_online_two_page: number,
        bond_form_allocation_online_two_start: number,
        bond_form_allocation_online_two_end: number,
        bond_form_allocation_online_abridged: number,
        bond_form_allocation_online_abridged_start: number,
        bond_form_allocation_online_abridged_end: number,
        bond_form_allocation_online_start: number,
        bond_form_allocation_online_end: number,
        bond_form_allocation_online_total: number,

        bond_form_allocation_dealer_single_page: number,
        bond_form_allocation_dealer_single_start: number,
        bond_form_allocation_dealer_single_end: number,
        bond_form_allocation_dealer_two_page: number,
        bond_form_allocation_dealer_two_start: number,
        bond_form_allocation_dealer_two_end: number,
        bond_form_allocation_dealer_abridged: number,
        bond_form_allocation_dealer_abridged_start: number,
        bond_form_allocation_dealer_abridged_end: number,
        bond_form_allocation_dealer_start: number,
        bond_form_allocation_dealer_end: number,
        bond_form_allocation_dealer_total: number,

        bond_id: number,
        bond_form_allocation_form_balance?: number
    }
    export interface IPOFormAllocationList {
        bond_form_allocation_created_at: string,
        bond_form_allocation_id: number,
        bond_form_allocation_status: number,
        bond_form_allocation_form_start: number,
        bond_form_allocation_form_end: number,
        bond_form_allocation_form_total: number,

        bond_form_allocation_online_single_page: number,
        bond_form_allocation_online_single_start: number,
        bond_form_allocation_online_single_end: number,
        bond_form_allocation_online_two_page: number,
        bond_form_allocation_online_two_start: number,
        bond_form_allocation_online_two_end: number,
        bond_form_allocation_online_abridged: number,
        bond_form_allocation_online_abridged_start: number,
        bond_form_allocation_online_abridged_end: number,
        bond_form_allocation_online_start: number,
        bond_form_allocation_online_end: number,
        bond_form_allocation_online_total: number,

        bond_form_allocation_dealer_single_page: number,
        bond_form_allocation_dealer_single_start: number,
        bond_form_allocation_dealer_single_end: number,
        bond_form_allocation_dealer_two_page: number,
        bond_form_allocation_dealer_two_start: number,
        bond_form_allocation_dealer_two_end: number,
        bond_form_allocation_dealer_abridged: number,
        bond_form_allocation_dealer_abridged_start: number,
        bond_form_allocation_dealer_abridged_end: number,
        bond_form_allocation_dealer_start: number,
        bond_form_allocation_dealer_end: number,
        bond_form_allocation_dealer_total: number,

        bond_id: number,
        bond_name: string
    }

    export interface DealerIPOFormAllocation {
        dealer_form_allocation_id: number,
        bond_id: number,
        dealer_id: number,
        dealer_name: string,
        dealer_unique_code: string,
        dealer_form_allocation_single_start: number,
        dealer_form_allocation_single_end: number,
        dealer_form_allocation_single_total: number,

        dealer_form_allocation_two_page_start: number,
        dealer_form_allocation_two_page_end: number,
        dealer_form_allocation_two_page_total: number,

        dealer_form_allocation_abridged_page_start: number,
        dealer_form_allocation_abridged_page_end: number,
        dealer_form_allocation_abridged_page_total: number,
        dealer_form_allocation_total: number
    }
    export interface DistributorType {
        distributor_type_id: number,
        distributor_type_name: string,
        distributor_type_status: number
    }
    export interface DistributorTypeList {
        distributor_type_created_at: string,
        distributor_type_id: number,
        distributor_type_name: string,
        distributor_type_status: number,
        distributor_type_updated_at: string
    }

    export interface DistributorSubType {
        distributor_sub_type_id: number,
        distributor_sub_type_name: string,
        distributor_sub_type_status: number,
        distributor_type_id: number
    }
    export interface DistributorSubTypeList {
        distributor_sub_type_created_at: string,
        distributor_sub_type_id: number,
        distributor_sub_type_name: string,
        distributor_sub_type_status: number,
        distributor_sub_type_updated_at: string,
        distributor_type_id: number,
        distributor_type_name: string
    }

    export interface DistributorCategory {
        distributor_category_id: number,
        distributor_category_name: string,
        distributor_category_status: number
    }
    export interface DistributorCategoryList {
        distributor_category_created_at: string,
        distributor_category_id: number,
        distributor_category_name: string,
        distributor_category_status: number,
        distributor_category_updated_at: string
    }
    export interface BrandTags {
        brand_tag_id: number,
        brand_tag_name: string,
        brand_tag_status: number,
    }
    export interface BrandTagsList {
        brand_tag_created_at: string,
        brand_tag_id: number,
        brand_tag_name: string,
        brand_tag_status: number,
        brand_tag_updated_at: string
    }
    export interface EligibleTags {
        eligible_investor_id: number,
        eligible_investor_name: string,
        eligible_investor_status: number,
    }
    export interface EligibleTagsList {
        eligible_investor_created_at: string,
        eligible_investor_id: number,
        eligible_investor_name: string,
        eligible_investor_status: number,
        eligible_investor_updated_at: string
    }
    export interface BrokerageCategory {
        bond_brokerage_category_id: number,
        bond_brokerage_category_name: string,
        bond_brokerage_category_status: number
    }
    export interface BrokerageCategoryList {
        bond_brokerage_category_created_at: string,
        bond_brokerage_category_id: number,
        bond_brokerage_category_name: string,
        bond_brokerage_category_status: number,
        bond_brokerage_category_updated_at: string
    }
    export interface ASBAList {
        asba_bank_code: string,
        asba_bank_created_at: string,
        asba_bank_id: number,
        asba_bank_name: string,
        asba_bank_status: number,
        asba_bank_updated_at: string,
        asba_loc_full_name: string,
        asba_loc_short_name: string
    }
    export interface BrokerList {
        broker_id: number,
        broker_name: string,
        broker_nse_code: string,
        broker_bse_code: string,
        broker_mcx_code: string,
        broker_address_line_1: string,
        broker_address_line_2: string,
        broker_address_city: string,
        broker_exchange: string,
        broker_gst_no: string,
        broker_gst_city: string,
        broker_address_pincode: string,
        broker_phone_no: string,
        broker_mobile_no: string,
        broker_email: string,
        broker_fax_no: string,
        broker_pan_no: string,
        broker_st_reg_no: string,
        broker_st_reg_category: string,
        broker_st_service_category: string,
        broker_status: number,
        broker_advisory: broker_advisory,
    }
    export interface broker_advisory {
        advisory_name: string,
        advisory_address_line_1: string,
        advisory_address_line_2: string,
        advisory_address_city: string,
        advisory_address_pincode: string,
        advisory_address_state: string,
        advisory_pan_no: string,
        advisory_gst_no: string,
        advisory_remarks: string,
    }
    
    export interface DistributList {
        distributor_id: any,
        distributor_name: string | any,
        distributor_status: any |string,
        distributor_unique_code: any,
        distributor_prospective: any,
        distributor_pan_number: any,
        distributor_arn_code: any,
        distributor_gst_number: any,
        distributor_state_id: any,
        distributor_type_id: any,
        distributor_gender: any,
        distributor_sub_type_id: any,
        distributor_category_id: any,
        distributor_net_worth: any,
        distributor_aum: any,
        distributor_location: any,
        distributor_website: any,
        distributor_existing_status: any,
        distributor_intro_by: any,
        distributor_zone: any,
        distributor_dob: any,
        distributor_empanelment_date: any,
        distributor_remarks: any,
        distributor_sharing_terms: any,
        state_name: any,
        distributor_address: distributor_address[],
        distributor_bank: distributor_bank[],
        distributor_contacts: distributor_contacts[],
        distributor_dealer: distributor_dealer[],
        distributor_demat: distributor_demat[],
        distributor_reg: distributor_reg[],
        distributor_sgl: distributor_sgl[],
        distributor_companies: distributorcompanies[],
        distributor_segment: distributorsegment[],
        distributor_emp: distributor_emp[],
        distributor_document: distributor_document[],
    }
    export interface distributor_address {
        distributor_address_id: any,
        distributor_address_type_name: any,
        distributor_address_line1: any;
        distributor_address_line2: any,
        distributor_address_line3: any,
        distributor_address_city_id: any,
        distributor_address_pincode: any,
    }
    export interface distributor_bank {
        distributor_bank_account_name?: any,
        distributor_bank_account_id: any,
        distributor_bank_account_ifsc: any,
        distributor_bank_account_number: any,
        distributor_bank_account_number_confirm?: any
    }
    export interface distributor_contacts {
        distributor_contact_id: any,
        distributor_contact_name: any,
        distributor_contact_email: any
        distributor_contact_designation: any,
        distributor_contact_landline: any,
        distributor_contact_mobile: any,
        distributor_contact_fax: any,
    }
    export interface distributor_dealer {
        distributor_dealer_id: any,
        distributor_dealer_from_date: any,
        distributor_dealer_type: any
        distributor_address_line2: any,
        distributor_dealer_name: any,
    }
    export interface distributor_demat {
        distributor_demat_account_id: any,
        distributor_demat_account_depository: any,
        distributor_demat_account_dp_name: any
        distributor_demat_account_dp_id: any,
        distributor_demat_account_client_id: any,
    }
    export interface distributor_reg {
        distributor_registration_id: any,
        distributor_registration_exchange_name: any,
        distributor_registration_ap_reg_name: any
        distributor_registration_approval_date: any,
    }
    export interface distributor_sgl {
        distributor_sgl_account_id: any,
        distributor_sgl_account_bank_name: any,
        distributor_sgl_account_name: any
        distributor_sgl_account_number: any,
    }
    export interface distributorcompanies {
        distributor_companies_id: null,
        companies_id: any,
        companies_name: null,
        distributor_companies_selected: null,
        
    }
    export interface distributorsegment {
        distributor_segment_id: null,
        segment_name: null,
        segment_id: any,
        distributor_segment_selected: null,
    }
    export interface distributor_emp {
        distributor_empanelment_id: null,
        empanelment_name: null,
        empanelment_id: any,
        distributor_empanelment_selected: null,
    }
    export interface distributor_document {
        distributor_doc_id: any,
        document_id: any,
        distributor_doc_url: any,
    }
    export interface IfscDetails {
        ADDRESS: string;
        BANK: string;
        BANKCODE: string;
        BRANCH: string;
        CENTRE: string;
        CITY: string;
        CONTACT: string;
        DISTRICT: string;
        IFSC: string;
        IMPS: boolean;
        ISO3166: string;
        MICR: string;
        NEFT: boolean;
        RTGS: boolean;
        STATE: string;
        SWIFT: string;
        UPI: boolean;
      }
    
    export interface CustomerOrderList {
        bond_id: number,
        bond_name: string,
        customer_name: string
        order_amount: string,
        order_datetime: string,
        order_id: number,
        order_order_id: number,
        order_quantity: number,
        order_status: number,
        user_id: number,
        order_type: number,
        order_pdf: string,
        order_number: string
    }
    export interface OrderPayment {
        bond_id: number,
        ipo_order_id: number,
        ipo_order_payment_amount: string,
        ipo_order_payment_created_at: string,
        ipo_order_payment_datetime: string,
        ipo_order_payment_id: number,
        ipo_order_payment_ref_bank_id: number,
        ipo_order_payment_ref_city_id: number,
        ipo_order_payment_status: number,
        ipo_order_payment_updated_at: string,
        ipo_order_payment_upi: string,
        payment_mode_id: string
    }
    export interface bondOrderPayment {
        bond_id: number,
        bond_order_id: number,
        bond_order_payment_amount: string,
        bond_order_payment_created_at: string,
        bond_order_payment_datetime: string,
        bond_order_payment_id: number,
        bond_order_payment_ref: number,
        bond_order_payment_ref_bank_id: number,
        bond_order_payment_status: number,
        bond_order_payment_updated_at: string,
        // ipo_order_payment_upi: string,
        payment_mode_id: string
    }
    export interface SgbOrderPayment {
        sgb_order_payment_status: number,
    }
    export interface IPOOrderStatus {
        ipo_order_id: number,
        ipo_order_status: number,
        ipo_order_status_created_at: string,
        ipo_order_status_id: number
    }
    export interface BondOrderStatus {
        bond_order_id: number,
        bond_order_status: number,
        bond_order_status_created_at: string,
        bond_order_status_id: number,
        bond_order_status_updated_at: string
    }
    export interface SgbOrderStatus {
        sgb_order_status: number,
    }
    export interface IPOOrderDetailView {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: number,
        bond_isin_number: string,
        bond_serial_number_id: number,
        customer_details: personalDetail,
        ipo_ncd_series: any[],
        ipo_order_amount: string,
        ipo_order_application_no: string,
        ipo_order_created_at: string,
        ipo_order_current_payment_id: number,
        ipo_order_current_status_id: number,
        ipo_order_customer_id: number,
        ipo_order_datetime: string,
        ipo_order_demant_account_id: number,
        ipo_order_demant_account_type: number,
        ipo_order_form_number: number,
        ipo_order_id: number,
        ipo_order_investor_category_id: number,
        ipo_order_investor_type_id: number,
        ipo_order_payments: OrderPayment[],
        ipo_order_pdf: string,
        ipo_order_quantity: number,
        ipo_order_second_appl_name: string,
        ipo_order_second_pan: string,
        ipo_order_status: IPOOrderStatus[],
        ipo_order_sub_category_code: number,
        ipo_order_third_appl_name: string,
        ipo_order_third_pan: string,
        ipo_order_total_amount: number,
        ipo_order_updated_at: string,
        order_id: number,
        order_type: number,
        order_number: string
    }
    export interface BondOrderDetailView {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: number,
        bond_isin_number: string,
        bond_order_amount: string,
        bond_order_created_at: string,
        bond_order_current_payment_id: number,
        bond_order_current_status_id: number,
        bond_order_customer_id: number,
        bond_order_datetime: string,
        bond_order_demant_account_id: number,
        bond_order_demant_account_type: number,
        bond_order_id: number,
        bond_order_payments: bondOrderPayment[],
        bond_order_quantity: number,
        bond_order_status: BondOrderStatus[],
        bond_order_updated_at: string,
        bse_rfq_id: number,
        customer_details: personalDetail,
        order_id: number,
        order_type: number
        order_number: string
        bonds_yeild: string,
        bond_coupon_rate: number,
        bond_order_payment_amount: number
    }
    export interface SgbOrderDetailView {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: number,
        bond_isin_number: string,
        sgb_order_amount: string,
        sgb_order_created_at: string,
        bond_order_current_payment_id: number,
        bond_order_current_status_id: number,
        bond_order_customer_id: number,
        sgb_order_datetime: string,
        bond_order_demant_account_id: number,
        bond_order_demant_account_type: number,
        bond_order_id: number,
        sgb_order_payments: SgbOrderPayment[],
        sgb_order_quantity: number,
        sgb_order_status: SgbOrderStatus[],
        bond_order_updated_at: string,
        bse_rfq_id: number,
        customer_details: personalDetail,
        order_id: number,
        order_type: number
    }
    export interface CustomerIPOOrderDetailView {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        customer_details: personalDetail,
        ipo_order_payments: OrderPayment[],
        ipo_order_created_at: string,
        ipo_order_datetime: string,
        ipo_order_amount: string,
        ipo_order_quantity: number,
        ipo_order_pdf: string,
        ipo_order_status: IPOOrderStatus[],
        order_id: number,
        ipo_order_total_amount: number,
        order_number: string
    }
    export interface CustomerBondOrderDetailView {
        bond_detail: BondDetails,
        bond_id: number,
        bond_isin_number: string,
        bond_order_amount: string,
        bond_order_created_at: string,
        bond_order_current_payment_id: number,
        bond_order_current_status_id: number,
        bond_order_customer_id: number,
        bond_order_datetime: string,
        bond_order_demant_account_id: number,
        bond_order_demant_account_type: number,
        bond_order_id: number,
        bond_order_payments: OrderPayment[],
        bond_order_quantity: number,
        bond_order_status: IPOOrderStatus[],
        bond_order_updated_at: string,
        bse_rfq_id: number,
        customer_details: personalDetail,
        order_id: number,
        order_type: number
    }
    export interface BondSubcribeList {
        bond_subcriber_created_at: string,
        bond_subcriber_email: string,
        bond_subcriber_id: number
    }
    export interface bank {
        bank_cancel_cheque_image: string;
        bank_id: number;
        bank_is_verified: number;
        document_name: string;
        last_update: string;
        remarks_id: number;
    }
    export interface CustomerEnquire {
        bond_id: number,
        bond_isin_number: string,
        bond_logo: string,
        bond_name: string,
        buy_sell_id: number,
        buy_sell_name: string,
        customer_bond_enquire_created_at: string,
        customer_bond_enquire_id: number,
        customer_bond_enquire_number: number,
        customer_bond_enquire_price: string,
        customer_bond_enquire_quantity: number,
        customer_bond_enquire_status: number,
        customer_bond_enquire_yield: string,
        customer_email_id: string,
        customer_id: string,
        customer_mobile_number: string,
        customer_name: string,
        settlement_date_id: number,
        settlement_date_name: string,
        user_id: number
    }
    export interface aidcc {
        ai_dcc_created_at: string,
        ai_dcc_id: number
        ai_dcc_name: string;
        ai_dcc_updated_at: string;
    }
    export interface Yield {
        yield_type_created_at: string,
        yield_type_id: number
        yield_type_name: string;
        yield_type_updated_at: string;
    }
    export interface settlement {
        settlement_date_created_at: string,
        settlement_date_id: number
        settlement_date_name: string;
        settlement_date_updated_at: string;
    }
    export interface ActiveBondList {
        bond_face_value: string,
        bond_id: number,
        bond_isin_number: string,
        bond_issuer_name: string,
        bond_listing_bond_id: number,
        bond_listing_end_date: string,
        bond_listing_id: number,
        bond_listing_price: string,
        bond_listing_publish_unpublish: number,
        bond_listing_quantity: string,
        bond_listing_start_date: string,
        bond_listing_status: number,
        bond_listing_yield: string,
        bond_maturity_date: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_type: number,
        buy_sell_id: number,
        buy_sell_name: string,
        call_date: string
    }

    export interface AllBondList {
        bond_face_value: string,
        bond_id: number,
        bond_isin_number: string,
        bond_issuer_name: string,
        bond_listing_bond_id: number,
        bond_listing_status: number,
        bond_maturity_date: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_type: number,
        call_date: string
    }

    export interface PriceChangeLogs {
        bond_id: number,
        buy_sell_id: number,
        buy_sell_name: string,
        change_log_end_date: string,
        change_log_id: number,
        change_log_last_price: string,
        change_log_last_yield: string,
        change_log_modified_at: string,
        change_log_modified_by: string,
        change_log_start_date: string,
        change_log_status: string,
        tm_dealer_id: number,
        change_log_quantity: number
    }

    export interface UserDetailsMyOrders {
        bond_id: number,
        bond_isin_number: string,
        bond_logo: string,
        bond_name: string,
        order_amount: string,
        order_datetime: string,
        order_id: number,
        order_order_id: number,
        order_payment_status: number,
        order_pdf: string,
        order_quantity: number,
        order_status: number,
        order_type: number,
    }
}

export namespace FormDataTypes {
    export interface SelectOption {
        value: string,
        label: string
    }
}