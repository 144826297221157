import moment from "moment";
import { APIData } from "./DataTypes";
import dayjs, { Dayjs } from 'dayjs';
import { string } from "yup";

export namespace InitialData {

    export const Login: APIData.Login = {
        mobile_number: "",
    }

    export const Otp: APIData.Otp = {
        otp: ""
    }

    export const BondList: APIData.BondList = {
        bond_about_this_ipo: '',
        bond_allotment_date: '',
        bond_app_no_series: '',
        bond_arranger_name: '',
        bond_asba_app_no_series_1: '',
        bond_asba_app_no_series_2: '',
        bond_bonds_name: '',
        bond_brokerage_details_status: '',
        bond_brokerage_structure: '',
        bond_calc_int_on_holidays: '',
        bond_category_hni: '',
        bond_category_institutional: '',
        bond_category_instructions: '',
        bond_category_non_institutional: '',
        bond_category_retail: '',
        bond_closer_date: '',
        bond_closing_date: '',
        bond_coupon_amount: '',
        bond_coupon_date: '',
        bond_coupon_on: '',
        bond_coupon_rate: '',
        bond_created_at: '',
        bond_deposit: '',
        bond_disclaimer: '',
        bond_dmat_book_closer_date: '',
        bond_e_form_incentive: '',
        bond_early_bid_incentive: '',
        bond_effort_basis: '',
        bond_exchange: '',
        bond_face_value: '',
        bond_final_issue_amount: '',
        bond_green_shoe: 0,
        bond_green_shoe_size: '',
        bond_gst: '',
        bond_guaranted_by: '',
        bond_id: 0,
        bond_int_on_app_money: '',
        bond_int_on_maturity: '',
        bond_int_on_refund_money: '',
        bond_interest_days: '',
        bond_interest_frequency: '',
        bond_interest_type: '',
        bond_ip_date: "",
        bond_ipo_sell_windows_days: '',
        bond_isin_number: '',
        bond_issue_date: '',
        bond_issue_price: '',
        bond_issue_size: '',
        bond_issue_status: '',
        bond_issuer_name: '',
        bond_key_highlights: '',
        bond_listing: '',
        bond_listing_circle: '',
        bond_logo: '',
        bond_maturity_amount: '',
        bond_maturity_date: '',
        bond_minimum_application: '',
        bond_nature_of_instrument: '',
        bond_opening_date: '',
        bond_other_incentive: '',
        bond_our_status: '',
        bond_price_per_bond: '',
        bond_procurement_amount: '',
        bond_product_note: '',
        bond_rbi_loan_code: '',
        bond_registrar_name: '',
        bond_script_id: 0,
        bond_security_code: '',
        bond_security_type: '',
        bond_series_instructions: '',
        bond_st: '',
        bond_sub_category_code: '',
        bond_term_condition_link: '',
        bond_trustee_name: '',
        bond_type: 0,
        bond_updated_at: '',
        bond_upi_app_no_series_1: '',
        bond_upi_app_no_series_2: '',
        bond_name: '',
    }

    export const BondCallDetails: APIData.BondCallDetails = {
        bond_call_detail_id: 0,
        bond_call_detail_datetime:null,
        bond_call_detail_amount: null
    }

    export const BondPutDetails: APIData.BondPutDetails = {
        bond_put_detail_id: 0,
        bond_put_detail_datetime: null,
        bond_put_detail_amount: null
    }

    export const BondRatingDetails: APIData.BondRatingDetails = {
        bond_rating_agency: null,
        bond_rating_certificate: null,
        bond_rating: 0
    }
    export const BrokerAdvisory: APIData.broker_advisory = {
        advisory_name: '',
        advisory_address_line_1: '',
        advisory_address_line_2: '',
        advisory_address_city: '',
        advisory_address_pincode: '',
        advisory_address_state: '',
        advisory_pan_no: '',
        advisory_gst_no: '',
        advisory_remarks: '',
    }
       export const distributoraddress: APIData.distributor_address = {
        distributor_address_id: null,
        distributor_address_type_name: null,
        distributor_address_line1: null,
        distributor_address_line2: null,
        distributor_address_line3: null,
        distributor_address_city_id: null,
        distributor_address_pincode: null,
    }
      export const distributorbank: APIData.distributor_bank = {
        distributor_bank_account_id: null,
        distributor_bank_account_ifsc: '',
        distributor_bank_account_number: null,
    }
    export const distributorcontacts: APIData.distributor_contacts = {
        distributor_contact_id: null,
        distributor_contact_name: null,
        distributor_contact_email: null,
        distributor_contact_designation: null,
        distributor_contact_landline: null,
        distributor_contact_mobile: null,
        distributor_contact_fax: null,
    }
    export const distributordealer: APIData.distributor_dealer = {
        distributor_dealer_id: null,
        distributor_dealer_from_date: null,
        distributor_dealer_type: null,
        distributor_address_line2: null,
        distributor_dealer_name: null,
    }
    export const distributordemat: APIData.distributor_demat = {
        distributor_demat_account_id: null,
        distributor_demat_account_depository: null,
        distributor_demat_account_dp_name:  null,
        distributor_demat_account_dp_id: null,
        distributor_demat_account_client_id: null,
    }
    export const distributorreg: APIData.distributor_reg = {
        distributor_registration_id: null,
        distributor_registration_exchange_name:  null,
        distributor_registration_ap_reg_name: null,
        distributor_registration_approval_date: null,
    }
    export const distributorsgl: APIData.distributor_sgl = {
        distributor_sgl_account_id: null,
        distributor_sgl_account_bank_name: null,
        distributor_sgl_account_name: null,
        distributor_sgl_account_number: null,
    }
    export const distributorsegment: APIData.distributorsegment = {
        distributor_segment_id: null,
        segment_name: null,
        segment_id: null,
        distributor_segment_selected: null,
    }
    export const distributordocument: APIData.distributor_document = {
        distributor_doc_id: null,
        document_id: null,
        distributor_doc_url: null,
    }
    export const BondIssuerDetails: APIData.BondIssuerDetails = {
        bond_issuer_id: 0,
        bond_issuer_type: 1,
        bond_issuer_total_annual_revenue: null,
        bond_issuer_year_of_inception: null,
        bond_issuer_industry: null,
        bond_issuer_head_office: null,
        bond_type_of_issuer: 1,
        bond_issuer_finance_details: null,
        bond_issuer_documents: null,
        bond_issuer_profile_link: null,
        bond_issuer_about: null,
        bond_issuer_series: null,
        bond_issuer_tenor: 1,
        bond_issuer_coupon_type: 1,
        bond_issuer_frequency_of_interest: 1,
        bond_issuer_first_ip_date: null,
        bond_issuer_ip_dates: null,
        bond_issuer_effective_yield: null,
        bond_id: 0,
        // bond_issuer_isin_number: ''
    }

    export const BondNcdsDetails: APIData.BondNcdsDetails = {
        bond_ncd_series_id: 0,
        bond_specfic_terms_for_each_ncd: null,
        bond_frequency_of_interest_payment: null,
        bond_tenor: null,
        bond_coupon_per_annum: 0,
        bond_redemption_amount_on_maturity: 0,
        bond_effective_yield_per_annum: 0
    }

    export const BondCashflowDetails: APIData.BondCashflowDetails = {
        bond_cashflow_date: null,
        bond_cashflow_amount: 0,
        bond_cashflow_days: '',
        bond_cashflow_type: 0
    }

    export const BondBrandTags: APIData.BondBrandTags = {
        brand_tag_id: 0,
        brand_tag_name: '',
        bond_brand_tag_id: 0,
        bond_brand_tag_available: 0
    }

    // export const BondEligibleInvestors: APIData.BondEligibleInvestors = {
    //     eligible_investor_id: 0,
    //     eligible_investor_name: '',
    //     bond_eligible_investor_id: 0
    // }

    export const BondNcdAvailable: APIData.BondNcdAvailable = {
        bond_ncd_id: 0,
        bond_ncd_minimum_application: 0,
        bond_ncd_frequency_of_interest_payment: 0,
        bond_ncd_in_multiple_terms_of_thereafter: 0,
        bond_ncd_issue_price_of_ncd: 0,
        bond_ncd_tenor: 0,
        bond_ncd_coupon: 0,
        bond_ncd_effective_yield: 0,
        bond_ncd_mode_of_interest_payment: 0,
        bond_ncd_redemption_amount: 0,
        bond_ncd_put_and_option: 0
    }

    export const BondNcdSeries: APIData.BondNcdSeries = {
        bond_ncd_series_id: 0,
        bond_ncd_series_number: 0,
        bond_ncd_minimum_application: 0,
        bond_ncd_frequency_of_interest_payment: null,
        bond_ncd_in_multiple_terms_of_thereafter: 0,
        bond_ncd_issue_price_of_ncd: null,
        bond_ncd_tenor: 0,
        bond_ncd_coupon: null,
        bond_ncd_effective_yield: null,
        bond_ncd_mode_of_interest_payment: null,
        bond_ncd_redemption_amount: null,
        bond_ncd_put_and_option: 0
    }

    export const BondBrokerageDetails: APIData.BondBrokerageDetails = {
        bond_brokerage_detail_id: 0,
        bond_breakage_series_number: 0,
        bond_brokerage_category_id: 0,
        bond_brokerage_value: '',
        bond_id: 0
    }

    export const BondEligibleInvestors: APIData.BondEligibleInvestors = {
        eligible_investor_id: 0,
        eligible_investor_name: '',
        bond_eligible_investor_id: 0,
        eligible_investors_available: 0,
    }

    export const bond_upcoming_issues: APIData.bond_upcoming_issues = {
        bond_upcoming_issues_name: '',
        bond_upcoming_issues_subscription_start_date: "",
        bond_upcoming_issues_subscription_end_date: "",
        bond_upcoming_issues_allotment_date: ""
    }

    export const bond_highlights: APIData.bond_highlights = {
        bond_highlights_title: ''
    }

    export const bond_benefits: APIData.bond_benefits = {
        bond_benefits_title: '',
        bond_benefits_icon: '',
        bond_benefits_description: ''
    }

    export const BondDetails: APIData.BondDetails = {
        bond_isin_number: '',
        bond_issuer_name: '',
        bond_name: '',
        bond_nature_of_instrument: '',
        bond_security_type: '',
        bond_st: 0,
        bond_security_code: 0,
        bond_guaranted_by: '',
        bond_face_value: 0,
        bond_issue_price: 0,
        bond_price_per_bond: 0,
        bond_issue_date: '',
        bond_ip_date: '',
        bond_interest_frequency: '',
        bond_interest_type: '',
        bond_coupon_on: '',
        bond_coupon_rate: '',
        bond_govt_guranatee: 0,
        bond_interest_days: 0,
        bond_closer_date: '',
        bond_opening_date:  "",
        bond_dmat_book_closer_date: "",
        bond_rbi_loan_code: '',
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: '',
        bond_listing: 0,
        bond_minimum_application: '',
        bond_issue_size: '',
        bond_logo: '',
        bond_maturity_date: '',
        bond_maturity_amount: '',
        bond_coupon_date: '',
        bond_coupon_amount: '',
        bond_key_highlights: '',
        bond_disclaimer: '',
        bond_term_condition_link: '',
        bond_category_institutional: '',
        bond_category_non_institutional: '',
        bond_category_hni: 0,
        bond_category_retail: 0,
        bond_about_this_ipo: '',
        bond_security_type_code: 0,
        bonds_yeild: '',
        bond_call_details: [BondCallDetails],
        bond_put_details: [BondPutDetails],
        bond_cashflow_details: [BondCashflowDetails],
        bond_rating_details: [BondRatingDetails],
        bond_issuer_details: [BondIssuerDetails],
        bond_brand_tags: [],
        bond_eligible_investors: [],
        bond_ncd_available: [BondNcdAvailable],
        bond_ncd_series: [BondNcdSeries],
        bond_brokerage_details: [BondBrokerageDetails]
    }

    export const BondAdd: APIData.BondAdd = {
        bond_id: 0,
        bond_isin_number: null,
        bond_issuer_name: null,
        // bond_asba_details: '',
        bond_name: null,
        bond_nature_of_instrument: 0,
        bond_security_type: 0,
        bond_st: 0,
        bond_security_code: null,
        bond_guaranted_by: null,
        bond_face_value: null,
        bond_issue_price: null,
        bond_price_per_bond: null,
        bond_issue_date: null,
        bond_ip_date: null,
        bond_interest_frequency: null,
        bond_interest_type: 1,
        bond_ncd_status: 0,
        bond_coupon_on: null,
        bond_coupon_rate: null,
        bond_interest_days: null,
        bond_closer_date: null,
        bond_dmat_book_closer_date: null,
        bond_rbi_loan_code: null,
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: null,
        bond_listing: 0,
        bond_minimum_application: null,
        bond_issue_size: null,
        bond_logo: null,
        bonds_yeild: 0,
        bond_govt_guranatee: 0,
        // bond_type: 1,
        bond_maturity_date:null,
        bond_maturity_amount: null,
        bond_coupon_date: null,
        bond_coupon_amount: null,
        bond_key_highlights: null,
        bond_disclaimer: null,
        bond_term_condition_link: null,
        bond_category_institutional: null,
        bond_category_non_institutional: null,
        bond_category_hni: null,
        bond_category_retail: null,
        bond_about_this_ipo: null,
        // bond_ncd_status: 0,
        // bond_brokerage_details_status: 0,
        // bond_issue_status: 1,
        // bond_effort_basis: 1,
        // bond_registrar_name: 1,
        // bond_trustee_name: 1,
        // bond_our_status: 1,
        // bond_int_on_app_money: null,
        // bond_int_on_refund_money: null,
        // bond_early_bid_incentive: null,
        // bond_other_incentive: null,
        // bond_e_form_incentive: null,
        // bond_opening_date: "",
        // bond_closing_date: "",
        // bond_allotment_date: "",
        // bond_deposit: '',
        // bond_green_shoe: 1,
        // bond_green_shoe_size: '',
        // bond_final_issue_amount: '',
        // bond_procurement_amount: null,
        // bond_gst: 1,
        // bond_script_id: '',
        // bond_arranger_name: null,
        // bond_upi_app_no_series_1: '',
        // bond_upi_app_no_series_2: '',
        // bond_asba_app_no_series_1: '',
        // bond_asba_app_no_series_2: '',
        // bond_ipo_sell_windows_days: 0,
        // bond_category_instructions: '',
        // bond_sub_category_code: '',
        // bond_app_no_series: '',
        // bond_series_instructions: '',
        // bond_product_note: '',
        // bond_listing_circle: '',
        // bond_brokerage_structure: '',

        // bond_details: BondDetails,
        bond_call_details: [BondCallDetails],
        bond_put_details: [BondPutDetails],
        bond_cashflow_details: [BondCashflowDetails],
        bond_rating_details: [BondRatingDetails],
        bond_issuer_details: [BondIssuerDetails],
        bond_brand_tags: [],
        bond_eligible_investors: [],
        bond_ncd_available: [BondNcdAvailable],
        bond_ncd_series: [BondNcdSeries],
        bond_status: 1
    }

    export const IPOAdd: APIData.IPOAdd = {
        bond_id: 0,
        bond_isin_number: null,
        bond_issuer_name: null,
        bond_asba_details: null,
        bond_name: null,
        bond_nature_of_instrument: 1,
        bond_security_type: 1,
        bond_st: 1,
        bond_security_code: null,
        bond_guaranted_by: null,
        bond_face_value: null,
        bond_issue_price: null,
        bond_price_per_bond: null,
        bond_issue_date: "",
        bond_form_start_number: 0,
        bond_form_end_number: 0,
        bond_status: 1,
        bond_ip_date: "",
        bond_interest_frequency: '',
        bond_interest_type: 1,
        bond_coupon_on: null,
        bond_coupon_rate: null,
        bond_interest_days: null,
        bond_closer_date: "",
        bond_dmat_book_closer_date: "",
        bond_rbi_loan_code: null,
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: null,
        bond_listing: 0,
        bond_minimum_application: null,
        bond_issue_size: null,
        bond_logo: null,
        bond_type: 1,
        bond_maturity_date: "",
        bond_maturity_amount: null,
        bond_coupon_date: "",
        bond_coupon_amount: null,
        bond_key_highlights: null,
        bond_disclaimer: null,
        bond_term_condition_link: null,
        bond_category_institutional: null,
        bond_category_non_institutional: null,
        bond_category_hni: null,
        bond_category_retail: null,
        bond_about_this_ipo: '',
        bond_ncd_status: 0,
        bond_brokerage_details_status: 0,
        bond_issue_status: 1,
        bond_effort_basis: 1,
        bond_registrar_name: 1,
        bond_trustee_name: 1,
        bond_our_status: 1,
        bond_int_on_app_money: null,
        bond_int_on_refund_money: null,
        bond_early_bid_incentive: null,
        bond_other_incentive: null,
        bond_e_form_incentive: null,
        bond_opening_date: "",
        bond_closing_date: "",
        bond_allotment_date: "",
        bond_deposit: null,
        bond_green_shoe: 1,
        bond_green_shoe_size: null,
        bond_final_issue_amount: null,
        bond_procurement_amount: null,
        bond_gst: 1,
        bond_script_id: null,
        bond_arranger_name: null,
        bond_upi_app_no_series_1: null,
        bond_upi_app_no_series_2: null,
        bond_asba_app_no_series_1: null,
        bond_asba_app_no_series_2: null,
        bond_ipo_sell_windows_days: 0,
        bond_category_instructions: null,
        bond_sub_category_code: null,
        bond_app_no_series: null,
        bond_series_instructions: null,
        bond_product_note: null,
        bond_listing_circle: null,
        bond_brokerage_structure: null,
        bonds_yeild: null,

        bond_call_details: [BondCallDetails],
        bond_put_details: [BondPutDetails],
        bond_rating_details: [BondRatingDetails],
        bond_issuer_details: [BondIssuerDetails],
        bond_brand_tags: [],
        bond_eligible_investors: [],
        bond_ncd_available: [BondNcdAvailable],
        bond_brokerage_details: [BondBrokerageDetails],
        bond_ncd_series: [BondNcdSeries],
        
    }

    export const IPODetailView: APIData.IPODetailView = {
        bond_id: 0,
        bond_isin_number: '',
        bond_issuer_name: '',
        bond_asba_details: '',
        bond_name: '',
        bond_nature_of_instrument: 1,
        bond_security_type: 1,
        bond_st: 1,
        bond_security_code: '',
        bond_guaranted_by: '',
        bond_face_value: '',
        bond_issue_price: null,
        bond_price_per_bond: null,
        bond_issue_date: "",
        bond_ip_date: "",
        bond_interest_frequency: '',
        bond_interest_type: 1,
        bond_coupon_on: '',
        bond_coupon_rate: '',
        bond_interest_days: 0,
        bond_closer_date: "",
        bond_dmat_book_closer_date: "",
        bond_rbi_loan_code: '',
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: '',
        bond_listing: 0,
        bond_minimum_application: '',
        bond_issue_size: '',
        bond_logo: '',
        bond_type: 1,
        bond_maturity_date: "",
        bond_maturity_amount: '',
        bond_coupon_date: "",
        bond_coupon_amount: '',
        bond_key_highlights: '',
        bond_disclaimer: '',
        bond_term_condition_link: '',
        bond_category_institutional: '',
        bond_category_non_institutional: '',
        bond_category_hni: '',
        bond_category_retail: '',
        bond_about_this_ipo: '',
        bond_ncd_status: 0,
        bond_brokerage_details_status: 0,
        bond_issue_status: 1,
        bond_effort_basis: 1,
        bond_registrar_name: 1,
        bond_trustee_name: 1,
        bond_our_status: 1,
        bond_int_on_app_money: '',
        bond_int_on_refund_money: '',
        bond_early_bid_incentive: '',
        bond_other_incentive: '',
        bond_e_form_incentive: '',
        bond_opening_date: "",
        bond_closing_date: "",
        bond_allotment_date: "",
        bond_deposit: '',
        bond_green_shoe: 0,
        bond_green_shoe_size: '',
        bond_final_issue_amount: '',
        bond_procurement_amount: '',
        bond_gst: 1,
        bond_script_id: '',
        bond_arranger_name: '',
        bond_upi_app_no_series_1: '',
        bond_upi_app_no_series_2: '',
        bond_asba_app_no_series_1: '',
        bond_asba_app_no_series_2: '',
        bond_ipo_sell_windows_days: 0,
        bond_category_instructions: '',
        bond_sub_category_code: '',
        bond_app_no_series: '',
        bond_series_instructions: '',
        bond_product_note: '',
        bond_listing_circle: '',
        bond_brokerage_structure: '',
        bonds_yeild: '',
        bond_depository: 0,

        bond_call_details: [BondCallDetails],
        bond_put_details: [BondPutDetails],
        bond_rating_details: [BondRatingDetails],
        bond_issuer_details: [BondIssuerDetails],
        bond_brand_tags: [],
        bond_eligible_investors: [BondEligibleInvestors],
        bond_ncd_available: [BondNcdAvailable],
        bond_ncd_series: [BondNcdSeries],
        bond_brokerage_details: [BondBrokerageDetails]
    }

    export const Dealer: APIData.DealerList = {
        dealer_address_line1: '',
        dealer_address_line2: '',
        dealer_address_line3: '',
        dealer_branch_name: '',
        dealer_created_at: '',
        dealer_dob: '',
        dealer_doj: '',
        dealer_email: '',
        dealer_employee_code: '',
        dealer_gender: 0,
        dealer_id: 0,
        dealer_login_hash: '',
        dealer_login_otp: '',
        dealer_login_otp_time: '',
        dealer_login_status: 0,
        dealer_managed_by: '',
        dealer_mobile: '',
        dealer_name: '',
        dealer_password: '',
        dealer_phone: '',
        dealer_remarks: '',
        dealer_status: 0,
        dealer_unique_code: '',
        dealer_updated_at: '',
        dealer_user_name: '',
        dealer_state_name: '',
        dealer_city_name: '',
        dealer_state_id: 0,
        dealer_city_id: 1
    }
    export const DealerAdd: APIData.DealerAdd = {
        dealer_address_line1: '',
        dealer_address_line2: '',
        dealer_address_line3: '',
        dealer_branch_name: '',
        dealer_dob: "",
        dealer_doj: "",
        dealer_email: '',
        dealer_employee_code: '',
        dealer_gender: 1,
        dealer_id: 0,
        dealer_managed_by: '',
        dealer_mobile: '',
        dealer_name: '',
        dealer_password: '',
        dealer_phone: '',
        dealer_remarks: '',
        dealer_unique_code: '',
        dealer_user_name: '',
        dealer_status: 1,
        dealer_state_id: 0,
        dealer_city_id: 1
    }
    export const SGBList: APIData.SGBList = {
        bond_id: 0,
        bond_isin_number: '',
        bond_issuer_name: '',
        bond_asba_details: '',
        bond_name: '',
        bond_nature_of_instrument: 0,
        bond_security_type: 0,
        bond_st: 0,
        bond_security_code: '',
        bond_guaranted_by: '',
        bond_face_value: null,
        bond_issue_price: null,
        bond_price_per_bond: null,
        bond_issue_date: '',
        bond_ip_date: '',
        bond_interest_frequency: '',
        bond_interest_type: 0,
        bond_coupon_on: '',
        bond_coupon_rate: '',
        bond_interest_days: 0,
        bond_closer_date: '',
        bond_dmat_book_closer_date: '',
        bond_rbi_loan_code: '',
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: '',
        bond_listing: 0,
        bond_minimum_application: '',
        bond_issue_size: '',
        bond_logo: '',
        bond_type: 0,
        bond_maturity_date: '',
        bond_maturity_amount: null,
        bond_coupon_date: '',
        bond_coupon_amount: null,
        bond_key_highlights: '',
        bond_disclaimer: '',
        bond_term_condition_link: '',
        bond_category_institutional: null,
        bond_category_non_institutional: null,
        bond_category_hni: null,
        bond_category_retail: null,
        bond_about_this_ipo: '',
        bond_ncd_status: 0,
        bond_brokerage_details_status: 0,
        bond_issue_status: 0,
        bond_effort_basis: 0,
        bond_registrar_name: 0,
        bond_trustee_name: 0,
        bond_our_status: 0,
        bond_int_on_app_money: null,
        bond_int_on_refund_money: null,
        bond_early_bid_incentive: null,
        bond_other_incentive: null,
        bond_e_form_incentive: null,
        bond_opening_date: '',
        bond_closing_date: '',
        bond_allotment_date: '',
        bond_deposit: '',
        bond_green_shoe: 0,
        bond_green_shoe_size: '',
        bond_final_issue_amount: '',
        bond_procurement_amount: null,
        bond_gst: 0,
        bond_script_id: '',
        bond_arranger_name: null,
        bond_upi_app_no_series_1: '',
        bond_upi_app_no_series_2: '',
        bond_asba_app_no_series_1: '',
        bond_asba_app_no_series_2: '',
        bond_ipo_sell_windows_days: 0,
        bond_category_instructions: '',
        bond_sub_category_code: '',
        bond_app_no_series: '',
        bond_series_instructions: '',
        bond_product_note: '',
        bond_listing_circle: '',
        bond_brokerage_structure: '',
    }
    export const SGBAdd: APIData.SGBAdd = {
        bond_id: 0,
        bond_issuer_name: '',
        bond_name: '',
        bond_nature_of_instrument: 0,
        bond_security_type: 0,
        bond_st: 0,
        bond_security_code: '',
        bond_security_type_code: '',
        bond_govt_guranatee: 0,
        bond_isin_number: '',
        bond_face_value: 0,
        bond_issue_price: 0,
        bonds_price_per_gram: 0,
        bond_purchase_limit: 0,
        bond_purchase_limit_metric: 0,
        bond_coupon_rate: null,
        bonds_yeild: 0,
        bond_interest_type: '',
        bond_interest_frequency: '',
        bond_allotment_date: "",
        bond_opening_date: "",
        bond_closing_date: "",
        bonds_next_interest_payment_date: "",
        bond_maturity_date: "",
        bond_dmat_book_closer_date: "",
        exit_option_available: 0,
        bond_calc_int_on_holidays: 0,
        bond_int_on_maturity: 0,
        bond_exchange: '',
        bond_listing: 0,
        bonds_banner_rcb_notice_link: '',
        bond_logo: '',
        bond_about_this_ipo: '',
        bond_category_institutional: 0,
        bond_category_non_institutional: 0,
        bond_category_hni: 0,
        bond_category_retail: 0,
        bond_coupon_date: "",
        bond_coupon_amount: 0,
        bonds_banner_content_title: '',
        bonds_banner_content_sub_title: '',
        bonds_banner_content_image: '',
        bond_issue_document: '',
        bond_disclaimer: '',
        bond_term_condition_link: '',
        bond_benefits: [bond_benefits],
        bond_highlights: [bond_highlights],
        bond_upcoming_issues_status: 0,
        bond_upcoming_issues: [bond_upcoming_issues],
        bond_cashflow_details: [BondCashflowDetails],
        bond_brand_tags: [],
        bond_eligible_investors: [],
        bond_status: 1
    }
    export const IPOFormAllocation: APIData.IPOFormAllocation = {
        bond_form_allocation_id: 0,
        bond_form_allocation_status: 0,
        bond_form_allocation_form_start: 0,
        bond_form_allocation_form_end: 0,
        bond_form_allocation_form_total: 0,

        bond_form_allocation_online_single_page: 0,
        bond_form_allocation_online_single_start: 0,
        bond_form_allocation_online_single_end: 0,
        bond_form_allocation_online_two_page: 0,
        bond_form_allocation_online_two_start: 0,
        bond_form_allocation_online_two_end: 0,
        bond_form_allocation_online_abridged: 0,
        bond_form_allocation_online_abridged_start: 0,
        bond_form_allocation_online_abridged_end: 0,
        bond_form_allocation_online_start: 0,
        bond_form_allocation_online_end: 0,
        bond_form_allocation_online_total: 0,

        bond_form_allocation_dealer_single_page: 0,
        bond_form_allocation_dealer_single_start: 0,
        bond_form_allocation_dealer_single_end: 0,
        bond_form_allocation_dealer_two_page: 0,
        bond_form_allocation_dealer_two_start: 0,
        bond_form_allocation_dealer_two_end: 0,
        bond_form_allocation_dealer_abridged: 0,
        bond_form_allocation_dealer_abridged_start: 0,
        bond_form_allocation_dealer_abridged_end: 0,
        bond_form_allocation_dealer_start: 0,
        bond_form_allocation_dealer_end: 0,
        bond_form_allocation_dealer_total: 0,

        bond_id: 0,
    }
    export const IPOFormAllocationList: APIData.IPOFormAllocationList = {
        bond_form_allocation_created_at: '',
        bond_form_allocation_id: 0,
        bond_form_allocation_status: 0,
        bond_form_allocation_form_start: 0,
        bond_form_allocation_form_end: 0,
        bond_form_allocation_form_total: 0,

        bond_form_allocation_online_single_page: 0,
        bond_form_allocation_online_single_start: 0,
        bond_form_allocation_online_single_end: 0,
        bond_form_allocation_online_two_page: 0,
        bond_form_allocation_online_two_start: 0,
        bond_form_allocation_online_two_end: 0,
        bond_form_allocation_online_abridged: 0,
        bond_form_allocation_online_abridged_start: 0,
        bond_form_allocation_online_abridged_end: 0,
        bond_form_allocation_online_start: 0,
        bond_form_allocation_online_end: 0,
        bond_form_allocation_online_total: 0,

        bond_form_allocation_dealer_single_page: 0,
        bond_form_allocation_dealer_single_start: 0,
        bond_form_allocation_dealer_single_end: 0,
        bond_form_allocation_dealer_two_page: 0,
        bond_form_allocation_dealer_two_start: 0,
        bond_form_allocation_dealer_two_end: 0,
        bond_form_allocation_dealer_abridged: 0,
        bond_form_allocation_dealer_abridged_start: 0,
        bond_form_allocation_dealer_abridged_end: 0,
        bond_form_allocation_dealer_start: 0,
        bond_form_allocation_dealer_end: 0,
        bond_form_allocation_dealer_total: 0,

        bond_id: 0,
        bond_name: ''
    }
    export const DealerIPOFormAllocation: APIData.DealerIPOFormAllocation = {
        dealer_form_allocation_id: 0,
        bond_id: 0,
        dealer_id: 0,
        dealer_name: '',
        dealer_unique_code: '',
        dealer_form_allocation_single_start: 0,
        dealer_form_allocation_single_end: 0,
        dealer_form_allocation_single_total: 0,

        dealer_form_allocation_two_page_start: 0,
        dealer_form_allocation_two_page_end: 0,
        dealer_form_allocation_two_page_total: 0,

        dealer_form_allocation_abridged_page_start: 0,
        dealer_form_allocation_abridged_page_end: 0,
        dealer_form_allocation_abridged_page_total: 0,
        dealer_form_allocation_total: 0
    }
    export const DistributorType: APIData.DistributorType = {
        distributor_type_id: 0,
        distributor_type_name: '',
        distributor_type_status: 0
    }
    export const DistributorTypeList: APIData.DistributorTypeList = {
        distributor_type_created_at: '',
        distributor_type_id: 0,
        distributor_type_name: '',
        distributor_type_status: 0,
        distributor_type_updated_at: ''
    }
    export const DistributorSubType: APIData.DistributorSubType = {
        distributor_sub_type_id: 0,
        distributor_sub_type_name: '',
        distributor_sub_type_status: 0,
        distributor_type_id: 0
    }
    export const ASBAList: APIData.ASBAList = {
        asba_bank_code: '',
        asba_bank_created_at: '',
        asba_bank_id: 0,
        asba_bank_name: '',
        asba_bank_status: 0,
        asba_bank_updated_at: '',
        asba_loc_full_name: '',
        asba_loc_short_name: ''
    }
    export const BrokerList: APIData.BrokerList = {
        broker_id: 0,
        broker_name: '',
        broker_nse_code: '',
        broker_bse_code: '',
        broker_mcx_code: '',
        broker_address_line_1: '',
        broker_address_line_2: '',
        broker_address_city: '',
        broker_exchange: '',
        broker_gst_no: '',
        broker_gst_city: '',
        broker_address_pincode: '',
        broker_phone_no: '',
        broker_mobile_no: '',
        broker_email: '',
        broker_fax_no: '',
        broker_pan_no: '',
        broker_st_reg_no: '',
        broker_st_reg_category: '',
        broker_st_service_category: '',
        broker_status: 0,
        broker_advisory: BrokerAdvisory,
    }
    export const DistributorSubTypeList: APIData.DistributorSubTypeList = {
        distributor_sub_type_created_at: '',
        distributor_sub_type_id: 0,
        distributor_sub_type_name: '',
        distributor_sub_type_status: 0,
        distributor_sub_type_updated_at: '',
        distributor_type_id: 0,
        distributor_type_name: ''
    }
    export const DistributList: APIData.DistributList = {
        distributor_id: null,
        distributor_name: null,
        distributor_status: null,
        distributor_unique_code: null,
        distributor_prospective: null,
        distributor_pan_number: null,
        distributor_arn_code: null,
        distributor_gst_number: null,
        distributor_state_id: null,
        distributor_type_id: null,
        distributor_gender: null,
        distributor_sub_type_id: null,
        distributor_category_id: null,
        distributor_net_worth: null,
        distributor_aum: null,
        distributor_location: null,
        distributor_website: null,
        distributor_existing_status: null,
        distributor_intro_by: null,
        distributor_zone: null,
        distributor_dob: null,
        distributor_empanelment_date: null,
        distributor_remarks: null,
        distributor_sharing_terms: null,
        state_name: null,
        distributor_address: [distributoraddress],
        distributor_bank: [distributorbank],
        distributor_contacts: [distributorcontacts],
        distributor_dealer: [distributordealer],
        distributor_demat: [distributordemat],
        distributor_reg: [distributorreg],
        distributor_sgl: [distributorsgl],
        distributor_companies: [],
        distributor_segment: [],
        distributor_emp: [],
        distributor_document: [distributordocument],
    }
 
  
    export const DistributorCategory: APIData.DistributorCategory = {
        distributor_category_id: 0,
        distributor_category_name: '',
        distributor_category_status: 0
    }
    export const DistributorCategoryList: APIData.DistributorCategoryList = {
        distributor_category_created_at: '',
        distributor_category_id: 0,
        distributor_category_name: '',
        distributor_category_status: 0,
        distributor_category_updated_at: ''
    }
    export const BrandTags: APIData.BrandTags = {
        brand_tag_id: 0,
        brand_tag_name: '',
        brand_tag_status: 0,
    }
    export const BrandTagsList: APIData.BrandTagsList = {
        brand_tag_created_at: '',
        brand_tag_id: 0,
        brand_tag_name: '',
        brand_tag_status: 0,
        brand_tag_updated_at: ''
    }
    export const EligibleTags: APIData.EligibleTags = {
        eligible_investor_id: 0,
        eligible_investor_name: '',
        eligible_investor_status: 0,
    }
    export const EligibleTagsList: APIData.EligibleTagsList = {
        eligible_investor_created_at: '',
        eligible_investor_id: 0,
        eligible_investor_name: '',
        eligible_investor_status: 0,
        eligible_investor_updated_at: ''
    }
    export const BrokerageCategory: APIData.BrokerageCategory = {
        bond_brokerage_category_id: 0,
        bond_brokerage_category_name: '',
        bond_brokerage_category_status: 0
    }
    export const BrokerageCategoryList: APIData.BrokerageCategoryList = {
        bond_brokerage_category_created_at: '',
        bond_brokerage_category_id: 0,
        bond_brokerage_category_name: '',
        bond_brokerage_category_status: 0,
        bond_brokerage_category_updated_at: ''
    }
    export const CustomerOrderList: APIData.CustomerOrderList = {
        bond_id: 0,
        bond_name: '',
        customer_name: '',
        order_amount: '',
        order_datetime: '',
        order_id: 0,
        order_order_id: 0,
        order_quantity: 0,
        order_status: 0,
        user_id: 0,
        order_type: 0,
        order_pdf: '',
        order_number: ''
    }
    export const OrderPayment: APIData.OrderPayment = {
        bond_id: 0,
        ipo_order_id: 0,
        ipo_order_payment_amount: '',
        ipo_order_payment_created_at: '',
        ipo_order_payment_datetime: '',
        ipo_order_payment_id: 0,
        ipo_order_payment_ref_bank_id: 0,
        ipo_order_payment_ref_city_id: 0,
        ipo_order_payment_status: 0,
        ipo_order_payment_updated_at: '',
        ipo_order_payment_upi: '',
        payment_mode_id: ''
    }
    export const BondOrderPayment: APIData.bondOrderPayment = {
        bond_id: 0,
        bond_order_id: 0,
        bond_order_payment_amount: '',
        bond_order_payment_created_at: '',
        bond_order_payment_datetime: '',
        bond_order_payment_id: 0,
        bond_order_payment_ref: 0,
        bond_order_payment_ref_bank_id: 0,
        bond_order_payment_status: 0,
        bond_order_payment_updated_at: '',
        // ipo_order_payment_upi: '',
        payment_mode_id: ''
    }
    export const BondOrderPayments: APIData.SgbOrderPayment = {
        sgb_order_payment_status: 0,
    }
    export const BankDetailsDatas: APIData.BankDetailsDatas = {
        account_name: '',
        account_number: '',
        account_type_1: 0,
        account_type_2: 0,
        bank_details_created_at: '',
        bank_details_id: 0,
        bank_details_updated_at: '',
        bank_name: '',
        cancel_cheque_image: '',
        ifsc_code: '',
        is_bank_account_verified: 0,
        is_primary: 0,
        status: 0,
        branch_name: '',
        branch_address: '',
        account_status: 0,
    }
    export const personalDetail: APIData.personalDetail = {
        address_proof_id: 0,
        aadhar_name: '',
        aadhar_number: '',
        address_city: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        address_state: '',
        address_state_code: '',
        address_zip: '',
        annual_income: 0,
        bank_name: '',
        check_box_share_data_with_company: 0,
        check_box_share_data_with_govt: 0,
        city_sequence_no: 0,
        country_code: '',
        customer_id: '',
        dob: '',
        education_degree: '',
        ekyc_application_status: null,
        email_id: '',
        family_account: '',
        father_or_husband_name: null,
        fname: '',
        gender: 0,
        geogriphical_code: '',
        address_type: 0,
        // id: '',
        ifsc_code: '',
        in_person_verification: 0,
        is_aadhar_verified: 0,
        is_email_verified: 0,
        is_mobile_verified: 0,
        is_pan_verified: 0,
        is_politically_exposed: 0,
        is_nominee_added: 0,
        lifestyle: '', 
        lname: '',
        married_status: 0,
        mental_disability: '',
        mname: '',
        mobile_number: '',
        mothers_maiden_name: '',
        occupation: 0,
        pan_name: '',
        pan_number: '',
        personal_details_created_at: '',
        personal_details_updated_at: '',
        profile_image: '',
        proof_back_image: '',
        proof_front_image: '',
        proof_type: '',
        signature: '',
        signature_file_flag: '',
        // suffix: null,
        title: '',
        trading_experience: 0,
        user_hashkey: '',
        user_id: '',
        users_created_at: '',
        users_created_by: '',
        users_updated_at: '',
        users_updated_by: '',
        verification_video: '',
        customer_type: '',
        customer_category: '',
        customer_sub_category: '',
        would_you_like_to_activate: 0,
        customer_group: '',
        account_type_1: '',
        in_person_video_verification_status: 0,
        gst: '',
        kra_status: 0,
        firstname: '',
        middlename: '',
        lastname: '',
        married_status_name: '',
    }
    export const IPOOrderStatus: APIData.IPOOrderStatus = {
        ipo_order_id: 0,
        ipo_order_status: 0,
        ipo_order_status_created_at: '',
        ipo_order_status_id: 0
    }
    export const BondOrderStatus: APIData.BondOrderStatus = {
        bond_order_id: 0,
        bond_order_status: 0,
        bond_order_status_created_at: '',
        bond_order_status_id: 0,
        bond_order_status_updated_at: ''
    }
    export const BondOrderStatu: APIData.SgbOrderStatus = {
        sgb_order_status: 0,
    }
    export const IPOOrderDetailView: APIData.IPOOrderDetailView = {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: 0,
        bond_isin_number: '',
        bond_serial_number_id: 0,
        customer_details: personalDetail,
        ipo_ncd_series: [],
        ipo_order_amount: '',
        ipo_order_application_no: '',
        ipo_order_created_at: '',
        ipo_order_current_payment_id: 0,
        ipo_order_current_status_id: 0,
        ipo_order_customer_id: 0,
        ipo_order_datetime: '',
        ipo_order_demant_account_id: 0,
        ipo_order_demant_account_type: 0,
        ipo_order_form_number: 0,
        ipo_order_id: 0,
        ipo_order_investor_category_id: 0,
        ipo_order_investor_type_id: 0,
        ipo_order_payments: [OrderPayment],
        ipo_order_pdf: '',
        ipo_order_quantity: 0,
        ipo_order_second_appl_name: '',
        ipo_order_second_pan: '',
        ipo_order_status: [IPOOrderStatus],
        ipo_order_sub_category_code: 0,
        ipo_order_third_appl_name: '',
        ipo_order_third_pan: '',
        ipo_order_total_amount: 0,
        ipo_order_updated_at: '',
        order_id: 0,
        order_type: 0,
        order_number: ''
    }
    export const BondOrderDetailView: APIData.BondOrderDetailView = {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: 0,
        bond_isin_number: '',
        bond_order_amount: '',
        bond_order_created_at: '',
        bond_order_current_payment_id: 0,
        bond_order_current_status_id: 0,
        bond_order_customer_id: 0,
        bond_order_datetime: '',
        bond_order_demant_account_id: 0,
        bond_order_demant_account_type: 0,
        bond_order_id: 0,
        bond_order_payments: [BondOrderPayment],
        bond_order_quantity: 0,
        bond_order_status: [BondOrderStatus],
        bond_order_updated_at: '',
        bse_rfq_id: 0,
        customer_details: personalDetail,
        order_id: 0,
        order_type: 0,
        order_number: '',
        bonds_yeild: '',
        bond_coupon_rate: 0,
        bond_order_payment_amount: 0,
    }
    export const BondOrderDetailViews: APIData.SgbOrderDetailView = {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        bond_id: 0,
        bond_isin_number: '',
        sgb_order_amount: '',
        sgb_order_created_at: '',
        bond_order_current_payment_id: 0,
        bond_order_current_status_id: 0,
        bond_order_customer_id: 0,
        sgb_order_datetime: '',
        bond_order_demant_account_id: 0,
        bond_order_demant_account_type: 0,
        bond_order_id: 0,
        sgb_order_payments: [BondOrderPayments],
        sgb_order_quantity: 0,
        sgb_order_status: [BondOrderStatu],
        bond_order_updated_at: '',
        bse_rfq_id: 0,
        customer_details: personalDetail,
        order_id: 0,
        order_type: 0
    }
    export const CustomerIPOOrderDetailView: APIData.CustomerIPOOrderDetailView = {
        bank_details: BankDetailsDatas,
        bond_detail: BondDetails,
        customer_details: personalDetail,
        ipo_order_payments: [OrderPayment],
        ipo_order_created_at: '',
        ipo_order_datetime: '',
        ipo_order_amount: '',
        ipo_order_quantity: 0,
        ipo_order_pdf: '',
        ipo_order_status: [IPOOrderStatus],
        order_id: 0,
        ipo_order_total_amount: 0,
        order_number: ''
    }
    export const CustomerBondOrderDetailView: APIData.CustomerBondOrderDetailView = {
        bond_detail: BondDetails,
        bond_id: 0,
        bond_isin_number: '',
        bond_order_amount: '',
        bond_order_created_at: '',
        bond_order_current_payment_id: 0,
        bond_order_current_status_id: 0,
        bond_order_customer_id: 0,
        bond_order_datetime: '',
        bond_order_demant_account_id: 0,
        bond_order_demant_account_type: 0,
        bond_order_id: 0,
        bond_order_payments: [OrderPayment],
        bond_order_quantity: 0,
        bond_order_status: [IPOOrderStatus],
        bond_order_updated_at: '',
        bse_rfq_id: 0,
        customer_details: personalDetail,
        order_id: 0,
        order_type: 0
    }

    export const CustomerEnquire: APIData.CustomerEnquire = {
        bond_id: 0,
        bond_isin_number: '',
        bond_logo: '',
        bond_name: '',
        buy_sell_id: 0,
        buy_sell_name: '',
        customer_bond_enquire_created_at: '',
        customer_bond_enquire_id: 0,
        customer_bond_enquire_number: 0,
        customer_bond_enquire_price: '',
        customer_bond_enquire_quantity: 0,
        customer_bond_enquire_status: 0,
        customer_bond_enquire_yield: '',
        customer_email_id: '',
        customer_id: '',
        customer_mobile_number: '',
        customer_name: '',
        settlement_date_id: 0,
        settlement_date_name: '',
        user_id: 0
    }
}