import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDataTypes } from '../common/DataTypes';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InitialData } from '../common/InitialValue';
import { frameSelectOptions } from '../common/Utilities';
import toast from 'react-hot-toast';
const IPOFormAllocation: React.FC = () => {
    const navigate = useNavigate();
    const [user_id, setUserId] = useState()
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.DealerIPOFormAllocation[]>([]);
    const formikRef = useRef<FormikProps<APIData.DealerIPOFormAllocation[]>>(null);
    const [bondOptions, setBondOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [bondFormAllocationID, setBondFormAllocationID] = useState<number>(0);
    const [formAllocationDetails, setFormAllocationDetails] = useState<APIData.IPOFormAllocationList>(InitialData.IPOFormAllocationList);
    const [dealerList, setdealerList] = useState<APIData.DealerList[]>([InitialData.Dealer]);
    const [filteredDealerList, setfilteredDealerList] = useState<APIData.DealerIPOFormAllocation[]>([]);

    useEffect(() => {
        loadIPOList();
        loadDealerList();
    }, [])

    const loadIPOList = () => {
        setShowPreloader(true);
        ServerAPI.getIPOFormList().then((res) => {
            try {
                let ipoOptions = frameSelectOptions(res.data, 'bond_form_allocation_id', 'bond_name');
                setBondOptions(ipoOptions);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const loadDealerList = () => {
        setShowPreloader(true);
        ServerAPI.getDealerList().then((res) => {
            try {
                console.log(res)
                // setdealerList(res.data)
                if (formikRef.current?.values) {
                    var updateArray: APIData.DealerIPOFormAllocation[] = [];
                    res.data.map((response: APIData.DealerList, inx: number) => {
                        updateArray.push({
                            bond_id: formAllocationDetails.bond_id,
                            dealer_id: response.dealer_id,
                            dealer_name: response.dealer_name,
                            dealer_unique_code: response.dealer_unique_code,
                            dealer_form_allocation_id: formAllocationDetails.bond_form_allocation_id,
                            dealer_form_allocation_single_start: 0,
                            dealer_form_allocation_single_end: 0,
                            dealer_form_allocation_single_total: 0,
                            dealer_form_allocation_two_page_start: 0,
                            dealer_form_allocation_two_page_end: 0,
                            dealer_form_allocation_two_page_total: 0,
                            dealer_form_allocation_abridged_page_start: 0,
                            dealer_form_allocation_abridged_page_end: 0,
                            dealer_form_allocation_abridged_page_total: 0,
                            dealer_form_allocation_total: 0
                        })
                    })
                    setfilteredDealerList([...updateArray])
                }
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    useEffect(() => {
        if (bondFormAllocationID) {
            loadIPODetails(bondFormAllocationID);
        }
    }, [bondFormAllocationID])

    const loadIPODetails = (id: number) => {
        setShowPreloader(true);
        ServerAPI.getIPODetail(id).then((res) => {
            try {
                console.log(res)
                setFormAllocationDetails(res);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const getDiffCount = (start: number, end: number) => {
        return Math.abs(start - end)
    }

    useEffect(() => {
        console.log(filteredDealerList);
    }, [filteredDealerList])


    const yupSchema = Yup.object().shape({
        // firstName: Yup.string().required('Product Name is required'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        console.log(data);
        setSubmitting(false);
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item">IPO Form Master</li>
                                                <li className="breadcrumb-item active ">Allocation dealer</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    console.log(values);
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    {/* <div className="card-header">
                                                                        <h5 className="m-0 header"> Allocation Dealer </h5>
                                                                    </div> */}
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={6}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>IPO Name</label>
                                                                                    <select id="bond_id" name="bond_id"
                                                                                        // value={formikProps.values.bond_id}
                                                                                        onChange={e => {
                                                                                            setFormikInitialData(value => ({ ...value, dealer_form_allocation_id: parseInt(e.target.value) }));
                                                                                            setBondFormAllocationID(parseInt(e.target.value))
                                                                                        }}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {bondOptions !== undefined && !bondOptions.some(bondOptions => bondOptions.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {bondOptions && bondOptions.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_start" className='bond-label'>Application Start No.</label>
                                                                                    <input id="bond_form_allocation_form_start" name="bond_form_allocation_form_start" type="number" className='bond-input-disabled' disabled
                                                                                        value={formAllocationDetails.bond_form_allocation_form_start}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_start: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_end" className='bond-label'>Application End No.</label>
                                                                                    <input id="bond_form_allocation_form_end" name="bond_form_allocation_form_end" type="number" className='bond-input-disabled' disabled
                                                                                        value={formAllocationDetails.bond_form_allocation_form_end}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_end: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_allocation_form_total" className='bond-label'>Total No. Of Application Forms</label>
                                                                                    <input id="bond_form_allocation_form_total" name="bond_form_allocation_form_total" type="number" className='bond-input-disabled' disabled
                                                                                        value={formAllocationDetails.bond_form_allocation_form_total}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_total: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Balance Application Forms (Online + Dealer)</label>
                                                                                    <input id="Bond Name" name="lastName" type="number" className='bond-input-disabled' disabled
                                                                                        value={formAllocationDetails.bond_form_allocation_form_total}
                                                                                    // onChange={e => setFormikInitialData(({ ...formikInitialData, bond_form_allocation_form_balance: parseInt(e.target.value) }))}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                                            <table id="tech-companies-1" className="table table table-bordered">
                                                                                <thead className="city-table">
                                                                                    <tr>
                                                                                        <th>Form Details</th>
                                                                                        <th>Total No. of Forms</th>
                                                                                        <th>App Start No</th>
                                                                                        <th>App End No</th>
                                                                                        <th>Allocated</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Single Page</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_single_page}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_single_start}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_single_end}</td>
                                                                                        <td>{(formAllocationDetails.bond_form_allocation_dealer_single_page) - (getDiffCount(formAllocationDetails.bond_form_allocation_dealer_single_start, formAllocationDetails.bond_form_allocation_dealer_single_end))}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Two Page</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_two_page}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_two_start}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_two_end}</td>
                                                                                        <td>{(formAllocationDetails.bond_form_allocation_dealer_two_page) - (getDiffCount(formAllocationDetails.bond_form_allocation_dealer_two_start, formAllocationDetails.bond_form_allocation_dealer_two_end))}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Abridged</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_abridged}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_abridged_start}</td>
                                                                                        <td>{formAllocationDetails.bond_form_allocation_dealer_abridged_end}</td>
                                                                                        <td>{(formAllocationDetails.bond_form_allocation_dealer_abridged) - (getDiffCount(formAllocationDetails.bond_form_allocation_dealer_abridged_start, formAllocationDetails.bond_form_allocation_dealer_abridged_end))}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                                    <form className="app-search card-title p-2 m-0">
                                                                        <div className="position-relative">
                                                                            <input type="text" className="select-style-search"
                                                                            //  onChange={(e) => setSearchText(e.target.value)} 
                                                                            />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="table-responsive " data-pattern="priority-columns">
                                                                        <table id="tech-companies-1" className="table table table-bordered">
                                                                            <thead className="city-table">
                                                                                <tr>
                                                                                    <th>Sr. No</th>
                                                                                    <th>Dealer Employee Code</th>
                                                                                    <th>Dealer Name</th>
                                                                                    <th>Single Page Form</th>
                                                                                    <th>Two Page Form</th>
                                                                                    <th>Abridged Forms</th>
                                                                                    <th>Total No. Of Forms</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    filteredDealerList != undefined && filteredDealerList.length > 0 ? filteredDealerList.map((dealer: APIData.DealerIPOFormAllocation, index: number) => {
                                                                                        return (
                                                                                            <tr>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{dealer.dealer_unique_code}</td>
                                                                                                <td>{dealer.dealer_name}</td>
                                                                                                <td>
                                                                                                    <input id="bond_upcoming_issues_name" name="bond_upcoming_issues_name" type="number" className='bond-input'
                                                                                                        value={dealer.dealer_form_allocation_single_total}
                                                                                                        onChange={e => { dealer.dealer_form_allocation_single_total = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input id="bond_upcoming_issues_name" name="bond_upcoming_issues_name" type="number" className='bond-input'
                                                                                                        value={dealer.dealer_form_allocation_two_page_total}
                                                                                                        onChange={e => { dealer.dealer_form_allocation_two_page_total = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input id="bond_upcoming_issues_name" name="bond_upcoming_issues_name" type="number" className='bond-input'
                                                                                                        value={dealer.dealer_form_allocation_abridged_page_total}
                                                                                                        onChange={e => { dealer.dealer_form_allocation_abridged_page_total = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td>{dealer.dealer_form_allocation_total = (dealer.dealer_form_allocation_single_total + dealer.dealer_form_allocation_two_page_total + dealer.dealer_form_allocation_two_page_total)}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    }) : ""
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)}>Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default IPOFormAllocation;

