import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import DataTable from "react-data-table-component";
import { Depository, DistributorCategoryStatus, DealerStatus, PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import { toast } from 'react-hot-toast';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import { getDisplayData, getFormattedLocalDateTime } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';

var startSerialNo = 0;
const BrokerageCategoryList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [brokerageCategoryList, setBrokerageCategoryList] = useState<APIData.BrokerageCategoryList[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currPage = currentPage ? currentPage : 1;
    startSerialNo = currPage * 10 - 10;

    let columns: any = [
        {
            name: 'Sr.No',
            cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
        },
        {
            name: "Created Date",
            selector: (row: any) => getFormattedLocalDateTime(row.bond_brokerage_category_created_at),
            sortable: true,
            format: (row: any) => moment(row.bond_brokerage_category_created_at).format('DD-MM-YYYY hh:mm A'),
        },
        {
            name: "Last Updated",
            selector: (row: any) => getFormattedLocalDateTime(row.bond_brokerage_category_updated_at),
            sortable: true,
            format: (row: any) => moment(row.bond_brokerage_category_updated_at).format('DD-MM-YYYY hh:mm A'),
        },
        {
            name: "Brokerage Category",
            selector: (row: any) => getDisplayData(row.bond_brokerage_category_name),
            sortable: true,
        },
        {
            name: "Action",
            cell: (row: any) => [
                <td className="city-action-rable-row  d-flex ">
                    <img src='../../assets/images/edit-icon.svg' style={{ width: '12%', margin: "0px 5px" }}
                        onClick={() => navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_ADD, { state: { mode: 'edit', bond_brokerage_category_id: row.bond_brokerage_category_id, bond_brokerage_category_name: row.bond_brokerage_category_name, bond_brokerage_category_status: row.bond_brokerage_category_status } })}
                    ></img>
                    <div className="form-check form-switch mx-2">
                        <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                            onChange={(e) => {
                                e.target.checked == true ? row.bond_brokerage_category_status = 1 : row.bond_brokerage_category_status = 0;
                                BrokerageList(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, row.bond_brokerage_category_id);
                                setBrokerageCategoryList([...brokerageCategoryList]);
                            }}
                            checked={row.bond_brokerage_category_status == DistributorCategoryStatus.Active ? true : false}
                        />
                    </div>
                </td>
            ]
        },
    ];
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
        setPageNumber(page);
    };
    useEffect(() => {
        brokerageCategorylist();
    }, [pageNumber, search_text, toData, fromDate])


    const brokerageCategorylist = () => {
        setShowPreloader(true);
        ServerAPI.BrokerageCategoryList(pageNumber, search_text, toData, fromDate).then((response) => {
            if (response['message'] != undefined) {
                setBrokerageCategoryList(response['message']);
                setTotalCount(response['message']?.length);
            }

        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const BrokerageList = (status: number, BrokerageID: number) => {
        setShowPreloader(true);
        ServerAPI.BrokerageList(status, BrokerageID).then((res) => {
            if (res['message'] != undefined) {
                toast.success(res['message']);
                console.log(res);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });
    const resetfilter = () => {
        setFromDate(null);
        setToData(null);
    }
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Masters </a></li>
                                                <li className="breadcrumb-item"><a> Bond Brokerage Category </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-7">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Created Date, Last Updated, B..." />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-5 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-50 text-center" style={{ fontSize: "0.78rem" }}>
                                                            <a className='text-white' onClick={() => navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_ADD, { state: { mode: 'add' } })}>
                                                                <div className="position-relative btn-suspend">
                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                    Add
                                                                </div>
                                                            </a>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                     placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={toData}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                     placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                        <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th>Last Updated</th>
                                                                <th >Brokerage Category</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            brokerageCategoryList != undefined && brokerageCategoryList.length > 0 ?
                                                                <>
                                                                    {
                                                                        brokerageCategoryList.map((res: APIData.BrokerageCategoryList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getFormattedLocalDateTime(res.bond_brokerage_category_created_at)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.bond_brokerage_category_updated_at)}</td>
                                                                                    <td rowSpan={2}>{getDisplayData(res.bond_brokerage_category_name)}</td>

                                                                                    <td className="city-action-rable-row  d-flex ">
                                                                                        <img src='../../assets/images/edit-icon.svg' style={{ width: '6%', margin: "0px 5px" }}
                                                                                         onClick={() => navigate(PageLinks.BOND_BROKERAGE_CATEGORIE_ADD, { state: { mode: 'edit' ,bond_brokerage_category_id: res.bond_brokerage_category_id ,bond_brokerage_category_name: res.bond_brokerage_category_name, bond_brokerage_category_status: res.bond_brokerage_category_status } })}
                                                                                        ></img>
                                                                                        <div className="form-check form-switch mx-2">
                                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                                                                                                onChange={(e) => {
                                                                                                    e.target.checked == true ? res.bond_brokerage_category_status = 1 : res.bond_brokerage_category_status = 0;
                                                                                                    BrokerageList(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, res.bond_brokerage_category_id);
                                                                                                    setBrokerageCategoryList([...brokerageCategoryList]);
                                                                                                }}
                                                                                                checked={res.bond_brokerage_category_status == DistributorCategoryStatus.Active ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table> */}
                                                    <DataTable
                                                        data={brokerageCategoryList}
                                                        columns={columns}
                                                        noHeader
                                                        defaultSortFieldId={"bond_brokerage_category_name"}
                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                        defaultSortAsc={true}
                                                        highlightOnHover
                                                        dense
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {brokerageCategoryList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value))
                                                            setCurrentPage(Number(value));
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default BrokerageCategoryList;

