import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, DistributorSubTypeStatus, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDataTypes } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getIsLogin } from '../common/Utilities';
import toast from 'react-hot-toast';
import { InitialData } from '../common/InitialValue';
import { frameSelectOptions } from '../common/Utilities';

const DistributorSubTypeAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.DistributorSubType>(InitialData.DistributorSubType);
    const formikRef = useRef<FormikProps<APIData.DistributorSubType>>(null);
    const [distributorTypeOptions, setDistributorTypeOptions] = useState<FormDataTypes.SelectOption[]>([]);

    useEffect(() => {
        getIsLogin().then(status => {
            if (!status) {
                navigate(PageLinks.LOGIN);
            }
        })
        loadDistributorTypeList();
    }, [])

    useEffect(() => {
        if (location.state.mode == 'edit') {
            setFormikInitialData(location.state);
        }
    }, [location])

    const loadIntitalDistributorSubType = (id: number) => {
        // setShowPreloader(true);
        // ServerAPI.getDealerDetail(id).then((res) => {
        //     setFormikInitialData(res);
        // }).finally(() => {
        //     setShowPreloader(false);
        // })
    }

    const loadDistributorTypeList = () => {
        setShowPreloader(true);
        ServerAPI.distributorTypeList().then((res) => {
            try {
                let DisTypeOptions = frameSelectOptions(res.distributor_types, 'distributor_type_id', 'distributor_type_name');
                setDistributorTypeOptions(DisTypeOptions);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const yupSchema = Yup.object().shape({
        distributor_sub_type_name: Yup.string().required('Distributor Sub Type Name is required'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        console.log(data);
        // if (location.state.mode == 'edit') {
        //     setShowPreloader(true);
        //     ServerAPI.putDistributorSubType(data).then((res) => {
        //         toast.success(res.message);
        //         navigate(PageLinks.DISTRIBUTOR_SUB_TYPE_LIST, { state: res.id });
        //         setSubmitting(false);
        //     }).finally(() => {
        //         setShowPreloader(false);
        //     })
        // } else {
            setShowPreloader(true);
            ServerAPI.postDistributorSubType(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.DISTRIBUTOR_SUB_TYPE_LIST, { state: res.id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        // }
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item active "> Distributor Sub Type </li>
                                                <li className="breadcrumb-item active "> Add Distributor Sub Type </li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Add Distributor Sub Type </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="distributorSubTypeChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="distributorSubTypeChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, distributor_sub_type_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.distributor_sub_type_status == DistributorSubTypeStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="distributor_type_name" className='bond-label'>Distributor Sub Type</label>
                                                                                    <input id="distributor_type_name" name="distributor_type_name" type="text" className='bond-input'
                                                                                        value={formikProps.values.distributor_sub_type_name}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_sub_type_name: e.target.value }))}
                                                                                    />
                                                                                    {formikProps.errors.distributor_sub_type_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.distributor_sub_type_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Distributor Type</label>
                                                                                    <select id="bond_id" name="bond_id"
                                                                                        value={formikProps.values.distributor_type_id}
                                                                                        onChange={e => setFormikInitialData(value => ({ ...value, distributor_type_id: parseInt(e.target.value) }))}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {distributorTypeOptions !== undefined && !distributorTypeOptions.some(distributorTypeOption => distributorTypeOption.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {distributorTypeOptions && distributorTypeOptions.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)}>Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}

                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default DistributorSubTypeAdd;

