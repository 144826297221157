import React, { } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PageLinks } from '../common/Constants';
// import { ServerAPI } from '../common/ServerAPI';
import ImageMagnifier from '../elements/ImageMagnifier';


const PanVerification: React.FC = () => {

    const location = useLocation();

    const customer_id = location.state;

    

    return (
        <div className='page-style'>
            <div className="step_box_content">
                <div className="col-xl-12">
                    <div className="row justify-content-center mx-5 my-5">
                        <div className="sub-bg">
                            <div className='col-lg-10 box-center'>
                                <div className=' row '>
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3">
                                            <h6 className="mb-0 px-3 py-2 wow fadeInUp2 animated" data-wow-delay="0.1s">IPV Verification</h6>
                                            <Link to={PageLinks.USERSDETAILS} state={customer_id} className="theme_btn profile_close_btn sub-btn wow fadeInUp2 animated" data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                            <hr></hr>
                                            <form className="quote-form mb-10" action="#">
                                                <div className="row">
                                                    {/* <h6 className="pt-20 mb--10 fadeInUp2 animated" data-wow-delay="0.1s">Hey {localStorage.getItem('customerFirstName')+" "+localStorage.getItem('customerLastName')}, Let’s Verify Your Email ID</h6> */}
                                                    <div className="col-xl-6 pl-0 pt-20 float-left">
                                                        <div className="email-input input_field pl-0">
                                                            <div className="my-3">
                                                                <label className="input-title pb-3">PAN</label>
                                                                <ImageMagnifier
                                                                    src={'/assets/images/PAN_Card.jpg'}
                                                                />
                                                                {/* <img src='/assets/images/PAN_Card.jpg' alt='PAN_CARD' className='pan-card-img' /> */}
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 pt-20 float-left">
                                                        <div className="email-input input_field pl-0">
                                                            <div className='col-lg-4 my-3'>
                                                                <label className="input-title pb-3">Verification Status</label>
                                                                <select className='select-style'>
                                                                    <option>Verified</option>
                                                                    <option>Reject</option>
                                                                </select>
                                                            </div>
                                                            <div className='my-3'>
                                                                <label className="input-title pb-3">Remarks</label>
                                                                <select className='select-style' placeholder='Select From drop down'>
                                                                    <option></option>
                                                                    <option></option>
                                                                </select>
                                                            </div>
                                                            <div className='row mx-3 my-3 py-5 px-5'>
                                                                <div  className='col-lg-6'>
                                                                    <a className='cancel-btn' >Cancel</a>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <a className='save-btn'>Save</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PanVerification;
