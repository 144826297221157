import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react"
import JSONPretty from 'react-json-pretty';

interface FormParameters {
    contents?: string,
    showModel: boolean,
    closeModel: any,
}

const PreviewJSONModal: React.FC<FormParameters> = ({ contents, showModel, closeModel }) => {

    return (
        <div>
            <Dialog
                open={showModel}
                className="dialog-box"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="model-title">
                    <div className="modal-box-title">
                        <p className="m-0 quicksand-medium">Preview JSON File</p>
                        <p className="m-0" onClick={closeModel}><i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i></p>
                    </div>
                </DialogTitle>
                <hr />
                <DialogContent>
                    <JSONPretty id="json-pretty" data={contents}></JSONPretty>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default PreviewJSONModal;