import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIData } from '../common/DataTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import DataTable from "react-data-table-component";
import { Depository, DealerStatus, DistributorTypeStatus, PageLinks } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import { getDisplayData, getFormattedLocalDateTime } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import { toast } from 'react-hot-toast';


var startSerialNo = 0;
const ASBABankLocationList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [ASBAlist, setASBAlist] = useState<APIData.ASBAList[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currPage = currentPage ? currentPage : 1;
    startSerialNo = currPage * 10 - 10;
    const [editingRowId, setEditingRowId] = useState(null);
    const [editvalue, setEditValue] = useState<any>()

    const handleEdit = (row: any) => {
        setEditValue({
            asba_bank_name: row?.asba_bank_name,
            asba_bank_status: row?.asba_bank_status,
            asba_bank_code: row?.asba_bank_code,
            asba_loc_short_name: row?.asba_loc_short_name,
            asba_loc_full_name: row?.asba_loc_full_name
        })
      setEditingRowId(row?.asba_bank_id);
    };
    const handleSave = () => {
        // setShowPreloader(true);
        ServerAPI.putASBAList(editingRowId,editvalue?.asba_bank_name, editvalue?.asba_bank_status,editvalue?.asba_bank_code, editvalue?.asba_loc_short_name, editvalue?.asba_loc_full_name ).then((res) => {
            toast.success(res.message);
            ASBA_list()
        }).finally(() => {
        setEditingRowId(null);

            // setShowPreloader(false);
        })
    };
    

    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    let columns: any = [
        {
            name: 'Sr.No',
            cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
        },
        {
            name: "Created Date",
            selector: (row: any) => getFormattedLocalDateTime(row.asba_bank_created_at),
            sortable: true,
            format: (row: any) => moment(row.asba_bank_created_at).format('DD-MM-YYYY hh:mm A'),

        },
        {
            name: "Last Updated",
            selector: (row: any) => getFormattedLocalDateTime(row.asba_bank_updated_at),
            sortable: true,
            format: (row: any) => moment(row.asba_bank_updated_at).format('DD-MM-YYYY hh:mm A'),
        },
        {
            name: "Bank Code",
            selector: (row: any) => getDisplayData(row.asba_bank_code),
            sortable: true,
            cell: (row: any) => editingRowId === row.asba_bank_id ?
                <input
                    type="text"
                    value={row.asba_bank_code}
                    onChange={e => handleNameChange(row, 'asba_bank_code', e.target.value)}
                    style={{ width: '100%' }}
                />: <span>{getDisplayData(row.asba_bank_code)}</span>
        },
        {
            name: "Bank Name",
            selector: (row: any) => getDisplayData(row.asba_bank_name),
            sortable: true,
            cell: (row: any) => editingRowId === row.asba_bank_id ?
                <input
                    type="text"
                    value={row.asba_bank_name}
                    onChange={e => handleNameChange(row, 'asba_bank_name', e.target.value)}
                    style={{ width: '100%' }}
                />: <span>{getDisplayData(row.asba_bank_name)}</span>
            
        },
        {
            name: "Location Short Name",
            selector: 'asba_loc_short_name',
            sortable: true,
            cell: (row: any) => editingRowId === row.asba_bank_id ?
                <input
                    type="text"
                    defaultValue={row.asba_loc_short_name}
                    onChange={e => handleNameChange(row, 'asba_loc_short_name', e.target.value)}
                    style={{ width: '100%' }}
                />: <span>{getDisplayData(row.asba_loc_short_name)}</span>
        },
        {
            name: "Location Full Name",
            selector: (row: any) => getDisplayData(row.asba_loc_full_name),
            sortable: true,
            cell: (row: any) => editingRowId === row.asba_bank_id ?
                <input
                    type="text"
                    defaultValue={row.asba_loc_full_name}
                    onChange={e => handleNameChange(row, 'asba_loc_full_name', e.target.value)}
                    style={{ width: '100%' }}
                />: <span>{getDisplayData(row.asba_loc_full_name)}</span>
        },
        {
            name: "Action",
            cell: (row: any) =>  
              <td className="city-action-rable-row d-flex">
                {editingRowId === row.asba_bank_id ? (
                <button onClick={() => handleSave()} className="edit-btn">  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 83.267 83.267">
                <path id="Path_27433" data-name="Path 27433" d="M-826.7,1049.441v49.833a8.921,8.921,0,0,1-8.921,8.923h-65.425a8.923,8.923,0,0,1-8.921-8.923v-65.423a8.922,8.922,0,0,1,8.921-8.921h49.835a8.9,8.9,0,0,1,6.308,2.612l15.59,15.593A8.889,8.889,0,0,1-826.7,1049.441Zm-24.443-11.316-.648-.648a2.228,2.228,0,0,0-1.578-.652h-42.474a2.231,2.231,0,0,0-2.23,2.23v19.33a2.231,2.231,0,0,0,2.23,2.23h43.121a2.23,2.23,0,0,0,2.23-2.23V1039.7A2.227,2.227,0,0,0-851.145,1038.125Zm-5.3,46.28a11.9,11.9,0,0,0-11.9-11.9,11.894,11.894,0,0,0-11.9,11.9,11.894,11.894,0,0,0,11.9,11.9A11.9,11.9,0,0,0-856.44,1084.405Z" transform="translate(909.969 -1024.93)"/>
              </svg></button>
                ) : (
                <button onClick={() => handleEdit(row)} className="edit-btn"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 83.267 83.267">
                <path id="Path_27431" data-name="Path 27431" d="M-1029.882,1033.83l18.052,18.052a1.956,1.956,0,0,1,0,2.767l-42.708,42.707-19.761,3.464a3.908,3.908,0,0,1-4.521-4.521l3.447-19.76,42.71-42.708A1.971,1.971,0,0,1-1029.882,1033.83Zm-34.692,44.92h-5.9l-1.838,10.491,5.058,5.058,10.49-1.838v-5.9h-7.806Zm9.092-5.871,25.047-25.046a2.271,2.271,0,0,0,0-3.221,2.269,2.269,0,0,0-3.221,0l-25.046,25.047a2.267,2.267,0,0,0,0,3.22A2.27,2.27,0,0,0-1055.482,1072.879Zm50.076-24.674a1.95,1.95,0,0,1-2.764,0l-18.053-18.052a1.955,1.955,0,0,1,0-2.766l7.5-7.5a7.823,7.823,0,0,1,11.042,0l9.775,9.775a7.794,7.794,0,0,1,0,11.042Z" transform="translate(1078.882 -1017.61)"/>
              </svg></button>
                )}
                <div className="form-check form-switch mx-2">
                <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                    onChange={(e) => {
                        e.target.checked == true ? row.asba_bank_status = 1 : row.asba_bank_status = 0;
                        setASBA(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, row.asba_bank_id);
                        setASBAlist([...ASBAlist]);
                    }}
                    checked={row.asba_bank_status == DistributorTypeStatus.Active ? true : false}
                />
              </div></td>
            // cell: (row: any) => [
            //     <td className="city-action-rable-row d-flex">
            //         <img src='../../assets/images/edit-icon.svg' style={{ width: '15%', margin: "0px 5px" }}
            //             onClick={handleButtonClick}
            //         ></img>
                   
            //         {/* <img src='../../assets/images/edit-icon.svg' style={{ width: '15%', margin: "0px 5px" }}
            //             onClick={() => navigate(PageLinks.ASBA_ADD, { state: { mode: 'edit', asba_bank_id: row.asba_bank_id, asba_bank_name: row.asba_bank_name, asba_bank_status: row.asba_bank_status, asba_bank_code: row.asba_bank_code, asba_loc_short_name: row.asba_loc_short_name, asba_loc_full_name: row.asba_loc_full_name } })}
            //         ></img> */}
            //         <div className="form-check form-switch mx-2">
            //             <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
            //                 onChange={(e) => {
            //                     e.target.checked == true ? row.asba_bank_status = 1 : row.asba_bank_status = 0;
            //                     setASBA(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, row.asba_bank_id);
            //                     setASBAlist([...ASBAlist]);
            //                 }}
            //                 checked={row.asba_bank_status == DistributorTypeStatus.Active ? true : false}
            //             />
            //         </div>
            //     </td>
            // ]
        },
    ];
    const handleButtonClick = () => {
        setDisabled(false)
    }
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
        setPageNumber(page);
    };
    const handleNameChange = (row: any, key: string, value: any) => {
        setEditValue({
            ...editvalue,
            [key]: value,
            asba_bank_status :row.asba_bank_status
        })
        // setData(updatedData);
    };
    useEffect(() => {
        ASBA_list();
    }, [pageNumber, search_text, toData, fromDate, preData, open])

    const setASBA = (status: number, ASBAID: number) => {
        setShowPreloader(true);
        ServerAPI.setASBA(status, ASBAID).then((res) => {
            if (res['message'] != undefined) {
                toast.success(res['message']);
                console.log(res);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const ASBA_list = () => {
        setShowPreloader(true);
        ServerAPI.ASBAlist(pageNumber, search_text, toData, fromDate).then((res) => {
            setASBAlist(res.data);
            setTotalCount(res.data.length);

        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
        // suspended_list();
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        ASBA_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });
    const resetfilter = () => {
        setFromDate(null);
        setToData(null);
       }
    return (
        // <div>
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> ASBA Bank Location </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-4">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Created Date, Last Updated, B..." />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-8 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <div className='d-grid'>
                                                            <input id="Bond Name" name="lastName" type="text" className='bond-input-upload-excel' placeholder='Upload Excel' />
                                                        </div>
                                                        <form className="app-search card-title p-2 m-0 w-100" style={{ fontSize: "0.78rem" }}>
                                                                <a className='text-white' onClick={() => navigate(PageLinks.ASBA_ADD, { state: { mode: 'add' } })}>
                                                            <div className="position-relative btn-suspend">
                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                    Add New
                                                            </div>
                                                                </a>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={fromDate}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                     placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                                <DatePicker
                                                                    selected={toData}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                        <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th>Last Updated</th>
                                                                <th>Bank Code</th>
                                                                <th>Bank Name</th>
                                                                <th>Location Short Name</th>
                                                                <th>Location Full Name</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            ASBAlist.length > 0 ?
                                                                <>
                                                                    {
                                                                        ASBAlist.map((res: APIData.ASBAList, i: any) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td> {startSerialNo + i + 1} </td>
                                                                                    <td>{res.asba_bank_created_at != null && moment(res.asba_bank_created_at).format("DD-MM-YYYY")}</td>
                                                                                    <td>{res.asba_bank_updated_at != null && moment(res.asba_bank_updated_at).format("hh:mm A")}</td>
                                                                                    <td>{res.asba_bank_code}</td>
                                                                                    <td>{res.asba_bank_name}</td>
                                                                                    <td>{res.asba_loc_short_name != null && res.asba_loc_short_name !== "null" ? <>{res.asba_loc_short_name}</> : "N/A"}</td>
                                                                                    <td>{res.asba_loc_full_name != null && res.asba_loc_full_name !== "null" ? <>{res.asba_loc_full_name}</> : "N/A"}</td>
                                                                                    {/* <td>{res.account_status_reason != null && res.account_status_reason !== "null" ? <>{res.account_status_reason}</> : "N/A"}</td>
                                                                                    <td>{res.account_status_remarks != null && res.account_status_remarks !== "null" ? <p className='m-0 text-content'>{res.account_status_remarks}</p> : "N/A"} {res.account_status_remarks.length > 80 && <span className='text-red cursor-pointer' onClick={(e) => view_more_content(res.account_status_remarks)} >(View more)</span>}</td> */}

                                                    {/* <td className="city-action-rable-row ">
                                                                                        <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.ASBA_ADD)}></i>

                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" className='pb-2 cursor-pointer' height="22" viewBox="0 0 73.281 58.625" onClick={() => activate_user(res.asba_bank_id)} >
                                                                                            <path id="Path_22594" data-name="Path 22594" d="M-1807.294,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.259-1.832A15.393,15.393,0,0,1-1807.294,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.893-9.893c-1.683,0-4.339,1.832-10.259,1.832-5.874,0-8.588-1.832-10.259-1.832a9.911,9.911,0,0,0-9.894,9.893v2.931h40.3Zm-36.641-33.709a16.494,16.494,0,0,1,16.489-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1849.431,870.975Zm5.5,0a11.01,11.01,0,0,0,10.993,10.992,11.01,11.01,0,0,0,10.992-10.992,11.01,11.01,0,0,0-10.992-10.993A11.011,11.011,0,0,0-1843.935,870.975Zm58.282,3.641-14.839,14.919a1.168,1.168,0,0,1-1.672,0l-8.45-8.507a1.2,1.2,0,0,1,0-1.683l2.2-2.2a1.169,1.169,0,0,1,1.672,0l5.428,5.462,11.8-11.874a1.169,1.169,0,0,1,1.672,0l2.187,2.2A1.2,1.2,0,0,1-1785.653,874.616Z" transform="translate(1858.591 -854.486)" />
                                                                                        </svg>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }

                                                    </table> */}
                                                    <DataTable
                                                        data={ASBAlist}
                                                        columns={columns}
                                                        noHeader
                                                        defaultSortFieldId={"eligible_investor_name"}
                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                        defaultSortAsc={true}
                                                        highlightOnHover
                                                        dense
                                                    />
                                                   
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {ASBAlist.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value));
                                                            setCurrentPage(Number(value));
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            {/* <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" /> */}

        </div>
    );
}

export default ASBABankLocationList;

