import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import DataTable from "react-data-table-component";

import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import { getDisplayData, getDisplaySelectOption, getDownloadFile, getDownloadURLExtension, getFormattedLocalDateTime, numberWithCommas } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";

const DistributorDetailView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const distributor_id = location.state;
    const [DistributID, setDistributID] = useState(0);

    const [DisDetails, setDisDetails] = useState<APIData.DistributList>(InitialData.DistributList);
    const { setShowPreloader } = useAppStateAPI();
    let columns: any = [
        {
            name: "Contact Person",
            selector: (row: any) => getDisplayData(row.distributor_contact_name),
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row: any) => getDisplayData(row.distributor_contact_designation),
            sortable: true,
        },
        {
            name: "Landline",
            selector: (row: any) => getDisplayData(row.distributor_contact_landline),
            sortable: true,
        },
        {
            name: "Mobile No",
            selector: (row: any) => getDisplayData(row.distributor_contact_mobile),
            sortable: true,
        },
        {
            name: "FAX No",
            selector: (row: any) => getDisplayData(row.distributor_contact_fax),
            sortable: true,
        },
        {
            name: "Email ID",
            selector: (row: any) => getDisplayData(row.distributor_contact_email),
            sortable: true,
        },
        {
            name: "Action",
            cell: (row: any) => [
                <td className="city-action-rable-row d-flex">
                    <i className="fas fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                </td>
            ]
        },
    ];

    useEffect(() => {
        loadBondDetail(location.state.distributor_id);
        // console.log(moment(documentProofDetails?.personal_details_updated_at).format("DD-MM-YYYY"));
    }, [distributor_id]);

    const loadBondDetail = (id: any) => {
        if (id) {
            setShowPreloader(true);
            ServerAPI.DistributListID(id)
                .then((res) => {
                    console.log('12', res);
                    setDisDetails(res.data);
                    setDistributID(res.data.distributor_id);

                })
                .finally(() => {
                    setShowPreloader(false);
                });
        }
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                                        <div className="page-title-rightds">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item" onClick={() => navigate(PageLinks.DISTRIBUTOR_LIST)}>Distributor List</li>
                                                <li className="breadcrumb-item active"><b>Detail View</b></li>
                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header col-xl-12 w-100">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="my-3 header">Distributor Details </h5>
                                                        <div className="">
                                                            <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.DISTRIBUTOR_ADD, { state: { Did: DistributID, mode: 'edit' } })} ><img src="../../assets/images/edit.svg" style={{ marginBottom: "4px", width: "16px" }} /> Edit</a>
                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Distributor Name</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Unique Code</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_unique_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">In Prospective</p>
                                                                    <h6>{getDisplayData(DisDetails?.distributor_prospective)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">PAN Number</p>
                                                                    <h6>{getDisplayData(DisDetails?.distributor_pan_number)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ARN Code</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_arn_code)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST Number</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_gst_number)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">State name (Code)</p>
                                                                    <h6>{getDisplayData(DisDetails.state_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Gendar</p>
                                                                    <h6>{getDisplaySelectOption(DisDetails.distributor_gender,'distributor_gender')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Distributor Type</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_type_id)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Distributor Sub Type</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_sub_type_id)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Distributor Category</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_category_id)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">AUM</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_aum)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Location</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_location)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Website (if any)</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_website)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Existing Status</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_existing_status)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Introduce By</p>
                                                                    <h6>{getDisplayData(DisDetails?.distributor_intro_by)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Distributor Zone</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_zone)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Date of Birth / Incorporation</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_dob)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Interest Calculate by</p>
                                                                    <h6>n/a</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Empanelment Date</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_empanelment_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Remark</p>
                                                                    <h6>{getDisplayData(DisDetails.distributor_remarks!)}</h6>
                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Segments (in which he operates)</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-8">
                                                                    {
                                                                        DisDetails.distributor_segment != undefined && DisDetails.distributor_segment.length !== 0 ?
                                                                            DisDetails.distributor_segment.map((segment: APIData.distributorsegment, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${segment.segment_id != null ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(segment.segment_name)}</div>
                                                                                )
                                                                            })
                                                                            : <h5>No Data Found</h5>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Companies</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-8">
                                                                    {
                                                                        DisDetails.distributor_companies != undefined && DisDetails.distributor_companies.length !== 0 ?
                                                                            DisDetails.distributor_companies.map((companies: APIData.distributorcompanies, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${companies.companies_id != null ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(companies.companies_name)}</div>
                                                                                )
                                                                            })
                                                                            : <h5>No Data Found</h5>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Empanelment with Trust</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-8">
                                                                    {
                                                                        DisDetails.distributor_emp != undefined && DisDetails.distributor_emp.length !== 0 ?
                                                                            DisDetails.distributor_emp.map((emp: APIData.distributor_emp, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${emp.empanelment_id != null ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(emp.empanelment_name)}</div>
                                                                                )
                                                                            })
                                                                            : <h5>No Data Found</h5>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Bank Account Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {
                                                                    DisDetails.distributor_bank != undefined && DisDetails.distributor_bank.length !== 0 ?
                                                                        DisDetails.distributor_bank.map((bank: APIData.distributor_bank, index: number) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Bank Name</p>
                                                                                        <h6>{getDisplayData(bank.distributor_bank_account_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Branch Name</p>
                                                                                        <h6>{getDisplayData(bank.distributor_bank_account_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Account Number</p>
                                                                                        <h6>{getDisplayData(bank.distributor_bank_account_number)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">IFSC Code</p>
                                                                                        <h6>{getDisplayData(bank.distributor_bank_account_ifsc)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Address</p>
                                                                                        <h6>{getDisplayData(bank.distributor_bank_account_name)}</h6>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        : <h5>No Data Found</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">SGL Account Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {
                                                                    DisDetails.distributor_sgl != undefined && DisDetails.distributor_sgl.length !== 0 ?
                                                                        DisDetails.distributor_sgl.map((sgl: APIData.distributor_sgl, index: number) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Bank Name</p>
                                                                                        <h6>{getDisplayData(sgl.distributor_sgl_account_bank_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">SGL Name</p>
                                                                                        <h6>{getDisplayData(sgl.distributor_sgl_account_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">SGL Account Number</p>
                                                                                        <h6>{getDisplayData(sgl.distributor_sgl_account_number)}</h6>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        : <h5>No Data Found</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Demat Account Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {
                                                                    DisDetails.distributor_demat != undefined && DisDetails.distributor_demat.length !== 0 ?
                                                                        DisDetails.distributor_demat.map((demat: APIData.distributor_demat, index: number) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Depository</p>
                                                                                        <h6>{getDisplayData(demat.distributor_demat_account_depository)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">DP Name</p>
                                                                                        <h6>{getDisplayData(demat.distributor_demat_account_dp_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">DP ID</p>
                                                                                        <h6>{getDisplayData(demat.distributor_demat_account_dp_id)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Client ID</p>
                                                                                        <h6>{getDisplayData(demat.distributor_demat_account_client_id)}</h6>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        : <h5>No Data Found</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Registration Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                {
                                                                    DisDetails.distributor_reg != undefined && DisDetails.distributor_reg.length !== 0 ?
                                                                        DisDetails.distributor_reg.map((reg: APIData.distributor_reg, index: number) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Exchange Name</p>
                                                                                        <h6>{getDisplayData(reg.distributor_registration_exchange_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">AP Reg Name</p>
                                                                                        <h6>{getDisplayData(reg.distributor_registration_ap_reg_name)}</h6>
                                                                                    </div>
                                                                                    <div className="col dist_col">
                                                                                        <p className="detail-title">Approval Date</p>
                                                                                        <h6>{getDisplayData(reg.distributor_registration_approval_date)}</h6>
                                                                                    </div>
                                                                                </div>)
                                                                        })
                                                                        : <h5>No Data Found</h5>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <Tabs forceRenderTabPanel>
                                                                        <div className="card-body">
                                                                            <TabList className="Product_detail_tablist">
                                                                                <Tab className="product_detail_tab">Contact Details</Tab>
                                                                                <Tab className="product_detail_tab">Address Details</Tab>
                                                                                <Tab className="product_detail_tab">Dealer Details</Tab>
                                                                                <Tab className="product_detail_tab">Document Details</Tab>
                                                                                <Tab className="product_detail_tab">Sharing Terms</Tab>
                                                                            </TabList>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <DataTable
                                                                                        data={DisDetails.distributor_contacts}
                                                                                        columns={columns}
                                                                                        noHeader
                                                                                        defaultSortFieldId={"broker_id"}
                                                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                                                        defaultSortAsc={true}
                                                                                        highlightOnHover
                                                                                        dense
                                                                                    />

                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Address Type</th>
                                                                                                <th>Address 1</th>
                                                                                                <th>Address 2</th>
                                                                                                <th>Address 3</th>
                                                                                                <th>City</th>
                                                                                                <th>State</th>
                                                                                                <th>Pin Code</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td></td>
                                                                                                <td>Kolkata</td>
                                                                                                <td>West Bengal</td>
                                                                                                <td>700015</td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fas fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Dealer Type</th>
                                                                                                <th>Dealer Name</th>
                                                                                                <th>From Date</th>
                                                                                                <th>To Date</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>Primary + Secondary </td>
                                                                                                <td>Dinendra Arya</td>
                                                                                                <td>13 / 09 / 2022</td>
                                                                                                <td>13 / 09 / 2022</td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fa fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Document Type</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Upload File</th>
                                                                                                <th>Download</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>
                                                                                                    <div className='d-grid'>
                                                                                                        <label htmlFor="Nature of Instrument" className='bond-label'>Address Proof</label>
                                                                                                        <select id="Nature of Instrument" name="email"
                                                                                                            // onChange={formikProps.handleChange}
                                                                                                            // value={formikProps.values.email}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value="">Select</option>
                                                                                                            <option value="Food">Food</option>
                                                                                                            <option value="Entertainment">Entertainment</option>
                                                                                                            <option value="Academic">Academic</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td colSpan={3} data-priority="3" className="">
                                                                                                    <div className='d-grid'>
                                                                                                        <label htmlFor="Bond Name" className='bond-label'>Upload Bond Logo</label>
                                                                                                        <input id="Bond Name" name="lastName" type="text" className='bond-input-upload-file'
                                                                                                        // onChange={formikProps.handleChange} value={formikProps.values.lastName}
                                                                                                        />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td><img src='../../assets/images/download.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} /></td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fa fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel><h1>hello</h1></TabPanel>
                                                                        </div>
                                                                    </Tabs>
                                                                </div>
                                                            </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
            {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}

        </div >
    );
}

export default DistributorDetailView;