import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { DealerStatus, Depository, PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { InitialData } from '../common/InitialValue';
import { ServerAPI } from '../common/ServerAPI';
import { getDealerStatus, getDisplayData, getFormattedLocalDateTime, getGender, getIsLogin } from '../common/Utilities';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';

var startSerialNo = 0;
const DealerList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value);
    const { setShowPreloader } = useAppStateAPI();
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [bondSelectOption, setBondSelectOption] = useState("all");
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const [dealerList, setdealerList] = useState<APIData.DealerList[]>([InitialData.Dealer]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currPage = currentPage ? currentPage : 1;
    startSerialNo = currPage * 10 - 10;

    let columns: any = [
        {
            name: 'Sr.No',
            cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
        },

        {
            name: "Name",
            selector: (row: any) => getDisplayData(row.dealer_name),
            sortable: true,
        },
        {
            name: "Joining. Date",
            selector: (row: any) => getFormattedLocalDateTime(row.dealer_created_at),
            sortable: true,
            format: (row: any) => moment(row.dealer_created_at).format('DD-MM-YYYY hh:mm A'),

        },
        {
            name: "Joining. Time",
            selector: (row: any) => getFormattedLocalDateTime(row.dealer_updated_at),
            sortable: true,
            format: (row: any) => moment(row.dealer_updated_at).format('DD-MM-YYYY hh:mm A'),

        },


        {
            name: "User Type",
            selector: "",
            sortable: true,
        },
        {
            name: "Employee Code",
            selector: (row: any) => getDisplayData(row.dealer_employee_code),
            sortable: true,
        },
        {
            name: "Gender",
            selector: (row: any) => getGender(row.dealer_gender),
            sortable: true,
        }, {
            name: "Manage By",
            selector: (row: any) => getDisplayData(row.dealer_managed_by),
            sortable: true,
        }, {
            name: "Branch Name",
            selector: (row: any) => getDisplayData(row.dealer_branch_name),
            sortable: true,
        }, {
            name: "Mobile Number",
            selector: (row: any) => getDisplayData(row.dealer_mobile),
            sortable: true,
        }, {
            name: "Status",
            selector: (row: any) => getDealerStatus(row.dealer_status),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row: any) => row.bond_listing_status,
            cell: (row: any) => [
                <td className="city-action-rable-row d-flex align-items-center">
                    <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.DEALER_Detail, { state: row.dealer_id })}></i>
                    <div className="form-check form-switch mx-2">
                        <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                            onChange={(e) => {
                                e.target.checked == true ? row.dealer_status = 1 : row.dealer_status = 0;
                                setDealerStatus(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, row.dealer_id);
                                setdealerList([...dealerList]);
                            }}
                            checked={row.dealer_status == DealerStatus.Active ? true : false}
                        />
                    </div>
                </td>
            ]
        },
    ];
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
        setPageNumber(page);
    };
    useEffect(() => {
        getIsLogin().then(status => {
            if (!status) {
                navigate(PageLinks.LOGIN);
            }
        })
    }, [])

    useEffect(() => {
        loadDealerList();
    }, [pageNumber, search_text, toData, fromDate, preData, open, bondSelectOption])

    const loadDealerList = () => {
        setShowPreloader(true);
        ServerAPI.DealerList(pageNumber, search_text, toData, fromDate, bondSelectOption).then((res) => {
            setdealerList(res.data);
            setTotalCount(res.data.length);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const setDealerStatus = (status: number, dealerID: number) => {
        setShowPreloader(true);
        ServerAPI.setDealerStatus(status, dealerID).then((res) => {
            toast.success(res.message);
            console.log(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
        // suspended_list();
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        loadDealerList();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });
    const resetfilter = () => {
        setFromDate(null);
        setToData(null);
       }
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item"><a> Dealer List </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-4">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Name, Joining. Date, Joining..."/>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-8 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative ">
                                                                <select className='select-style' name='user_status' onChange={(e) => setBondSelectOption(e.target.value)} >
                                                                    <option value="all">All</option>
                                                                    <option value="Primary">Primary</option>
                                                                    <option value="Secondary">Secondary</option>
                                                                </select>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100" style={{ fontSize: "0.78rem" }}>
                                                            <div className="position-relative btn-suspend" onClick={() => navigate(PageLinks.DEALER_ADD, { state: { mode: 'add' } })}>
                                                                <a className='text-white'>
                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                    Add New
                                                                </a>
                                                            </div>
                                                        </form>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                            <DatePicker
                                                                    selected={fromDate}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                   placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                            <DatePicker
                                                                    selected={toData}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                     placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                        <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Name</th>
                                                                <th>Joining. Date</th>
                                                                <th>Joining. Time</th>
                                                                <th>User Type</th>
                                                                <th>Employee Code</th>
                                                                <th>Gender</th>
                                                                <th>Manage By</th>
                                                                <th>Branch Name</th>
                                                                <th>Mobile Number</th>
                                                                <th>Status</th>
                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            dealerList.length > 0 ?
                                                                <>
                                                                    {
                                                                        dealerList.map((res: APIData.DealerList, i: any) => (
                                                                            <tbody key={i}>
                                                                                <tr style={{ color: `${res.dealer_status == DealerStatus.Disabled ? '#FF0000' : ''}` }}>
                                                                                    <td> {i + 1} </td>
                                                                                    <td>{getDisplayData(res.dealer_name)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.dealer_created_at)}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.dealer_created_at)}</td>
                                                                                    <td></td>
                                                                                    <td>{getDisplayData(res.dealer_employee_code)}</td>
                                                                                    <td>{getGender(res.dealer_gender)}</td>
                                                                                    <td>{getDisplayData(res.dealer_managed_by)}</td>
                                                                                    <td>{getDisplayData(res.dealer_branch_name)}</td>
                                                                                    <td>{getDisplayData(res.dealer_mobile)}</td>
                                                                                    <td>{getDealerStatus(res.dealer_status)}</td>

                                                                                    <td className="city-action-rable-row d-flex align-items-center">
                                                                                        <i className="fas fa-eye table-icons pe-1" onClick={() => navigate(PageLinks.DEALER_Detail, { state: res.dealer_id })}></i>
                                                                                        <div className="form-check form-switch mx-2">
                                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatus"
                                                                                                onChange={(e) => {
                                                                                                    e.target.checked == true ? res.dealer_status = 1 : res.dealer_status = 0;
                                                                                                    setDealerStatus(e.target.checked == true ? DealerStatus.Active : DealerStatus.Disabled, res.dealer_id);
                                                                                                    setdealerList([...dealerList]);
                                                                                                }}
                                                                                                checked={res.dealer_status == DealerStatus.Active ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table> */}
                                                    <DataTable
                                                        data={dealerList}
                                                        columns={columns}
                                                        noHeader
                                                        defaultSortFieldId={"dealer_name"}
                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                        defaultSortAsc={true}
                                                        highlightOnHover
                                                        dense
                                                    />
                                                    
                                                </div>

                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {dealerList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value))
                                                            setCurrentPage(Number(value));
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>


                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />
        </div>
    );
}

export default DealerList;

