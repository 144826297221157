import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import DataTable from "react-data-table-component";
import { getDisplayData, getFormattedLocalDateTime } from '../common/Utilities';

var startSerialNo = 0;
const BondSubcribeList: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [bondSubList, setBondSubList] = useState<APIData.BondSubcribeList[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(totalCount / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currPage = currentPage ? currentPage : 1;
    startSerialNo = currPage * 10 - 10;
    let columns: any = [
        {
            name: 'Sr.No',
            cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
        },
        {
            name: "Created Date",
            selector: (row: any) => getFormattedLocalDateTime(row.bond_subcriber_created_at),
            sortable: true,
            

        },
        {
            name: "Bond Subcribe Email",
            selector: (row: any) => getDisplayData(row.bond_subcriber_email),
            sortable: true,
        },
    ];
    const handlePageChange = (page: any) => {
        setCurrentPage(page);
        setPageNumber(page);
    };
    useEffect(() => {
        bondSubscribelist();
    }, [pageNumber, search_text, toData, fromDate])


    const bondSubscribelist = () => {
        setShowPreloader(true);
        ServerAPI.BondSubcribeList(pageNumber, search_text, toData, fromDate).then((res) => {
            setBondSubList(res.data);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });
    const resetfilter = () => {
        setFromDate(null);
        setToData(null);
       }
    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Masters </a></li>
                                                <li className="breadcrumb-item"><a> Bond Subcribe List </a></li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <div className="row" style={{ paddingBottom: "10px" }}>
                                                <div className="col-xl-7">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                                        <form className="app-search card-title p-2 m-0">
                                                            <div className="position-relative">
                                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)}  placeholder="Created Date, Bond Subcribe..."/>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="col-xl-5 text-end">
                                                    <div className="d-flex justify-content-end align-items-center">
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                            <DatePicker
                                                                    selected={fromDate}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setFromDate(date)}
                                                                    placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />

                                                            </div>
                                                        </form>
                                                        <h4 className="card-title p-2 m-0">TO</h4>
                                                        <form className="app-search card-title p-2 m-0 w-100">
                                                            <div className="position-relative">
                                                            <DatePicker
                                                                    selected={toData}
                                                                    closeOnScroll={true}
                                                                    onChange={(date: Date) => setToData(date)}
                                                                    placeholderText="DD/MM/YYYY"
                                                                    className='date-picker-style'
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    customInput={(<CustomInput />)}
                                                                />
                                                            </div>
                                                        </form>
                                                        <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-rep-plugin">
                                                <div className="table-responsive " data-pattern="priority-columns">
                                                    {/* <table id="tech-companies-1" className="table table table-bordered">
                                                        <thead className="city-table">
                                                            <tr>
                                                                <th>Sr.No</th>
                                                                <th>Created Date</th>
                                                                <th colSpan={5} data-priority="3" className="">Bond Subcribe Email</th>
                                                            </tr>
                                                        </thead>
                                                        {
                                                            bondSubList != undefined && bondSubList.length > 0 ?
                                                                <>
                                                                    {
                                                                        bondSubList.map((res: APIData.BondSubcribeList, i: number) => (
                                                                            <tbody key={i}>
                                                                                <tr >
                                                                                    <td>{startSerialNo + i + 1}</td>
                                                                                    <td>{getFormattedLocalDateTime(res.bond_subcriber_created_at)}</td>
                                                                                    <td colSpan={5}>{getDisplayData(res.bond_subcriber_email)}</td>
                                                                                </tr>

                                                                            </tbody>

                                                                        ))
                                                                    }
                                                                </>
                                                                : <tbody>
                                                                    <tr className='py-4'>
                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                        }
                                                    </table> */}
                                                    <DataTable
                                                        data={bondSubList}
                                                        columns={columns}
                                                        noHeader
                                                        defaultSortFieldId={"eligible_investor_name"}
                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                        defaultSortAsc={true}
                                                        highlightOnHover
                                                        dense
                                                    />
                                                   
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-sm-12 col-md-5">
                                                    {totalCount > 10 ?
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {bondSubList.length} of {totalCount} entries</div> :
                                                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                                        <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                                            setPageNumber(Number(value));
                                                            setCurrentPage(Number(value));
                                                        }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default BondSubcribeList;

