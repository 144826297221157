import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { BondType, BondTypes, OrderType, Depository, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ServerAPI } from "../common/ServerAPI";
import { getDealerStatus, getDisplayData,getPaymentStatus, getDownloadFile, getFormattedLocalDateTime, getOrderStatus, getOrderTypeFilter, numberWithCommas } from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";

var startSerialNo = 0;
const CustomerOrderList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState(0);

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [orderList, setOrderList] = useState<APIData.CustomerOrderList[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const [tabIndex, setTabIndex] = useState(0);
  const searchref = React.createRef();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;

  let columns: any = [
    {
      name: 'Sr.No',
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },

    {
      name: "Order No",
      selector: (row: any) => getDisplayData(row.order_number),
      sortable: true,
    },
    {
      name: "Order Date & Time",
      selector: (row: any) => row.order_datetime,
      sortable: true,
      format: (row: any) => moment(row.order_datetime).format('DD-MM-YYYY,  hh:mm A'),
    },
    {
      name: "Customer Name",
      selector: (row: any) => getDisplayData(row.customer_name),
      sortable: true,
    },

    {
      name: "Bond / IPO Name",
      selector: (row: any) => getDisplayData(row.bond_name),
      sortable: true,
    },
    {
      name: "ISIN Number",
      selector: (row: any) => getDisplayData(row.bond_isin_number),
      sortable: true,
    },
    {
      name: "Coupon Rate (%)",
      selector: (row: any) => getDisplayData(row.bond_coupon_rate),
      sortable: true,
    },
    {
      name: "Maturity Date",
      selector: (row: any) => row.bond_maturity_date,
      sortable: true,
      format: (row: any) => moment(row.bond_maturity_date).format('DD-MM-YYYY,  hh:mm A'),
    },
    {
      name: "Price",
      selector: (row: any) => numberWithCommas(row.order_amount),
      sortable: true,
    },
    {
      name: "Exchange",
      selector: (row: any) => getDisplayData(row.bond_exchange == 1 && 'BSE' || row.bond_exchange == 2 && 'NSE' || row.bond_exchange == 3 && 'BSE & NSE'),
      sortable: true,
    },
    {
      name: "No. Of Quantity",
      selector: (row: any) => getDisplayData(row.order_quantity),
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row: any) => numberWithCommas(row.order_amount),
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row: any) => getPaymentStatus(row.order_payment_status),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row: any) => getOrderStatus(row.order_status),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-eye table-icons pe-1"
            // onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
            //     state: { type: res.order_type, id: res.order_id }
            // })}
            onClick={() => {
              if (row.order_type == BondTypes.IPO) {
                navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "customer_book" },
                });
              } else if (row.order_type == BondTypes.ADminBond) {
                navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "customer_book" },
                });
              } else if (row.order_type == BondTypes.SGB) {
                navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "customer_book" },
                });
              }
            }}
          ></i>
          {row.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(row.order_pdf)}></img> : ""}
        </td>
      ]
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  useEffect(() => {
    loadOrderList();
  }, [pageNumber, search_text, toData, fromDate, preData, open, bondSelectOption]);

  const loadOrderList = () => {
   
    setShowPreloader(true);
    ServerAPI.getCustomerOrderList(pageNumber, search_text, toData, fromDate, bondSelectOption)
      .then((res) => {
        try {
          setOrderList(res.data);
          console.log("🚀 ~ file: CustomerOrderList.tsx:171 ~ .then ~ res:", res)
          setTotalCount(res.total_records);
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
 const resetfilter = () => {
  setFromDate(null);
  setToData(null);
 }
  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a> Order Book </a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs selectedIndex={tabIndex} onSelect={(index) =>{ setTabIndex(index); setSearchText(''); setBondSelectOption(index);} }>
                <TabList className="Product_detail_tablist" style={{ marginBottom: "0rem" }}>
                  <div className=" d-flex justify-content-between align-items-end">
                    <div>
                      <Tab className="product_detail_tab">IPO</Tab>
                      <Tab className="product_detail_tab">SGB</Tab>
                      <Tab className="product_detail_tab">BOND</Tab>
                    </div>
                    {/* <div>
                      <form className="app-search card-title p-2 m-0 w-100">
                        <div className="position-relative btn-suspend">
                          <a className="text-white" onClick={() => navigate(PageLinks.USERS, { state: true })}>
                            <i className="fa fa-user-circle mx-2" aria-hidden="true"></i>
                            Customer Enquiries
                          </a>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Order Id, Order Date & Time..." />
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setFromDate(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                 
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                       placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>
                                 <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {orderList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Order Id, Order Date & Time..."/>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setFromDate(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                       placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>

                                <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {orderList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          {/* <div className="card-header col-xl-12 w-100"> */}
                          <div className="row" style={{ paddingBottom: "10px" }}>
                            <div className="col-xl-4">
                              <div className="d-flex justify-content-start align-items-center">
                                <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                <form className="app-search card-title p-2 m-0">
                                  <div className="position-relative">
                                    <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Order Id, Order Date & Time..."/>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="col-xl-8 text-end">
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative ">
                                    <select className="select-style" name="bond_type" onChange={(e) => setBondSelectOption(e.target.value)}>
                                      <option value="all">All</option>
                                      <option value="IPO">IPO</option>
                                      <option value="BOND">Bond</option>
                                      <option value="SGB">SGB</option>
                                    </select>
                                  </div>
                                </form> */}
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={fromDate}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setFromDate(date)}
                                      placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>
                                <h4 className="card-title p-2 m-0">TO</h4>
                                <form className="app-search card-title p-2 m-0 w-100">
                                  <div className="position-relative">
                                    <DatePicker
                                      selected={toData}
                                      closeOnScroll={true}
                                      onChange={(date: Date) => setToData(date)}
                                       placeholderText="DD/MM/YYYY"
                                      className='date-picker-style'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      customInput={(<CustomInput />)}
                                    />
                                  </div>
                                </form>

                                <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                              </div>
                            </div>
                          </div>

                          <div className="table-rep-plugin">
                            <div className="table-responsive " data-pattern="priority-columns">
                              {/* <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Order ID</th>
                                <th>Order Date & Time</th>
                                <th>Order Type</th>
                                <th>Customer Name</th>
                                <th>Bond / IPO Name</th>
                                <th>No. Of Quantity</th>
                                <th>Total Amount</th>
                                <th>Order Status</th>
                                <th colSpan={3} data-priority="3" className="">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {orderList != undefined && orderList.length > 0 ? (
                              <>
                                {orderList.map((res: APIData.CustomerOrderList, i: number) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{getDisplayData(res.order_number)}</td> */}
                              {/* <td>{getFormattedLocalDateTime(res.order_datetime)}</td> */}
                              {/* <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{getOrderTypeFilter(res.order_type)}</td>
                                      <td>{getDisplayData(res.customer_name)}</td>
                                      <td>{getDisplayData(res.bond_name)}</td>
                                      <td>{getDisplayData(res.order_quantity)}</td>
                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                      <td>{getOrderStatus(res.order_status)}</td>
                                      <td className="city-action-rable-row "> */}
                              {/* <i
                                          className="fas fa-eye table-icons pe-1"
                                          // comment this section
                                          onClick={() => navigate(PageLinks.CUSTOMER_BOOK_DETAIL_VIEW, {
                                              state: { type: res.order_type, id: res.order_id }
                                          })}
                                          onClick={() => {
                                            if (res.order_type == BondTypes.IPO) {
                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.ADminBond) {
                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            } else if (res.order_type == BondTypes.SGB) {
                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                state: { type: res.order_type, id: res.order_id, path: "customer_book" },
                                              });
                                            }
                                          }}
                                        ></i> */}
                              {/* {res.order_type != 4 ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table> */}
                              <DataTable
                                data={orderList}
                                columns={columns}
                                noHeader
                                defaultSortFieldId={"customer_id"}
                                sortIcon={<i className="fas fa-sort"></i>}
                                defaultSortAsc={true}
                                highlightOnHover
                                dense
                              />
                             
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-5">
                              {totalCount > 10 ? (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {orderList.length} of {totalCount} entries
                                </div>
                              ) : (
                                <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  Showing {totalCount == 0 ? 0 : totalCount} entries
                                </div>
                              )}
                            </div>
                            <div className="col-sm-12 col-md-7">
                              <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                <Pagination
                                  count={Math.ceil(totalCount / 10)}
                                  onChange={(event: any, value: any) => {
                                    setPageNumber(Number(value));
                                    setCurrentPage(Number(value));
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  shape="rounded"
                                  className="pagination-style"
                                />
                              </div>
                            </div>
                          </div>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CustomerOrderList;
