import axios from "axios";
import moment, { invalid } from "moment";
import toast from "react-hot-toast";
import { API } from "./Constants";
import { APIData } from "./DataTypes";
import * as Utilities from "./Utilities";

export namespace ServerAPI {

    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    }

    // export const Register = async(values: any) => {
    //     let data: { [k: string]: any } = {};
    //     data["mobile_number"] = values['mobile_number'];
    //     data["fname"] = values['firstName'];
    //     data["lname"] = values['lastName'];
    //     data["resend_otp"] = false;
    //     return executeAPI(API.EndPoint.register, APIMethod.POST, false, data);
    // }

    export const Login = async (values: any) => {
        let data: { [k: string]: any } = {};
        data["user_mobile"] = values['mobile_number'];
        // data["resend_otp"] = false;
        return executeAPI(API.EndPoint.login, APIMethod.POST, false, data);

    }
    // export const Login_resend_otp = async (values: any) => {
    //     let data: { [k: string]: any } = {};
    //     data["mobile_number"] = values['mobile_number'];
    //     data["resend_otp"] = true;
    //     return executeAPI(API.EndPoint.login_resend_otp, APIMethod.POST, false, data);

    // }
    // export const resend_otp = async (values: any) => {
    //     let data: { [k: string]: any } = {};
    //     data["mobile_number"] = values['mobile_number'];
    //     data["fname"] = values['firstName'];
    //     data["lname"] = values['lastName'];
    //     data["resend_otp"] = true;
    //     return executeAPI(API.EndPoint.resend_otp, APIMethod.POST, false, data);
    // }

    export const verifyOtp = async (otp: any, mobile: any) => {
        let data: { [k: string]: any } = {};
        data["user_mobile"] = mobile['mobile_number'];
        data["user_login_otp"] = otp;
        data["user_login_hash"] = localStorage.getItem('hashkey')
        return executeAPI(API.EndPoint.verify_otp, APIMethod.POST, false, data);

    }

    // export const verify_Login_Otp = async (values: any, otp: any) => {
    //     let data: { [k: string]: any } = {};
    //     data["otp"] = otp;
    //     data["mobile_number"] = values['mobile_number'];
    //     data["hash_key"] = values['hash_key'];
    //     return executeAPI(API.EndPoint.login_otp_verify, APIMethod.POST, false, data);

    // }


    export const getIPVDatas = async (user_id: any) => {
        let params: { [k: string]: any } = {};
        params["user_id"] = user_id
        return executeAPI(API.EndPoint.verify_ipv, APIMethod.GET, true, null, params)
    }

    export const updateIPVStatus = async (verify_status: any, remarks: any, user_id: any) => {
        let data: { [k: string]: any } = {};
        data["user_id"] = user_id;
        data["verification_status"] = Number(verify_status);
        data["remarks"] = remarks
        return executeAPI(API.EndPoint.update_ipv_status, APIMethod.PUT, false, data);

    }

    export const UserUpdateStatus = async (status_Data: any, user_id: any) => {
        let data: { [k: string]: any } = {};
        data["user_id"] = Number(user_id);
        data["user_status_key"] = Number(status_Data['user_status']);
        data["account_status_remarks"] = status_Data['account_status_remarks']
        data["account_status_reason"] = status_Data['account_status_reason']
        return executeAPI(API.EndPoint.update_account_status, APIMethod.PUT, false, data);
    }

    export const AddUpdateStatus = async (status_Data: any, user_id: any) => {
        let data: { [k: string]: any } = {};
        data["suspended_start_date"] = moment(status_Data.fromData).format('YYYY/MM/DD');
        data["suspended_end_date"] = moment(status_Data.toDate).format('YYYY/MM/DD');
        data["user_id"] = user_id;
        data["user_status_key"] = 3;
        data["account_status_remarks"] = status_Data['account_status_remarks']
        data["account_status_reason"] = status_Data['account_status_reason']
        return executeAPI(API.EndPoint.suspension_status_update, APIMethod.PUT, false, data);
    }
    export const ActiveSuspendedUser = async (status_Data: any) => {
        let data: { [k: string]: any } = {};
        data["user_id"] = status_Data;
        data["user_status_key"] = 2;
        return executeAPI(API.EndPoint.suspension_status_active, APIMethod.PUT, false, data);
    }

    export const UserLogsModule = async () => {
        return executeAPI(API.EndPoint.user_logs_module_dropdown, APIMethod.GET, true, null)

    }

    export const userDetailData = async (pageNumber: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.usersList, APIMethod.GET, true, null, params)
    }

    export const UserDetail = async (customer_id: any) => {
        let params: { [k: string]: any } = {};
        params['user_id'] = customer_id;
        return executeAPI(API.EndPoint.user_details, APIMethod.GET, true, null, params)
    }

    export const SuspentedUserList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.suspended_list, APIMethod.GET, true, null, params)
    }
    export const BrokerList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.Broker_list, APIMethod.GET, true, null, params)
    }
    export const Distributlist = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.Distribut_list, APIMethod.GET, true, null, params)
    }
    export const ASBAlist = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.ASBA_list, APIMethod.GET, true, null, params)
    }
    export const AllUserLogs = async (pageNumber: any, search_text: string, to_date: any, from_date: any, select_module: string) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (select_module !== "" && select_module !== null) {
            params['select_module'] = select_module;
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.all_users_logs, APIMethod.GET, true, null, params)
    }

    export const UserLogs = async (pageNumber: any, userId: any, search_text: string, to_date: any, from_date: any, select_module: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["user_id"] = userId;

        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (select_module !== "" && select_module !== null) {
            params['select_module'] = select_module;
        }

        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.individual_user_logs, APIMethod.GET, true, null, params)
    }

    export const DealerList = async (pageNumber: any, search_text: string, to_date: any, from_date: any, distributor_type_id: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        params["dealer_status"] = '1,0';
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        if (distributor_type_id != null && distributor_type_id != "" && distributor_type_id == 'Primary') {
            params['distributor_type_id'] = '3'
        }
        if (distributor_type_id != null && distributor_type_id != "" && distributor_type_id == 'Secondary') {
            params['distributor_type_id'] = '1'
        }
        return executeAPI(API.EndPoint.dealers + '-list', APIMethod.GET, true, null, params)
    }

    export const setDealerStatus = async (status: number, dealerID: number) => {
        let data: { [k: string]: any } = {};
        data["dealer_id"] = dealerID;
        data["dealer_status"] = status;
        return executeAPI(API.EndPoint.setDealerStatus, APIMethod.PUT, true, data, null)
    }
    export const setDistributorStatus = async (status: number, DistID: number) => {
        let data: { [k: string]: any } = {};
        data["distributor_type_id"] = DistID;
        data["distributor_type_status"] = status;
        return executeAPI(API.EndPoint.setDistributorStatus, APIMethod.PUT, true, data, null)
    }
    export const setASBA = async (status: number, ASBAID: number) => {
        let params: { [k: string]: any } = {};
        params["asba_bank_id"] = ASBAID;
        params["status"] = status;
        return executeAPI(API.EndPoint.setASBA, APIMethod.PUT, true, null, params)
    }
    export const SetBroker = async (status: number, brokerID: number) => {
        let params: { [k: string]: any } = {};
        params["broker_id"] = brokerID;
        params["status"] = status;
        return executeAPI(API.EndPoint.SetBroker, APIMethod.PUT, true, null, params)
    }
    export const setDistributorSubStatus = async (status: number, DistSubID: number, DistID: number) => {
        let data: { [k: string]: any } = {};
        data["distributor_sub_type_id"] = DistSubID;
        data["distributor_sub_type_status"] = status;
        data["distributor_type_id"] = DistID;
        return executeAPI(API.EndPoint.setDistributorSubStatus, APIMethod.PUT, true, data, null)
    }
    export const setDistributorCatStatus = async (status: number, DistCatID: number) => {
        let data: { [k: string]: any } = {};
        data["distributor_category_id"] = DistCatID;
        data["distributor_category_status"] = status;
        return executeAPI(API.EndPoint.setDistributorCatStatus, APIMethod.PUT, true, data, null)
    }
    export const BrangTag = async (status: number, BrangTagID: number) => {
        let data: { [k: string]: any } = {};
        data["brand_tag_id"] = BrangTagID;
        data["brand_tag_status"] = status;
        return executeAPI(API.EndPoint.BrangTag, APIMethod.PUT, true, data, null)
    }
    export const EligibleTagsList = async (status: number, EligibleID: number) => {
        let data: { [k: string]: any } = {};
        data["eligible_investor_id"] = EligibleID;
        data["eligible_investor_status"] = status;
        return executeAPI(API.EndPoint.EligibleTagsList, APIMethod.PUT, true, data, null)
    }
    export const BrokerageList = async (status: number, BrokerageID: number) => {
        let data: { [k: string]: any } = {};
        data["bond_brokerage_category_id"] = BrokerageID;
        data["bond_brokerage_category_status"] = status;
        return executeAPI(API.EndPoint.BrokerageList, APIMethod.PUT, true, data, null)
    }
    export const getDealerDetail = async (dealerID: number) => {
        let params: { [k: string]: any } = {};
        params["id"] = dealerID;
        return executeAPI(API.EndPoint.dealers, APIMethod.GET, true, null, params);
    }

    export const postDealer = async (values: any) => {
        return executeAPI(API.EndPoint.dealers, APIMethod.POST, true, values)
    }

    export const putDealer = async (values: any) => {
        return executeAPI(API.EndPoint.dealers, APIMethod.PUT, true, values)
    }

    export const putDealerLoginDetail = async (dealerID: number, dealerUserName: string, dealerPassword: string, dealerUniqueCode: string) => {
        let data: { [k: string]: any } = {};
        data["dealer_id"] = dealerID;
        data["dealer_user_name"] = dealerUserName;
        data["dealer_password"] = dealerPassword;
        data["dealer_unique_code"] = dealerUniqueCode;
        return executeAPI(API.EndPoint.dealerLoginDetails, APIMethod.PUT, true, data, null);
    }

    export const bondList = async (pageNumber: any, search_text: string, to_date: any, from_date: any, filter_type: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        // if (filter_type != null && filter_type != "" && filter_type == 'all') {
        //     params['filter_by'] = '1,2,3'
        // }
        if (filter_type != null && filter_type != "" && filter_type == 'IPO') {
            params['filter_by'] = '1'
        }
        if (filter_type != null && filter_type != "" && filter_type == 'BOND') {
            params['filter_by'] = '2'
        }
        return executeAPI(API.EndPoint.bond + '_list', APIMethod.GET, true, null, params)
    }

    export const bondDetail = async (isinNumber: any) => {
        let params: { [k: string]: any } = {};
        params['bond_isin_number'] = isinNumber;
        return executeAPI(API.EndPoint.bondDetail, APIMethod.GET, false, null, params)
    }
    export const BrokerListID = async (id: any) => {
        let params: { [k: string]: any } = {};
        params['broker_id'] = id;
        return executeAPI(API.EndPoint.BrokerListID, APIMethod.GET, true, null, params)
    }
    export const DistributListID = async (id: any) => {
        let params: { [k: string]: any } = {};
        params['distributor_id'] = id;
        return executeAPI(API.EndPoint.DistributListID, APIMethod.GET, true, null, params)
    }
    export const IfscCode = async (ifsc: string) => {
        let params: { [k: string]: any } = {};
        params["bank_ifsc"] = ifsc;
        return executeBankAPI(API.EndPoint.ifsc_code, APIMethod.GET, true, null, params);
    };
    export const postBond = async (values: any) => {
        return executeAPI(API.EndPoint.populateBond, APIMethod.POST, true, values)
    }

    export const putBond = async (values: any) => {
        return executeAPI(API.EndPoint.populateBond, APIMethod.PUT, true, values)
    }

    export const getBrandTagsList = async () => {
        let params: { [k: string]: any } = {};
        params["brand_tag_status"] = '1,0'
        return executeAPI(API.EndPoint.brandTags + "-list", APIMethod.GET, true, null, params)
    }
    export const segment = async () => {
        return executeDealerAPI(API.EndPoint.segmentlist, APIMethod.GET, true, null)
    }
    export const companies_List = async () => {
        return executeDealerAPI(API.EndPoint.companieslist, APIMethod.GET, true, null)
    }
    export const emp_list = async () => {
        return executeDealerAPI(API.EndPoint.emplist, APIMethod.GET, true, null)
    }
    export const getbrokeradvisory = async () => {
        let params: { [k: string]: any } = {};
        params["broker_status"] = ''
        return executeAPI(API.EndPoint.Broker_list + "-list", APIMethod.GET, true, null, params)
    }

    export const getEligibleInvestorList = async () => {
        let params: { [k: string]: any } = {};
        params["eligible_investor_status"] = '1,0'
        return executeAPI(API.EndPoint.eligibleInvestor + "-list", APIMethod.GET, true, null, params)
    }

    export const postIPO = async (values: any) => {
        return executeAPI(API.EndPoint.IPO, APIMethod.POST, true, values)
    }

    export const putIPO = async (values: any) => {
        return executeAPI(API.EndPoint.IPO, APIMethod.PUT, true, values)
    }

    export const setUploadedFile = async (file: any) => {
        const config = { headers: { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('file', file);

        try {
            const response = await axios.post(API.BaseUrl + API.EndPoint.DocumentUpload, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Something went wrong. Please try again after some time. " };
        }
    }
    export const LogXl = async () => {
        const config = { headers: { 'accept': 'application/json', 'Content-Type': 'blob' } };
        try {
            const response = await axios.get(API.BaseUrl + API.EndPoint.LogXl, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Something went wrong. Please try again after some time. " };
        }
    }
    export const IPODetail = async (id: any) => {
        let params: { [k: string]: any } = {};
        params['id'] = id;
        return executeAPI(API.EndPoint.IPO, APIMethod.GET, true, null, params)
    }

    export const getSGBList = async (pageNumber: any, search_text: string, to_date: any, from_date: any, filter_type: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        if (filter_type != null && filter_type != "" && filter_type == 'all') {
            params['filter_by'] = '4'
        }
        return executeAPI(API.EndPoint.bond + '_list', APIMethod.GET, true, null, params)
    }

    export const postSGB = async (values: any) => {
        return executeAPI(API.EndPoint.populateSGB, APIMethod.POST, true, values)
    }

    export const putSGB = async (values: any) => {
        return executeAPI(API.EndPoint.populateSGB, APIMethod.PUT, true, values)
    }

    export const getSGBDetail = async (bond_isin_number: string) => {
        let params: { [k: string]: any } = {};
        params['bond_isin_number'] = bond_isin_number;
        return executeAPI(API.EndPoint.getSpecificSGB, APIMethod.GET, true, null, params)
    }

    export const getIPOList = async () => {
        let params: { [k: string]: any } = {};
        // params["limit"] = 10
        // params["page_number"] = pageNumber;
        // if (search_text != "") {
        //     params['search_text'] = search_text
        // }
        // if (to_date != null && to_date != "") {
        //     params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        // }
        // if (from_date != null && from_date != "" && from_date != invalid) {
        //     params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        // }
        params['filter_by'] = '1'
        return executeAPI(API.EndPoint.bond + '_list', APIMethod.GET, true, null, params)
    }

    export const formAllocationList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['bond_form_allocation_status'] = '1,0'
        return executeAPI(API.EndPoint.ipoFormAllocation + '-list', APIMethod.GET, true, null, params)
    }

    export const postFormAllocationAdd = async (values: any) => {
        return executeAPI(API.EndPoint.ipoFormAllocation, APIMethod.POST, true, values)
    }

    export const getIPOFormList = async () => {
        let params: { [k: string]: any } = {};
        // params["limit"] = 10
        // params["page_number"] = pageNumber;
        // if (search_text != "") {
        //     params['search_text'] = search_text
        // }
        // if (to_date != null && to_date != "") {
        //     params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        // }
        // if (from_date != null && from_date != "" && from_date != invalid) {
        //     params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        // }
        params['bond_form_allocation_status'] = '1,0'
        return executeAPI(API.EndPoint.ipoFormAllocation + '-list', APIMethod.GET, true, null, params)
    }

    export const getIPODetail = async (id: number) => {
        let params: { [k: string]: any } = {};
        params['id'] = id
        return executeAPI(API.EndPoint.ipoFormAllocation, APIMethod.GET, true, null, params)
    }

    export const getDealerList = async () => {
        return executeAPI(API.EndPoint.dealers + '-list', APIMethod.GET, true, null)
    }

    export const postDistributorType = async (values: any) => {
        return executeAPI(API.EndPoint.Distributor_type, APIMethod.POST, true, values)
    }
    export const postASBAList = async (values: any) => {
        return executeAPI(API.EndPoint.ASBA_type, APIMethod.POST, true, values)
    }
    export const postBroker = async (values: any) => {
        return executeAPI(API.EndPoint.Broker_type, APIMethod.POST, true, values)
    }
    export const postDistribut = async (values: any) => {
        return executeAPI(API.EndPoint.Distribut_type, APIMethod.POST, true, values)
    }
    export const putDistributorType = async (rowId: any, rowStatus: number, rowName: string) => {
        let data: { [k: string]: any } = {};
        data["distributor_type_id"] = rowId;
        data["distributor_type_name"] = rowName;
        data["distributor_type_status"] = rowStatus;
        return executeAPI(API.EndPoint.Distributor_type, APIMethod.PUT, true, data)
    }
    export const putASBAList = async (rowId: any, rowName: string, rowStatus: number, rowCode: string, rowSort: string, rowFull: string) => {
        let data: { [k: string]: any } = {};
        data["asba_bank_id"] = rowId;
        data["asba_bank_name"] = rowName;
        data["asba_bank_status"] = rowStatus;
        data["asba_bank_code"] = rowCode;
        data["asba_loc_short_name"] = rowSort;
        data["asba_loc_full_name"] = rowFull;
        return executeAPI(API.EndPoint.Put_ASBA_List, APIMethod.PUT, true, data)
    }
    export const putBroker = async (values: any) => {
        return executeAPI(API.EndPoint.PutBroker_List, APIMethod.PUT, true, values)
    }
    export const putDistribut = async (values: any) => {
        return executeAPI(API.EndPoint.PutDistribut_List, APIMethod.PUT, true, values)
    }
    export const getDistributorTypeList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['distributor_type_status'] = '1,0'
        return executeAPI(API.EndPoint.Distributor_type + '-list', APIMethod.GET, true, null, params)
    }
    export const getDistributorTypeLists = async (id: number) => {
        let params: { [k: string]: any } = {};
        params['distributor_type_id'] = id
        return executeAPI(API.EndPoint.Distributor_type + '-list', APIMethod.GET, true, null, params)
    }
    export const distributorTypeList = async () => {
        return executeAPI(API.EndPoint.Distributor_type + '-list', APIMethod.GET, true, null)
    }

    export const postDistributorSubType = async (values: any) => {
        return executeAPI(API.EndPoint.Distributor_sub_type, APIMethod.POST, true, values)
    }

    export const putDistributorSubType = async (rowId: any, rowName: string, rowStatus: number, rowDId: any, rowDname: any) => {
        let data: { [k: string]: any } = {};
        data["distributor_sub_type_id"] = rowId;
        data["distributor_sub_type_name"] = rowName;
        data["distributor_sub_type_status"] = rowStatus;
        data["distributor_type_id"] = rowDId;
        data["distributor_type_name"] = rowDname;
        return executeAPI(API.EndPoint.Distributor_sub_type, APIMethod.PUT, true, data)
    }

    export const getDistributorSubTypeList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['distributor_sub_type_status'] = '1,0'
        return executeAPI(API.EndPoint.Distributor_sub_type + '-list', APIMethod.GET, true, null, params)
    }

    export const postDistributorCategory = async (values: any) => {
        return executeAPI(API.EndPoint.DistributorCategory, APIMethod.POST, true, values)
    }

    export const putDistributorCategory = async (rowId: any, rowName: string, rowStatus: number) => {
        let data: { [k: string]: any } = {};
        data["distributor_category_id"] = rowId;
        data["distributor_category_name"] = rowName;
        data["distributor_category_status"] = rowStatus;
        return executeAPI(API.EndPoint.DistributorCategory, APIMethod.PUT, true, data)
    }
    export const getDistributorCategoryList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['distributor_category_status'] = '1,0'
        return executeAPI(API.EndPoint.DistributorCategory + '-list', APIMethod.GET, true, null, params)
    }

    export const postBrandTag = async (values: any) => {
        return executeAPI(API.EndPoint.brandTags, APIMethod.POST, true, values)
    }

    export const putBrandTag = async (values: any) => {
        return executeAPI(API.EndPoint.brandTags, APIMethod.PUT, true, values)
    }

    export const BrandTagList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['brand_tag_status'] = '1,0'
        return executeAPI(API.EndPoint.brandTags + '-list', APIMethod.GET, true, null, params)
    }

    export const postEligibleTag = async (values: any) => {
        return executeAPI(API.EndPoint.eligibleInvestor, APIMethod.POST, true, values)
    }

    export const putEligibleTag = async (values: any) => {
        return executeAPI(API.EndPoint.eligibleInvestor, APIMethod.PUT, true, values)
    }

    export const EligibleTagList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['eligible_investor_status'] = '1,0'
        return executeAPI(API.EndPoint.eligibleInvestor + '-list', APIMethod.GET, true, null, params)
    }
    export const APiStatus = async (pageNumber: any) => {
        let params: { [k: string]: any } = {};
        params["page_limit"] = 10
        params["page_number"] = pageNumber;
        return executeAPI(API.EndPoint.apistatus, APIMethod.GET, true, null, params)
    }

    export const postBrokerageCategory = async (values: any) => {
        return executeAPI(API.EndPoint.BrokerageCategory, APIMethod.POST, true, values)
    }

    export const putBrokerageCategory = async (values: any) => {
        return executeAPI(API.EndPoint.BrokerageCategory, APIMethod.PUT, true, values)
    }

    export const BrokerageCategoryList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        params['bond_brokerage_category_status'] = '1,0'
        return executeAPI(API.EndPoint.BrokerageCategory + '-list', APIMethod.GET, true, null, params)
    }

    export const getBondRatingList = async () => {
        return executeAPI(API.EndPoint.BondRatingList, APIMethod.GET, true, null)
    }
    export const getloadGender = async () => {
        return executeDealerAPI(API.EndPoint.loadGender, APIMethod.GET, true, null)
    }
    export const getDtype = async () => {
        return executeAPI(API.EndPoint.Distribut_list, APIMethod.GET, true, null)
    }
    export const getStype = async () => {
        return executeAPI(API.EndPoint.Distributor_sub_type + '-list', APIMethod.GET, true, null)

    }
    export const getCategory = async () => {
        return executeAPI(API.EndPoint.DistributorCategory + '-list', APIMethod.GET, true, null)

    }
    export const getState = async () => {
        return executeDealerAPI(API.EndPoint.stateList, APIMethod.GET, true, null)

    }
    export const getCustomerOrderList = async (pageNumber: any, search_text: string, to_date: any, from_date: any, filter_type: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        // if (filter_type != null && filter_type != "" && filter_type == 'all') {
        //     params['order_type'] = '1,2,3,4'
        // }
        // if (filter_type != null && filter_type != "" && filter_type == 0) {
        //     params['order_type'] = '1'
        // }
        if (filter_type != null && filter_type != "" && filter_type == 2) {
            params['order_type'] = '2'
        }
        else if (filter_type != null && filter_type != "" && filter_type == 1) {
            params['order_type'] = '4'
        } else {
            params['order_type'] = '1'
        }
        return executeAPI(API.EndPoint.CustomerOrderList + '_list', APIMethod.GET, true, null, params)
    }

    export const getOrderListDetailView = async (type: number, id: number) => {
        let params: { [k: string]: any } = {};
        params["order_type"] = type;
        params["order_id"] = id;
        return executeAPI(API.EndPoint.SpecificOrderList, APIMethod.GET, true, null, params);
    }

    export const BondSubcribeList = async (pageNumber: any, search_text: string, to_date: any, from_date: any,) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.BondSubcribe, APIMethod.GET, true, null, params)
    }

    export const getCustomerEnquiryList = async (pageNumber: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeDealerAPI(API.EndPoint.CustomerEnquiryList, APIMethod.GET, true, null, params)
    }

    export const getActiveBondList = async (pageNumber: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeDealerAPI(API.EndPoint.ActiveBondList, APIMethod.GET, true, null, params)
    }

    export const getAllBondList = async (pageNumber: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeDealerAPI(API.EndPoint.AllBondList, APIMethod.GET, true, null, params)
    }

    export const ActiveBond = async (bondID: number, actionState: number) => {
        let params: { [k: string]: any } = {};
        params["bond_id"] = bondID;
        params["action"] = actionState;
        return executeDealerAPI(API.EndPoint.AllBondList, APIMethod.PUT, false, null, params);
    }

    export const PublishUnpublishBond = async (status: number, listingID: number) => {
        let data: { [k: string]: any } = {};
        data["bond_listing_publish_unpublish"] = status;
        data["bond_listing_id"] = listingID;
        return executeDealerAPI(API.EndPoint.ActiveBondList, APIMethod.PUT, false, data);
    }

    export const BondEnquiryUpdate = async (bondEnquiryID: number, settlementDateID: number, startDate: string, endDate: string, price: string, yeild: number, quantity: string, buysellID: number) => {
        let data: { [k: string]: any } = {};
        data["customer_bond_enquire_id"] = bondEnquiryID;
        data["quantity"] = quantity;
        data["price"] = price;
        data["yield"] = yeild;
        data["settlement_date_id"] = settlementDateID;
        data["buy_sell_id"] = buysellID;
        data["bond_start_date"] = startDate;
        data["bond_end_date"] = endDate;
        console.log(data)
        return executeDealerAPI(API.EndPoint.CustomerEnquiryList, APIMethod.PUT, false, data);
    }
    export const Calculater = async (bondID:any,  Yield: number, qty: number, price: number, bonds: number) => {
        let data: { [k: string]: any } = {};
        data["bond_id"] = bondID;
        data["quantity"] = qty
        data["price"] = price;
        data["yield"] = Yield;
        data["settlement_date_id"] = 1;
        data["buy_sell_id"] = bonds;
        return executeDealerAPI(API.EndPoint.Calculater, APIMethod.POST, true, data);
    }
    export const getPriceChangeLogsList = async (pageNumber: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeDealerAPI(API.EndPoint.PriceChangeLogs, APIMethod.GET, true, null, params)
    }
    export const settlements = async () => {
        return executeDealerAPI(API.EndPoint.settlement_date, APIMethod.GET, true)
    }
    export const Yields = async () => {
        return executeDealerAPI(API.EndPoint.Yields, APIMethod.GET, true)
    }
    export const aidcc = async () => {
        return executeDealerAPI(API.EndPoint.aidcc, APIMethod.GET, true)
    }
    export const price_calculator = async (isin: number, settlementID: number, YieldID: number, price: number, YieldVal: number, aidccID: number) => {
        let data: { [k: string]: any } = {};
        data["isin_number"] = isin;
        data["settlement_date"] = settlementID;
        data["yield_type"] = YieldID;
        data["price_value"] = price;
        data["yield_value"] = YieldVal;
        data["ai_dcc"] = aidccID;
        return executeDealerAPI(API.EndPoint.yield_price_calculator, APIMethod.POST, true, data);
    }
    export const ActiveBondNewPriceUpdate = async (bondID: number, price: string, yeild: string, startDate: string, endDate: string, quantity: string, buysellID: number, publisgUnpublish: number, listingID: number) => {
        let data: { [k: string]: any } = {};
        data["bond_id"] = bondID;
        data["bond_listing_price"] = price;
        data["bond_listing_yield"] = yeild;
        data["bond_listing_start_date"] = startDate;
        data["bond_listing_end_date"] = endDate;
        data["bond_listing_quantity"] = quantity;
        data["buy_sell_id"] = buysellID;
        data["bond_listing_publish_unpublish"] = publisgUnpublish;
        data["bond_listing_id"] = listingID;
        console.log(data)
        return executeDealerAPI(API.EndPoint.ActiveBondList, APIMethod.PUT, false, data);
    }

    // export const UserDetailMyOrders = async (customer_id: any) => {
    //     let params: { [k: string]: any } = {};
    //     params['user_id'] = customer_id;
    //     return executeAPI(API.EndPoint.SpecificOrder+"_list", APIMethod.GET, true, null, params)
    // }

    export const UserDetailMyOrders = async (pageNumber: any, customerId: any, search_text: string, to_date: any, from_date: any) => {
        let params: { [k: string]: any } = {};
        params["limit"] = 10
        params["user_id"] = customerId;

        params["page_number"] = pageNumber;
        if (search_text != "") {
            params['search_text'] = search_text
        }
        if (to_date != null && to_date != "") {
            params['to_date'] = moment(to_date).format('YYYY-MM-DD')
        }
        if (from_date != null && from_date != "" && from_date != invalid) {
            params['from_date'] = moment(from_date).format('YYYY-MM-DD')
        }
        return executeAPI(API.EndPoint.SpecificOrder + "_list", APIMethod.GET, true, null, params)
    }


    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean,
        returnError?: boolean
    ) => {
        let headers: { [k: string]: any } = {};
        // const token = localStorage.getItem('token');
        // headers["Authorization"] = token;
        if (authRequired) {
            const persistAuthString = await Utilities.getAuthString();
            if (persistAuthString) {
                headers["Authorization"] = persistAuthString;
            }
        }

        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }

        try {
            const response = await axios({
                method: method,
                url: API.BaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            return response.data;

        } catch (error) {

            if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        toast.error(errorData.errors);
                    } else {
                        toast.error(errorData.errors);
                    }

                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
            if (axios.isAxiosError(error) && error.response && returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        return (errorData.errors);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
        }
    }

    export const executeDealerAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean,
        returnError?: boolean
    ) => {
        let headers: { [k: string]: any } = {};
        // const token = localStorage.getItem('token');
        // headers["Authorization"] = token;
        if (authRequired) {
            const persistAuthString = await Utilities.getAuthString();
            if (persistAuthString) {
                headers["Authorization"] = persistAuthString;
            }
        }

        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }

        try {
            const response = await axios({
                method: method,
                url: API.DealerBaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            return response.data;

        } catch (error) {

            if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        toast.error(errorData.errors);
                    } else {
                        toast.error(errorData.errors);
                    }

                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
            if (axios.isAxiosError(error) && error.response && returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        return (errorData.errors);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
        }



    }
    export const executeBankAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean,
        returnError?: boolean
    ) => {
        let headers: { [k: string]: any } = {};
        // const token = localStorage.getItem('token');
        // headers["Authorization"] = token;
        if (authRequired) {
            const persistAuthString = await Utilities.getAuthString();
            if (persistAuthString) {
                headers["Authorization"] = persistAuthString;
            }
        }

        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }

        try {
            const response = await axios({
                method: method,
                url: API.BankBaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            return response.data;

        } catch (error) {

            if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        toast.error(errorData.errors);
                    } else {
                        toast.error(errorData.errors);
                    }

                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
            if (axios.isAxiosError(error) && error.response && returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        return (errorData.errors);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
        }



    }
}
