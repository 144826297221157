import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountStatus, Depository, PageLinks } from "../common/Constants";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
import { getIsLogin, getDownloadFileXsl } from "../common/Utilities";
import SuspendedModal from "../modals/Suspended-modal";

var startSerialNo = 0;
const Users: React.FC = () => {
  const navigate = useNavigate();
  const [column, setColumn] = useState(null);
  const [sort, setSort] = useState({ column: null, direction: "desc" });
  const location = useLocation();
  const suspended_data = location.state;
  // const [suspended_data, setSuspended_data] = useState(location.state);
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [userList, setUserList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  // const [enable_desable, setEnableDesable] = useState(true);
  const [search_text, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [user_id, setUserId] = useState();
  const { setShowPreloader, activeColor, setActiveColor } = useAppStateAPI();
  const ref = React.createRef();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  let columns: any = [
    {
      name: 'Sr.No',
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },

    {
      name: "Cust. ID",
      selector: (row: any) => row.customer_id == null || row.customer_id == "" ? 'N/A' : row.customer_id,
      sortable: true
    },
    {
      name: "Reg. Date",
      selector: (row: any) => row.users_created_at,
      sortable: true,
      format: (row: any) => moment(row.users_created_at).format('DD-MM-YYYY'),
    },
    {
      name: "Reg. Time",
      selector: (row: any) => row.users_created_at,
      sortable: true,
      format: (row: any) => moment(row.users_created_at).format('hh:mm A'),
    },
    {
      name: "Customer Name",
      selector: "customer_name",
      sortable: true
    },
    {
      name: "PAN Number",
      selector: (row: any) => row.pan_number !== null && row.pan_number !== "" ? row.pan_number : 'N/A',
      sortable: true
    },
    {
      name: "Customer Type",
      selector: (row: any) => row.customer_type === null && 'N/A' ||
        row.customer_type === 0 && 'N/A' ||
        row.customer_type === 1 && 'Individual' ||
        row.customer_type === 2 && 'Hindu Undivided Family (HUF)' ||
        row.customer_type === 3 && 'Corporate' ||
        row.customer_type === null && 'Non-Resident Indians (NRIs)',
      sortable: true
    },
    {
      name: "Mobile Number",
      selector: (row: any) => row.mobile_number !== null && row.mobile_number !== "" ? row.mobile_number : 'N/A',
      sortable: true,
    },
    {
      name: "Bank Name",
      selector: (row: any) => row.bank_name !== null && row.bank_name !== "" ? row.bank_name : 'N/A',
      sortable: true
    },
    {
      name: "DP Name",
      selector: (row: any) => row.dp_name != null ? (row.dp_name == Depository.CSDL || row.customer_id == "" ? 'CSDL' : 'NSDL') : "N/A",
      sortable: true
    },
    {
      name: "Status",
      selector: (row: any) => (row.user_status_key === 0 && 'N/A' ||
        row.user_status_key === 1 && <p className="text-blue">Registered</p> ||
        row.user_status_key === 2 && <p className="text-green">Active</p> ||
        row.user_status_key === 4 && <p className="text-red">Closed</p> ||
        row.user_status_key === 3 && (
          <div>
            <div className="m-0 text-brown">Suspended</div>
            <div className="text-small">
              Start <span className="text-brown">{row.suspended_start_date !== "" || row.suspended_start_date !== null ? <>{moment(row.suspended_start_date).format("DD-MM-YYYY")}</> : "N/A"}</span>, End{" "}
              <span className="text-brown">{row.suspended_end_date !== "" || row.suspended_end_date !== null ? <>{moment(row.suspended_end_date).format("DD-MM-YYYY")}</> : "N/A"}</span>
            </div>
          </div>
        )),
      sortable: true
    },
    {
      name: "Action",
      selector: (row: any) => row.user_specific_id,
      cell: (row: any) => [
        <i
          className="fas fa-eye table-icons px-2"
          onClick={() =>
            navigate(PageLinks.USERSDETAILS, {
              state: row.user_specific_id,
            })
          }
        ></i>
      ]
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };
  useEffect(() => {
    getIsLogin().then((status) => {
      console.log(status);
      if (!status) {
        navigate(PageLinks.LOGIN);
      }
    });
  }, []);
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  }
  useEffect(() => {
    userList_data();
  }, [pageNumber, search_text, toData, fromDate, suspended_data]);

  const userList_data = () => {
    setShowPreloader(true);
    ServerAPI.userDetailData(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        setUserList(res.data);
        setTotalCount(res.total_records);
        // console.log(res.datas.filter((e: any) => { return e.new_demat_account_details != undefined && e.new_demat_account_details.length > 0 }))
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleClickOpen = () => { };
  const handleClose = (e: any) => {
    setOpen(e);
    userList_data();
  };

  const openPopUp = (e: any) => {
    setOpen(true);
    setUserId(e);
  };
  const handelClickXsl = () => {
    setShowPreloader(true);
    getDownloadFileXsl("https://trust-api.trustmony.com/admin/api/v1/user-data-xls")
      setShowPreloader(false);
  }
  const back_page = () => {
    navigate(PageLinks.SUSPENDED_USER_LIST);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                    <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                </svg> */}
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  return (
    <div>
      <div>
        <div id="layout-wrapper">
          <Header />
          <div className="main-content py-4">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                      <div className="page-title-rightds">
                        {suspended_data !== true ? (
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item">
                              <a style={{ color: "#90929E" }}>Trustmony</a>
                            </li>
                            <li className="breadcrumb-item active">
                              <b>All Users</b>
                            </li>
                          </ol>
                        ) : (
                          <ol className="breadcrumb ">
                            <li className="breadcrumb-item">
                              <a style={{ color: "#90929E" }}>Trustmony</a>
                            </li>
                            <li className="breadcrumb-item ">Users</li>
                            <li className="breadcrumb-item "> Suspend Users</li>
                            <li className="breadcrumb-item active">
                              <b>Add</b>
                            </li>
                          </ol>
                        )}
                      </div>
                      {suspended_data === true && (
                        <div className="cursor-pointer" onClick={() => back_page()}>
                          <p className="mb-0 active">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                              <path
                                id="Path_22590"
                                data-name="Path 22590"
                                d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                                transform="translate(1775.371 -858.797)"
                              />
                            </svg>
                            <span> Back </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="d-flex justify-content-start align-items-center">
                              {suspended_data !== true ? <h3 className="card-heading p-2 m-0">All User</h3> : <h3 className="card-heading p-2 m-0">Add Suspend Users</h3>}
                              <p className="text-bold p-2 m-0">Search :</p>
                              <form className="app-search card-title p-2 m-0">
                                <div className="position-relative">
                                  <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Cust. ID,Reg. Date,Reg. Time, C..." />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-xl-6 text-end">
                            <div className="d-flex justify-content-end align-items-center">
                              <button className="xl_sheet" onClick={handelClickXsl}>Download <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 84.227 95.107" className="xsl_icon">
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect id="Rectangle_3009" data-name="Rectangle 3009" width="84.227" height="95.107" />
                                  </clipPath>
                                </defs>
                                <g id="Group_10650" data-name="Group 10650" transform="translate(-0.667 0.247)">
                                  <g id="Group_10649" data-name="Group 10649" transform="translate(0.667 -0.247)" clip-path="url(#clip-path)">
                                    <path id="Path_21458" data-name="Path 21458" d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834" />
                                  </g>
                                </g>
                              </svg>
                              </button>
                              <form className="app-search card-title p-2 w-100">
                                <div className="position-relative">
                                  {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={true} name="dob" format="dd/MM/yyyy"
                                                                        dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                                        onChange={setFromDate} value={fromDate}
                                                                        calendarIcon={
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                                                                                <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                                                                            </svg>
                                                                        } /> */}
                                  <DatePicker
                                    selected={fromDate}
                                    closeOnScroll={true}
                                    onChange={(date: Date) => setFromDate(date)}
                                    placeholderText="DD/MM/YYYY"
                                    className='date-picker-style'
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={(<CustomInput />)}
                                  />
                                </div>
                              </form>
                              <h4 className="card-title p-2">TO</h4>
                              <form className="app-search card-title p-2 w-100">
                                <div className="position-relative">
                                  {/* <DatePicker className="date-picker-input" maxDate={new Date()} clearIcon={null} closeCalendar={false} name="dob" format="dd/MM/yyyy"
                                                                    dayPlaceholder="DD" monthPlaceholder="MM" yearPlaceholder="YYYY"
                                                                    onChange={setToData} value={toData}
                                                                    calendarIcon={
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 51.297 58.625">
                                                                            <path id="Path_22586" data-name="Path 22586" d="M-1365.036,907.615a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-40.3a5.5,5.5,0,0,1,5.5-5.5h5.5V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h14.656V855.86a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v5.954h5.5a5.5,5.5,0,0,1,5.5,5.5Zm-5.5-34.808h-40.3v34.121a.689.689,0,0,0,.687.687h38.93a.689.689,0,0,0,.687-.687Zm-33.434,14.656a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58a1.378,1.378,0,0,1-1.374,1.374Zm4.58,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1399.386,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1388.394,898.455Zm10.992-10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374v-4.58a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,887.463Zm0,10.992h-4.58a1.378,1.378,0,0,1-1.374-1.374V892.5a1.378,1.378,0,0,1,1.374-1.374h4.58a1.378,1.378,0,0,1,1.374,1.374v4.58A1.378,1.378,0,0,1-1377.4,898.455Z" transform="translate(1416.333 -854.486)" />
                                                                        </svg>
                                                                    } /> */}
                                  <DatePicker
                                    selected={toData}
                                    closeOnScroll={true}
                                    onChange={(date: Date) => setToData(date)}
                                    placeholderText="DD/MM/YYYY"
                                    className='date-picker-style'
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={(<CustomInput />)}
                                  />
                                </div>
                              </form>
                              <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                            </div>
                          </div>
                        </div>

                        <div className="table-rep-plugin">
                          <div className="table-responsive " data-pattern="priority-columns">
                            {/* <table id="tech-companies-1" className="table table table-bordered">
                              <thead className="city-table">
                                <tr>
                                  <th> Sr.No</th>
                                  <th>Cust. ID</th>
                                  <th>Reg. Date</th>
                                  <th>Reg. Time</th>
                                  <th>Customer Name</th>
                                  <th>PAN Number</th>
                                  <th>Customer Type</th>
                                  <th>Mobile Number</th>
                                  <th>Bank Name</th>
                                  <th>DP Name</th>
                                  <th>Status</th>
                                  <th colSpan={3} data-priority="3" className="">
                                    Action
                                  </th>
                                </tr>
                              </thead> */}
                            {/* {userList != undefined && userList.length > 0 ? (
                                <>
                                  {userList.map((res: any, i: any) => (
                                    <tbody key={i}>
                                      <tr>
                                        <td>{startSerialNo + i + 1}</td>
                                        <td>{res.customer_id == null || res.customer_id == "" ? "N/A" : res.customer_id}</td>
                                        <td>{res.users_created_at != null && moment(res.users_created_at).format("DD-MM-YYYY")}</td>
                                        <td>{res.users_created_at != null && moment(res.users_created_at).format("hh:mm A")}</td> */}
                            {/* <td>
                                          {res.fname} {res.mname} {res.lname}
                                        </td> */}
                            {/* <td>
                                          {res.customer_name}
                                        </td>
                                        <td>{res.pan_number != null && res.pan_number !== "null" ? <>{res.pan_number}</> : "N/A"}</td> */}
                            {/* <td>N/A</td> */}

                            {/* {res.customer_type === null && <td>N/A</td>}
                                        {res.customer_type === 0 && <td>N/A</td>}
                                        {res.customer_type === 1 && <td> Individual </td>}
                                        {res.customer_type === 2 && <td>Hindu Undivided Family (HUF)</td>}
                                        {res.customer_type === 3 && <td>Corporate</td>}
                                        {res.customer_type === 4 && <td>Non-Resident Indians (NRIs)</td>} */}
                            {/* <td>{res.customer_type != null && res.customer_type !== "null" ? <>{res.customer_type}</> : "N/A"}</td> */}

                            {/* <td>{res.mobile_number !== null || res.mobile_number !== "" ? <>{res.mobile_number}</> : "N/A"}</td>
                                        <td>{res.bank_name != null && res.bank_name !== "" ? <>{res.bank_name}</> : "N/A"}</td>
                                        <td>{res.dp_name != null ? <>{res.dp_name == Depository.CSDL ? "CSDL" : "NSDL"}</> : "N/A"}</td> */}
                            {/* {!res.user_status_key && <td>N/A</td>} */}
                            {/* <td>{res.user_status_key === 0 && <td>N/A</td>}
                                          {res.user_status_key === 1 && <td className="text-blue">Registered</td>}
                                          {res.user_status_key === 2 && <td className="text-green">Active</td>}
                                          {res.user_status_key === 4 && <td className="text-red">Closed</td>}
                                          {res.user_status_key === 3 && (
                                            <td>
                                              <div className="m-0 text-brown">Suspended</div>
                                              <div className="text-small">
                                                Start <span className="text-brown">{res.suspended_start_date !== "" || res.suspended_start_date !== null ? <>{moment(res.suspended_start_date).format("DD-MM-YYYY")}</> : "N/A"}</span>, End{" "}
                                                <span className="text-brown">{res.suspended_end_date !== "" || res.suspended_end_date !== null ? <>{moment(res.suspended_end_date).format("DD-MM-YYYY")}</> : "N/A"}</span>
                                              </div>
                                            </td>
                                          )}</td>

                                        <td className="">
                                          <i
                                            className="fas fa-eye table-icons px-2"
                                            onClick={() =>
                                              navigate(PageLinks.USERSDETAILS, {
                                                state: res.user_specific_id,
                                              })
                                            }
                                          ></i> */}
                            {/* {suspended_data === true && <i className="fa fa-ban text-red cursor-pointer" aria-hidden="true" onClick={() => setOpen(true)} ></i>} */}
                            {/* {suspended_data === true && res.user_status_key !== AccountStatus.Suspended && res.user_status_key !== AccountStatus.Closed ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" className="pb-2 cursor-pointer" height="22" viewBox="0 0 73.276 58.625" onClick={(e) => openPopUp(res.user_specific_id)}>
                                              <path
                                                id="Path_22599"
                                                data-name="Path 22599"
                                                d="M-1918.391,904.684v2.931a5.5,5.5,0,0,1-5.5,5.5h-40.3a5.5,5.5,0,0,1-5.5-5.5v-2.931a15.394,15.394,0,0,1,15.39-15.389c3.3,0,4.866,1.832,10.259,1.832s6.962-1.832,10.26-1.832A15.392,15.392,0,0,1-1918.391,904.684Zm-5.5,0a9.909,9.909,0,0,0-9.892-9.893c-1.684,0-4.34,1.832-10.26,1.832-5.874,0-8.588-1.832-10.259-1.832a9.91,9.91,0,0,0-9.893,9.893v2.931h40.3Zm-36.64-33.709a16.493,16.493,0,0,1,16.488-16.489,16.493,16.493,0,0,1,16.488,16.489,16.493,16.493,0,0,1-16.488,16.488A16.493,16.493,0,0,1-1960.527,870.975Zm5.5,0a11.01,11.01,0,0,0,10.992,10.992,11.009,11.009,0,0,0,10.992-10.992,11.009,11.009,0,0,0-10.992-10.993A11.01,11.01,0,0,0-1955.031,870.975Zm58.087,15.732a1.839,1.839,0,0,1,0,2.588l-1.294,1.294a1.838,1.838,0,0,1-2.588,0l-4.74-4.74-4.741,4.74a1.838,1.838,0,0,1-2.588,0l-1.293-1.294a1.839,1.839,0,0,1,0-2.588l4.74-4.74-4.74-4.74a1.839,1.839,0,0,1,0-2.588l1.293-1.294a1.839,1.839,0,0,1,2.588,0l4.741,4.74,4.74-4.74a1.839,1.839,0,0,1,2.588,0l1.294,1.294a1.839,1.839,0,0,1,0,2.588l-4.741,4.74Z"
                                                transform="translate(1969.688 -854.486)"
                                                fill="red"
                                              />
                                            </svg>
                                          ) : (
                                            ""
                                          )}
                                        </td> */}
                            {/* </tr>
                                    </tbody>
                                  ))}
                                </>
                              ) : (
                                <tbody>
                                  <tr className="py-4">
                                    <td className="text-center py-5" colSpan={11}>
                                      No Data Found.
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table> */}
                            <DataTable
                              data={userList}
                              columns={columns}
                              noHeader
                              defaultSortFieldId={"customer_id"}
                              sortIcon={<i className="fas fa-sort"></i>}
                              defaultSortAsc={true}
                              highlightOnHover
                              dense
                            />
                            <div className="pagination">
                              <button className="pagination-btn" disabled={currentPage === 1 ? true : false} onClick={() => handlePageChange(currentPage - 1)}>
                                <i className={currentPage === 1 ? "disabled fa fa-chevron-left" : "fa fa-chevron-left"}></i>
                              </button>
                              {Array.from(Array(pageCount), (val, index) => (
                                <>
                                  <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={currentPage === index + 1 ? 'active pagination-btn' : 'pagination-btn'}
                                  >
                                    {index + 1}
                                  </button>
                                </>
                              ))}
                              <button className="pagination-btn" disabled={currentPage > pageCount - 1 ? true : false} onClick={() => handlePageChange(currentPage + 1)}>
                                <i className={currentPage > pageCount - 1 ? "disabled fa fa-chevron-right" : "fa fa-chevron-right"}></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-5">
                            {totalCount > 10 ? (
                              <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                Showing {userList?.length} of {totalCount} entries
                              </div>
                            ) : (
                              <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                Showing {totalCount == 0 ? 0 : totalCount} entries
                              </div>
                            )}
                          </div>
                          {/* <div className="col-sm-12 col-md-7">
                            <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                              <Pagination
                                count={Math.ceil(totalCount / 10)}
                                onChange={(event: any, value: any) => {
                                  setPageNumber(Number(value));
                                  setCurrentPage(Number(value));
                                }}
                                variant="outlined"
                                color="primary"
                                shape="rounded"
                                className="pagination-style"
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SuspendedModal handleOpen={() => handleClickOpen} userId={user_id} openModal={open} handleClose={(e: any) => handleClose(e)} />

        <Footer />
      </div>
    </div>
  );
};

export default Users;
